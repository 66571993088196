<template>
  <div class="row review-component">
    <b-card class="col-lg-10 mx-auto">
      <div class="panel-list">
        <div class="panel-body">
          <b-row class="panel-title">
            <div class="text-right">
              <button
                v-if="CanGoToSection"
                class="btn btn-link"
                @click="GotoSection('Client Info')"
              >
                <b-img class="edit-icon" :src="editIcon" /> Client Info
              </button>
            </div>
          </b-row>
          <b-row class="panel-content">
            <b-col cols="lg-3">
              <label>HFM Client</label>
              <!-- <p>
                {{clientinfopageone.HFMCodeName.split('~')[1]}}
              </p>       -->
              <p>
                {{
                  clientinfopageone.HFMCodeName.indexOf("~") > -1
                    ? clientinfopageone.HFMCodeName.split("~")[1]
                    : clientinfopageone.HFMCodeName
                }}
              </p>
            </b-col>
            <b-col cols="lg-3">
              <label>Entity</label>
              <p>{{ clientinfopageone.EntityName }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Agency</label>
              <p>{{ clientinfopageone.AgencyName }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Client Name</label>
              <p>{{ clientinfopageone.ClientName }}</p>
            </b-col>
            <!-- <b-col cols="lg-3" v-if="IsTruncatedClientActivated">
              <label>Truncated Client Name</label>
              <p>{{ clientinfopageone.TruncatedClientName }}</p>
            </b-col> -->
            <b-col cols="lg-3">
              <label>Requested Client Code</label>
              <p>{{ clientinfopageone.RequestClientcode }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Is this for a client code that is already on file that needs to be opened in an additional media?</label>
              <p>{{ clientinfopageone.IsExistingClient }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>BPM Assigned</label>
              <p>{{ clientinfopageone.BpmAssigned }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>DDS Media Office Code</label>
              <p>{{ clientinfopageone.DDSMediaOfcecode }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>DDS Accounting Office Code</label>
              <p>{{ clientinfopageone.DdsAccountingofcecode }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>AX Office Location</label>
              <p>{{ clientinfopageone.AXLocationCompany }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="clientinfopageone.ProductUDF1">
              <label>PRODUCT UDEF 1</label>
              <p>{{ clientinfopageone.ProductUDF1 }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="clientinfopageone.ProductUDF2">
              <label>PRODUCT UDEF 2</label>
              <p>{{ clientinfopageone.ProductUDF2 }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="clientinfopageone.EstimateUDF1">
              <label>ESTIMATE UDEF 1</label>
              <p>{{ clientinfopageone.EstimateUDF1 }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="clientinfopageone.EstimateUDF2">
              <label>ESTIMATE UDEF 2</label>
              <p>{{ clientinfopageone.EstimateUDF2 }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="panel-body">
          <b-row class="panel-title">
            <div class="text-right">
              <button
                v-if="CanGoToSection"
                class="btn btn-link"
                @click="GotoSection('Euler Grade')"
              >
                <b-img class="edit-icon" :src="editIcon" /> Euler Grade
              </button>
            </div>
          </b-row>
          <b-row class="panel-content">
            <b-col cols="lg-3">
              <label>Euler Grade</label>
              <p v-if="AddOrUpdateEular">
                {{ clientinfopagetwo.EularGradeVal }}
              </p>
              <p v-else>-</p>
            </b-col>
            <b-col cols="lg-3">
              <label>IS THE MEDIA SPEND EXPECTED TO EXCEED THE EULER INSURANCE COVERAGE?</label>
                <p>{{ clientinfopagetwo.Insurance }}</p>
            </b-col>
            <b-col cols="lg-3" v-if="this.clientinfopagetwo.ApprovalSourceLevel !=null">
              <label>Approval Source</label>
              <p v-if="(AddOrUpdateEular && this.clientinfopagetwo.Insurance != true  &&  this.clientinfopagetwo.EularGradeVal > 6) || (AddOrUpdateEular && this.clientinfopagetwo.Insurance == 'true')  ">
                {{GetEulerApprovalSourceOriginalValue()}}
              </p>
              <p v-else>-</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Attached File</label>
              <div v-if="AddOrUpdateEular && clientinfopagetwo.Files && clientinfopagetwo.Files.length > 0">
                <div v-for="file in clientinfopagetwo.Files" :key="file">
                  {{ file }}
                </div>
              </div>
              <p v-else>-</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Client Entity Name Per Agreement</label>
              <p v-if="AddOrUpdateEular">
                {{ clientinfopagetwo.ClientEntityName }}
              </p>
              <p v-else>-</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Comments</label>
              <p v-if="AddOrUpdateEular">
                {{ clientinfopagetwo.Comments }}
              </p>
              <p v-else>-</p>
            </b-col>
          </b-row>
        </div>
        <div class="panel-body">
          <b-row class="panel-title">
            <div class="text-right">
              <button
                v-if="CanGoToSection"
                class="btn btn-link"
                @click="GotoSection('Billing')"
              >
                <b-img class="edit-icon" :src="editIcon" /> Billing Info
              </button>
            </div>
          </b-row>
          <b-row class="panel-content">
            <b-col cols="lg-3">
              <label>Biiling Party</label>
              <p>
                {{
                  (clientinfopagethree.BillingParty == "Direct"||
                   clientinfopagethree.BillingParty =="Time Only (Client Related Time MED)"||
                   clientinfopagethree.BillingParty == "Time Only (Internal Project Related Time ADM)")
                    ? clientinfopagethree.BillingParty
                    : clientinfopagethree.BillingParty.split("-")[0] +
                      "-" +
                      clientinfopagethree.BillingParty.split("-")[1] + (clientinfopagethree.BillingParty.split("-").length > 2 ?
                      "(" +
                      clientinfopagethree.BillingParty.split("-")[2].trim() +
                      ")" : "")
                }}
              </p>
            </b-col>
            <b-col cols="lg-3">
              <label>Bill to Client</label>
              <p>{{ clientinfopagethree.BillToClientName }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Country</label>
              <p>{{ clientinfopagethree.CountryName }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="!IsBillingCountryUSA">
              <label>Address Line 1</label>
              <p>{{ clientinfopagethree.AddressLine1 }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="!IsBillingCountryUSA">
              <label>Address Line 2</label>
              <p>{{ clientinfopagethree.AddressLine2 }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="IsBillingCountryUSA">
              <label>Street Address</label>
              <p>{{ clientinfopagethree.TruncatedBillingStreetAddress == null ? clientinfopagethree.StreetAddress : clientinfopagethree.TruncatedBillingStreetAddress }}</p>
            </b-col>

            <b-col cols="lg-3" v-show="IsBillingCountryUSA">
              <label>City</label>
              <p>{{ clientinfopagethree.City }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="IsBillingCountryUSA">
              <label>State</label>
              <p>{{ clientinfopagethree.StateName }}</p>
            </b-col>
            <b-col cols="lg-3" v-show="IsBillingCountryUSA">
              <label>Zip Code</label>
              <p>{{ clientinfopagethree.BillingZipCode }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Currency</label>
              <p>{{ clientinfopagethree.CurrencyName }}</p>
            </b-col>
            <b-col cols="lg-3">
              <label>Default Payment Terms</label>
              <p>{{ clientinfopagethree.DefaultPaymentTermsName }}</p>
            </b-col>
             <b-col cols="lg-3" v-if="clientinfopagethree.Files && clientinfopagethree.Files.length > 0">
              <label>Approval Screenshot from Credit Voyager</label>
              <div v-for="file in clientinfopagethree.Files" :key="file">
                {{ file.name }}
              </div>
            </b-col>
            <b-col cols="lg-3">
              <label>Is this a COS2 Client</label>
              <p>{{ clientinfopagethree.IsThisCostToClient }}</p>
            </b-col>
              <b-col cols="lg-3" v-if="clientinfopagethree.ProgrammaticBundled!=null">
              <label>Is this client COS2 ONLY for media B- Programmatic Bundled </label>
              <p>{{ clientinfopagethree.ProgrammaticBundled }}</p>
            </b-col>
            <b-col cols="lg-3" v-if="clientinfopagethree.ShowAORFields">
              <label>AOR Client Code</label>
              <p>{{ clientinfopagethree.AORClientCode }}</p>
            </b-col>
            <b-col cols="lg-3" v-if="clientinfopagethree.ShowAORFields">
              <label>AOR Client Name</label>
              <p>{{ clientinfopagethree.AORClientName }}</p>
            </b-col>
            <b-col cols="lg-3" v-if="clientinfopagethree.ShowAORFields">
              <label>AOR Client Agency</label>
              <p>{{ clientinfopagethree.AORClientAgencyName }}</p>
            </b-col>
          </b-row>
        </div>
        <div class="panel-body" v-if="BillingPartyNotTimeOnly">
          <b-row class="panel-title">
            <div class="text-right">
              <button
                v-if="CanGoToSection"
                class="btn btn-link"
                @click="GotoSection('Commission Info')"
              >
                <b-img class="edit-icon" :src="editIcon" /> Commission Info
              </button>
            </div>
          </b-row>
         <b-row class="panel-content" v-if="CommissionInfoCategories && CommissionInfoCategories.length>0">
            <b-row class="col-lg-12"  v-for="item in CommissionInfoCategories"
                :key="item.MediaCategory">
                <b-col cols="lg-12">
              <div>{{ item.MediaCategory }}:</div>
                </b-col>
              <b-col
                cols="lg-4"
                v-for="mediatype in item.Media"
                :key="mediatype.MediaType"
              >
                <label>{{ mediatype.MediaType }}</label>
                <div v-if="mediatype.MediaCategory != 'TimeOnly'">
                  <div v-if="mediatype.MediaType">Fee Only :
                    <span>{{mediatype.FeeOnly}}</span>
                  </div>
                  <div v-if="mediatype.FeeOnly!='Yes'">
                    Commission %: {{ mediatype.CommissionPercentage }}%
                  </div>
                  <div v-if="mediatype.FeeOnly!='No'">
                    Commission %: -
                  </div>
                  <div v-if="mediatype.FeeOnly != 'Yes'">
                    Commission Basis: {{ mediatype.CommissionBasis }}
                  </div>
                  <div v-if="mediatype.FeeOnly!='No'">
                    Commission Basis: -
                  </div>
                  <div v-if="mediatype.DefaultPaymentTerms">
                    Default PaymentTerms: {{ mediatype.DefaultPaymentTerms.toString() }}
                  </div>
                </div>
              </b-col>
              </b-row>
              <b-col cols="lg-4" v-if="clientinfopageone.IsNetworkCfoApproved">
                <label>Were the Payment terms above N030 approved by the Network CFO?</label>
                <p>{{ clientinfopageone.IsNetworkCfoApproved }}</p>
              </b-col>
              <b-col cols="lg-4" v-if="clientinfopageone.CreditVoyagerApprovalFiles && clientinfopageone.CreditVoyagerApprovalFiles.length > 0">
                <label>Credit Voyager Approval for Payment terms</label>
                <div v-for="file in clientinfopageone.CreditVoyagerApprovalFiles" :key="file">
                  {{ file.name }}
                </div>
              </b-col>
            </b-row>
             <b-row v-else class="panel-content" style="margin-left:4px;">
                <b-row class="col-lg-12">
                 <p> Production Billing Only</p>
                </b-row>
              </b-row>

        </div>
        <div class="panel-body">
          <b-row class="panel-title">
            <div class="text-right">
              <button
                v-if="CanGoToSection"
                class="btn btn-link"
                @click="GotoSection('Product Info')"
              >
                <b-img class="edit-icon" :src="editIcon" /> Product Info
              </button>
            </div>
          </b-row>
          <b-row class="panel-content row" v-if="ClientProductList">
            <b-col
              cols="lg-3"
              v-for="product in ClientProductList"
              :key="product.ProductName"
            >
              <label>Product Name: {{ product.ProductName }}</label>
              <div>Product Code: {{ product.ProductCode }}</div>
              <div v-show="product.Media != null && product.Media.length > 0">
                Media: {{ product.Media.map((s) => s.MediaType).join(",") }}
              </div>
              <div v-if="IsProductExceptionsAdded(product)">
                <div>
                  <!-- Exceptions: -->
                  <div v-html="GetCommissionException(product)"></div>
                  <div v-html="GetBillingException(product)"></div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="panel-body" v-if="EnableSpecialReqEdit || clientinfopageone.SpecialRequirement">

          <b-row class="panel-title">
            <label class="col-lg-3">Special Requirements</label>

            <div class="col-lg-2 offset-lg-7 text-right">
              <button
                v-if="EnableSpecialReqEdit || clientinfopageone.SpecialRequirement"
                class="btn btn-link"
                @click="AddSpecialRequirement()"
              >
                <b-img class="edit-icon" :src="editIcon" /> Edit
              </button>
            </div>
          </b-row>
          <b-row class="panel-content row"  style="margin-top:-22px;">
            <b-col
              cols="lg-3">
              <label style="line-height:1-8;" v-if="clientinfopageone.SpecialRequirement"> {{ clientinfopageone.SpecialRequirement }}</label>
              <label style="line-height:1-8;" v-show="!clientinfopageone.SpecialRequirement" > {{ SpecialRequirement }}</label>
            </b-col>
          </b-row>
        </div>


      </div>
    </b-card>
    <b-form-group class="footer-buttons text-right">
      <button class="btn custom-button btn-link" @click="Cancel()">
        Cancel
      </button>
      <button class="btn custom-button btn-outline-primary"
        @click="SubmitwithSpecialReq()"
      >
        Submit with Special Requirement
       </button>
      <button
        class="btn custom-button btn-primary"
        v-if="CanGoToSection"
        @click="Previous()"
      >
        BACK
      </button>

      <button
        class="btn custom-button btn-primary"
        v-if="!Edit"
        @click="Submit()"
      >
        Submit
      </button>
      <button
        class="btn custom-button btn-primary"
        v-if="CanResubmit && !isDraft"
        @click="Update()"
      >
        Resubmit
      </button>
    </b-form-group>
     <b-modal
      no-enforce-focus
      size="xl"
      v-model="openspecialreqbox"
      :no-close-on-backdrop="true"
      hide-footer
      title="Add Special Requirements"
    >
    <template v-slot:modal-header="{ close }">
          <h5 class="modal-title"> Add Special Requirements</h5>
          <button class="close" @click="CancelSpecialReqbox()"></button>
        </template>
      <!-- <p class="You-cannot-submit-th">

      </p> -->
      <div class="d-block row">
        <div class="Rectangle col-lg-8 offset-lg-1 fieldborder">
          <div class="col-md-12 rectangleborder">
           <b-form-textarea
      id="textarea"
      placeholder="Enter Special Requirements"
      v-model="SpecialRequirement"
      rows="8"
      max-rows="8"
      style="width:400px;"
    ></b-form-textarea>
          </div>
        </div>
        <br />
        <div
          class="fieldborder col-lg-8 offset-lg-2"
          style="margin-top: 10px !important"
        >
        </div>
      </div>
      <br />
      <div class="text-right">
        <button class="btn btn-link text-uppercase" @click="CancelSpecialReqbox()">
          Cancel
        </button>
        <button
          :disabled="!SpecialRequirement"
          v-show="true"
          class="text-uppercase btn custom-button btn-primary"
          @click="AddSpecialRequirementtoServer()"
        >
          Add
        </button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import service from "../../services/Dropdowns";
import * as ClientWorkflowStates from "../../utilities/ClientWorkflowStates";
import { NETMedia, SPOTMedia,PRINTCategoryMedia,DIGITALMedia } from "../../utilities/MediaList";
export default {
  data() {
    return {
      SpecialRequirement:null,
      AddSpecialbtn:true,
      openspecialreqbox:false,
      EnableSpecialReqEdit:false,
      editIcon: require("@/assets/Icons/edit.svg"),
      CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
      addIcon: require("@/assets/Icons/add.svg"),
      existingrequirementdata:null,
    };
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft:{
        type:Boolean,
        required:false
      }
  },
  activated() {
    if (this.id) {
      window.console.log("review step changed");
      window.console.log(`review ${this.$route.meta.tabIndex}`);
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
    }
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  created() {
    if (this.id) {
      window.console.log("review step changed");
      window.console.log(`review ${this.$route.meta.tabIndex}`);
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
    }
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  computed: {
    CommissionInfoCategories(){
     window.console.log(JSON.stringify(this.CommissionInfo) + 'result');
     if(this.CommissionInfo!=null &&this.CommissionInfo!=null && this.CommissionInfo.length>0){
      let commissionMediaTypes=[];
      let mediaSelected=this.CommissionInfo.flatMap(val=>
         val.Media
      );
      let spotMedia=mediaSelected.filter(val=>{
     return SPOTMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });
      if(spotMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Spot',Media:spotMedia});
      }

      let netMedia=mediaSelected.filter(val=>{
     return NETMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });
      if(netMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Network',Media:netMedia});
      }

      let printMedia=mediaSelected.filter(val=>{
     return PRINTCategoryMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });
      if(printMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Print',Media:printMedia});
      }

      let digitalMedia=mediaSelected.filter(val=>{
     return DIGITALMedia.map(mediaType=>mediaType.MediaType).includes(val.MediaType);
      });
      if(digitalMedia.length>0){
        commissionMediaTypes.push({MediaCategory:'Digital',Media:digitalMedia});
      }
return commissionMediaTypes;
     }else{
       return [];
     }
    },
    AddOrUpdateEular() {
      return this.clientinfopagetwo.TimeOnly == "true";
    },



    CanGoToSection() {
      return !this.Edit || this.CanResubmit;
    },
    CanResubmit() {
      return (
        this.id &&
        parseInt(this.id) > 0 &&
        this.Status == ClientWorkflowStates.Canceled
      );
    },
    Edit() {
      return this.id && parseInt(this.id) > 0 && !this.isDraft;
    },
    ...mapGetters("client", {
      ClientProductList: "clientProductList",
      CommissionInfo: "clientCommissionInfo",
      Status: "Status",
    }),
    ...mapGetters("client", {
      clientinfopageone: "ClientBasicDetails",
    }),

    ...mapGetters("client", {
      clientinfopagetwo: "ClientEularInfo",
    }),

    ...mapGetters("client", {
      clientinfopagethree: "ClientBillingInformations",
    }),

    ...mapGetters("client", {
      specialrequirements: "SpecialRequirement",
    }),
    BillingPartyNotTimeOnly(){
      let timeOnlyOptions=["Time Only (Client Related Time MED)","Time Only (Internal Project Related Time ADM)"];
      //let timeOnlySelected=timeOnlyOptions.includes(this.BillingInfo.BillingParty);
      return !(this.clientinfopagethree && timeOnlyOptions.includes(this.clientinfopagethree.BillingParty));
    },
    IsBillingCountryUSA() {
      return this.clientinfopagethree.Country == this.CountryUnitedStatesValue;
    },
    IsTruncatedClientActivated() {
      return (
        this.clientinfopageone != null &&
        this.clientinfopageone.TruncatedClientName != null &&
        this.clientinfopageone.TruncatedClientName != ""
      );
    },
  },

  watch:{
     "SpecialRequirement":function(val,old){
       if(old==null && this.id !=undefined)
       {
          this.existingrequirementdata=val;
       }
       if(this.id==undefined)
       {
       if(this.clientinfopageone.SpecialRequirement)
       {

              this.clientinfopageone.SpecialRequirement=val;
       }
       else
       {
         if(val.length>0)
         {
             this.EnableSpecialReqEdit=true;
             this.AddSpecialbtn=false;
         }
         else
         {
             this.EnableSpecialReqEdit=false;
             this.AddSpecialbtn=true;
         }
       }

       }

       else{
         if(val.length>-1)
         {
             this.clientinfopageone.SpecialRequirement=val;
             if(this.clientinfopageone.SpecialRequirement<0)
             {
               this.EnableSpecialReqEdit=false;
             this.AddSpecialbtn=false;
             }
             else{
               this.EnableSpecialReqEdit=false;
             this.AddSpecialbtn=false;
             }

         }
         else
         {

             this.EnableSpecialReqEdit=false;
             this.AddSpecialbtn=false;
         }
       }
     }
  },

  methods: {

    AddSpecialRequirement(){
      if(this.clientinfopageone.SpecialRequirement)
        {
            this.SpecialRequirement= this.clientinfopageone.SpecialRequirement;
            this.openspecialreqbox=true;
        }
        else{
            this.openspecialreqbox=true;
        }
    },

    CancelSpecialReqbox(){
      this.openspecialreqbox=false;
      if(this.id==undefined)
      {
        this.SpecialRequirement="";
      }


       if(this.SpecialRequirement)
       {
          this.AddSpecialbtn=false;
       }
       else
       {
         this.AddSpecialbtn=true;
       }

      if(this.id !=undefined)
      {
          this.clientinfopageone.SpecialRequirement=this.existingrequirementdata;
      }

    },


    AddSpecialRequirementtoServer(){
       this.openspecialreqbox=false;
       if(this.SpecialRequirement.length>0)
       {
          this.AddSpecialbtn=false;
       }
       else
       {
         this.AddSpecialbtn=true;
       }
       if(this.CanResubmit)
       {
        this.Update();
       }
       else{
         this.Submit();
       }

    },
    GotoSection(name) {
      if (this.id) {
        this.$router.push({ name: name, params: { id: parseInt(this.id),isDraft: this.isDraft } });
      } else {
        this.$router.push({ name: name });
      }
      // this.$router.push(`${section}`);
    },
    IsProductExceptionsAdded(product) {
      return (
        product != null &&
        (product.AddedBillingException || product.AddedCommissionException)
      );
    },
    IsBillingExceptionAdded(product) {
      return product != null && product.AddedBillingException;
    },
    IsCommissionExceptionAdded(product) {
      return product != null && product.AddedCommissionException;
    },
    GetCommissionException(product) {
      let html = "";
      if (product.AddedCommissionException) {
        if (product.CommissionInfo.TimeOnly == "true") {
          html += `Time Only`;
        } else if (product.CommissionInfo.CommissionBasis == "Net") {
          html += `Commission Basis: Net - ${product.CommissionInfo.CommissionPercentage}%`;
        } else if (product.CommissionInfo.CommissionBasis == "Gross") {
          html += `Commission Basis: Gross - ${product.CommissionInfo.CommissionPercentage}%`;
        } else if (product.CommissionInfo.FeeOnly) {
          html += `Fee Only`;
        }
      }
      return html;
    },

    GetEulerApprovalSourceOriginalValue(){
      if(this.clientinfopagetwo.ApprovalSourceLevel !=null)
      {
      if(this.clientinfopagetwo.ApprovalSourceLevel =="1")
      {
          return "Network CEO";
      }
      else if(this.clientinfopagetwo.ApprovalSourceLevel =="2")
      {
          return "Network CFO";
      }
      else
      {
          return "Omnicom CFO";
      }
      }
    },

    GetBillingException(product) {
      let html = "";
      if (product.AddedBillingException) {
        if (
          product.ProductBillingAddress.Country != this.CountryUnitedStatesValue
        ) {
          html += `Billing Address: ${product.ProductBillingAddress.AddressLine1} ${product.ProductBillingAddress.AddressLine2}`;
        } else {
          if (
            product.ProductBillingAddress.TruncatedBillingStreetAddress &&
            product.ProductBillingAddress.TruncatedBillingStreetAddress.length >
              0
          ) {
            html += `Billing Address: ${product.ProductBillingAddress.TruncatedBillingStreetAddress} <div> ${product.ProductBillingAddress.City},${product.ProductBillingAddress.StateName},${product.ProductBillingAddress.ZipCode} </div>`;
          } else {
            html += `Billing Address: ${product.ProductBillingAddress.StreetAddress}  <div>${product.ProductBillingAddress.City},${product.ProductBillingAddress.StateName},${product.ProductBillingAddress.ZipCode} </div>`;
          }
        }
      }
      return html;
    },



    GetProductInfo() {
      let ProductInfo = [];
      if (this.ClientProductList && this.ClientProductList.length > 0) {
        this.ClientProductList.forEach((val) => {
          let productBillingAddress = {};
          if (val.AddedBillingException) {
            productBillingAddress.Attn = val.ProductBillingAddress.Attn;
            productBillingAddress.BillToName = val.ProductBillingAddress.BillToName;
            productBillingAddress.AddressLine1 =
              val.ProductBillingAddress.AddressLine1;
            productBillingAddress.AddressLine2 =
              val.ProductBillingAddress.AddressLine2;
            productBillingAddress.State = val.ProductBillingAddress.State;
            productBillingAddress.ZipCode = val.ProductBillingAddress.ZipCode;
            productBillingAddress.Country = val.ProductBillingAddress.Country;
            productBillingAddress.City = val.ProductBillingAddress.City;
            productBillingAddress.BillingStreetAddress =
              val.ProductBillingAddress.StreetAddress;
            productBillingAddress.TruncatedBillingStreetAddress =
              val.ProductBillingAddress.TruncatedBillingStreetAddress;
          } else {
            productBillingAddress = null;
          }
          let productMediaDetails = [];
          if (val.Media && val.Media.length > 0) {
            val.Media.forEach((media) => {
              productMediaDetails.push({
                ID: 0,
                ProductRequestID: 0,
                ...media,
              });
            });
          }

          let commissionException = {
            FeelOnly: null,
            CommissionBasis: null,
            CommissionPercentage: null,
            TimeOnly: null,
          };
          if (val.AddedCommissionException) {
            commissionException.TimeOnly =
              val.CommissionInfo.TimeOnly != null &&
              val.CommissionInfo.TimeOnly == "true"
                ? true
                : false;
            commissionException.FeeOnly =
              val.CommissionInfo.FeeOnly != null
                ? val.CommissionInfo.FeeOnly == "Yes"
                  ? true
                  : false
                : null;
            commissionException.CommissionBasis =
              val.CommissionInfo.CommissionBasis;
            commissionException.CommissionPercentage = parseFloat(
              val.CommissionInfo.CommissionPercentage
            );
          }
          ProductInfo.push({
            ProductCommissionDetails: productMediaDetails,
            ...commissionException,
            ProductName: val.ProductName.toString(),
            ProductCode: val.ProductCode.toString(),
            TruncatedProductName: val.TruncatedProductName,
            ProductBillingAddress: productBillingAddress,
          });
        });
      }
      return ProductInfo;
    },
    CheckTimeonlyCommission() {
      let timeOnly = true;
      if (this.CommissionInfo != null && this.CommissionInfo.length == 1) {
        let indexMediaCategory = this.CommissionInfo.findIndex((val) => {
          return val.MediaCategory == "TimeOnly";
        });
        timeOnly = indexMediaCategory > -1;
      }
      return timeOnly;
    },
    GetCommissionInfo() {
      let commissionInfo = [];
      if(this.CommissionInfo && this.CommissionInfo.length>0)
      {
         this.CommissionInfo.forEach((mediaCategory) => {
        if (mediaCategory.MediaCategory == "TimeOnly") {
          commissionInfo.push({
            ID: 0,
            ClientRequestID: 0,
            MediaType: mediaCategory.MediaCategory,
            MediaCategory: mediaCategory.MediaCategory,
            CommissionPercentage: null,
            FeeOnly: null,
            CommissionBasis:null,
            DefaultPaymentTerms:null,
            ProductionBilling:false
          });
        } else {
          mediaCategory.Media.forEach((media) => {
            commissionInfo.push({
              ID: 0,
              ClientRequestID: 0,
              MediaType: media.MediaType,
              MediaCategory: mediaCategory.MediaCategory,
              CommissionPercentage: parseFloat(media.CommissionPercentage),
              FeeOnly: media.FeeOnly == "Yes" ? true : false,
              CommissionBasis: media.CommissionBasis,
              DefaultPaymentTerms: media.DefaultPaymentTerms,
              ProductionBilling:false
            });
          });
        }
      });
      }
      else
      {
        commissionInfo.push({
              ID: 0,
              ClientRequestID: 0,
              MediaType: '',
              MediaCategory: '',
              CommissionPercentage: 0,
              FeeOnly:  false,
              CommissionBasis: null,
              DefaultPaymentTerms: null,
              ProductionBilling:true
            });
          window.console.log( `executed prod billing section`);
      }
      window.console.log( `commission info length  ${commissionInfo.length}`);
      return commissionInfo;
    },
    GetBillingInfo() {
      let BillingInfo = {};
      BillingInfo.Attn = this.clientinfopagethree.Attn;
      BillingInfo.AddressLine1 = this.clientinfopagethree.AddressLine1;
      BillingInfo.AddressLine2 = this.clientinfopagethree.AddressLine2;
      BillingInfo.State = this.clientinfopagethree.BillingState;
      BillingInfo.ZipCode = this.clientinfopagethree.BillingZipCode;
      BillingInfo.Currency = this.clientinfopagethree.Currency;
      BillingInfo.DefaultPaymentTerms = this.clientinfopagethree.DfltPayment;
      BillingInfo.BillingParty = this.clientinfopagethree.BillingParty;
      BillingInfo.ClientBillToName = this.clientinfopagethree.BillToClientName;
      BillingInfo.Country = this.clientinfopagethree.Country;
      BillingInfo.City = this.clientinfopagethree.City;
      BillingInfo.ClientBillingStreetAddress = this.clientinfopagethree.StreetAddress;
      BillingInfo.AORClientCode = this.clientinfopagethree.AORClientCode;
      BillingInfo.AORClientName = this.clientinfopagethree.AORClientName;
      BillingInfo.AORClientAgency = this.clientinfopagethree.AORClientAgency;
      BillingInfo.IsThisCostToClient =
        (this.clientinfopagethree.IsThisCostToClient == "true" || this.clientinfopagethree.IsThisCostToClient == true)  ? true : false;

       if(this.clientinfopagethree.IsThisCostToClient == "true" || this.clientinfopagethree.IsThisCostToClient == true)
       {
         BillingInfo.ProgrammaticBundled = (this.clientinfopagethree.ProgrammaticBundled == "true" || this.clientinfopagethree.ProgrammaticBundled == true) ? true : false;
       }else{
         BillingInfo.ProgrammaticBundled =null;
       }

      BillingInfo.TruncatedBillingStreetAddress = this.clientinfopagethree.TruncatedBillingStreetAddress;
      return BillingInfo;
    },
    PrepareData() {
      let clientForm = {};
      if(this.id && this.isDraft==true){
        clientForm.DraftRequestID=parseInt(this.id);
      }
      if (this.clientinfopageone.HFMCodeName.indexOf("~") > -1) {
        clientForm.HFMCode = this.clientinfopageone.HfmCodeValue.split("~")[1];
        clientForm.HFMCodeID = this.clientinfopageone.HfmCodeValue.split(
          "~"
        )[0];
      } else {
        clientForm.HFMCode = this.clientinfopageone.HfmCodeValue;
        clientForm.HFMCodeID = null;
      }
      clientForm.EulerGrade = parseInt(this.clientinfopagetwo.EularGradeVal);
      clientForm.IsMediaSpendExceedsInsuranceCoverage =
        this.clientinfopagetwo.Insurance == "true" ? true : false;
      clientForm.ApprovalSource = this.clientinfopagetwo.ApprovalSourceLevel;
      clientForm.ClientEntityNamePerAgreement = this.clientinfopagetwo.ClientEntityName;
      clientForm.Comments = this.clientinfopagetwo.Comments;
      clientForm.MDMClientName = "Hard Coded"; //this.clientinfopageone.MDMClientName;
      clientForm.ClientName = this.clientinfopageone.ClientName;
      clientForm.RequestedClientCode = this.clientinfopageone.RequestClientcode;
      clientForm.IsExistingClient = this.clientinfopageone.IsExistingClient == "true" ? true : false;
      clientForm.DDSAccountingOfficeCode = this.clientinfopageone.DdsAccountingofcecode;
      clientForm.DDSMediaOfficeCode = this.clientinfopageone.DDSMediaOfcecode;
      clientForm.AXLocationOrCompany = this.clientinfopageone.AXLocationCompany;

      clientForm.ProductUDF1 = this.clientinfopageone.ProductUDF1;
      clientForm.ProductUDF2 = this.clientinfopageone.ProductUDF2;
      clientForm.EstimateUDF1 = this.clientinfopageone.EstimateUDF1;
      clientForm.EstimateUDF2 = this.clientinfopageone.EstimateUDF2;

      clientForm.Entity = this.clientinfopageone.Entity;
      clientForm.Agency = this.clientinfopageone.Agency;
      clientForm.AgencyName = this.clientinfopageone.AgencyName;
      clientForm.TruncatedClientName = this.clientinfopageone.TruncatedClientName;
      clientForm.AddOrUpdateEulerGrade =
      this.clientinfopagetwo.TimeOnly == "true" ? true : false;
      clientForm.BPMAssigned = this.clientinfopageone.BpmAssigned;
      clientForm.CreatedDate = new Date();
      clientForm.UpdatedDate = new Date();
      clientForm.Guid = localStorage.getItem("UploadfileId");
      //clientForm.Requestor=1;
      //clientForm.ID=1;
      clientForm.BillingInfo = this.GetBillingInfo();

      clientForm.ClientRequestApprovalDetails = [];
      clientForm.ClientMediaCommissionDetails = [];
      clientForm.ProductDetails = this.GetProductInfo();
      clientForm.ClientMediaCommissionDetails = this.GetCommissionInfo();
      if (
        clientForm.ClientMediaCommissionDetails == null ||
        clientForm.ClientMediaCommissionDetails.length < 1
      ) {
        clientForm.TimeOnly = this.CheckTimeonlyCommission();

      }
      if(this.id && this.id>0)
      {
        clientForm.SpecialRequirement=this.SpecialRequirement==""?this.clientinfopageone.SpecialRequirement:this.SpecialRequirement;
      }
      else{
        clientForm.SpecialRequirement=this.SpecialRequirement;
      }

      clientForm.Files = [];

      let eulerFiles =this.clientinfopagetwo.EulerFiles;
      if(eulerFiles != null && eulerFiles.length > 0){
        eulerFiles.forEach(eulerFile => {
          clientForm.Files.push({ Category: `EulerFiles`, Name: eulerFile.Name || eulerFile.name })
        });
      }
      let screenshotFiles =this.clientinfopagethree.Files;
      if(screenshotFiles != null && screenshotFiles.length > 0){
        screenshotFiles.forEach(screenshotFile => {
          clientForm.Files.push({ Category: `ScreenshotFiles`, Name: screenshotFile.Name || screenshotFile.name })
        });
      }
      let creditVoyagerApprovalFiles =this.clientinfopageone.CreditVoyagerApprovalFiles;
      if(creditVoyagerApprovalFiles != null && creditVoyagerApprovalFiles.length > 0){
        creditVoyagerApprovalFiles.forEach(creditVoyagerApprovalFile => {
          clientForm.Files.push({ Category: `CreditVoyagerApprovalFiles`, Name: creditVoyagerApprovalFile.Name || creditVoyagerApprovalFile.name })
        });
      }

      return clientForm;
    },
    // IsValidFormData(form) {
    //   if (
    //     !form.TimeOnly &&
    //     (form.ClientMediaCommissionDetails == null ||
    //       form.ClientMediaCommissionDetails.length < 1)
    //   ) {
    //     return false;
    //   }
    // },
    Update() {
      let data = this.PrepareData();
       let formData = new FormData();
            let eulerFiles =this.clientinfopagetwo.EulerFiles;
            for (let i = 0; i < eulerFiles.length; i++) {
                let file = eulerFiles[i];
                formData.append(`EulerFiles${[i]}`, file.file);
            }
            let screenshotFiles =this.clientinfopagethree.Files;
            for (let j = 0; j < screenshotFiles.length; j++) {
                let file = screenshotFiles[j];
                formData.append(`ScreenshotFiles${[j]}`, file.file);
            }
            let creditVoyagerApprovalFiles =this.clientinfopageone.CreditVoyagerApprovalFiles;
            for (let k = 0; k < creditVoyagerApprovalFiles.length; k++) {
                let file = creditVoyagerApprovalFiles[k];
                formData.append(`CreditVoyagerApprovalFiles${[k]}`, file.file);
            }
            formData.append('request', JSON.stringify(data));
      this.$store
//data: formData //.dispatch("wiretransfer/UpdateRequestWithFiles", { id: parseInt(this.id),data: formData})
        .dispatch("client/UpdateClient", { id: this.id, data:formData})
        .then(() => {
          this.$router.push("/ClientProduct");
          store.dispatch(
            "progress/setSuccessMessage",
            "Your client request has been updated!"
          );
        })
        .catch((ex) => {
          if (ex.response) {
            var commenmsg="";
            if(ex.response.status==500)
            {
               commenmsg="Please check your inputs or try again later";
               store.dispatch("progress/setErrorMessage", `${commenmsg}`);
            }
            else if(ex.response.status ==400)
            {
              for(var i=0; i<ex.response.data.length;i++)
                {
                  if(ex.response.data[i].ErrorMessage != 'undefined')
                    {
                     commenmsg += ex.response.data[i].ErrorMessage;
                    }
                }
                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
            }
            else{
              store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
            }
            // store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
          } else {
            store.dispatch(
              "progress/setErrorMessage",
              `Error while updating client. ${ex}`
            );
          }
        });
    },
    SubmitwithSpecialReq(){
       if(this.clientinfopageone.SpecialRequirement)
        {
            this.SpecialRequirement= this.clientinfopageone.SpecialRequirement;
            this.openspecialreqbox=true;
        }
        else{
            this.openspecialreqbox=true;
        }
    },
    Submit() {
      //this.openspecialreqbox=true;
      let data = this.PrepareData();
      let formData = new FormData();
            let eulerFiles =this.clientinfopagetwo.EulerFiles;
            for (let i = 0; i < eulerFiles.length; i++) {
                let file = eulerFiles[i];
                formData.append(`EulerFiles`, file.file);
            }
            let screenshotFiles =this.clientinfopagethree.Files;
            for (let j = 0; j < screenshotFiles.length; j++) {
                let file = screenshotFiles[j];
                formData.append(`ScreenshotFiles`, file.file);
            }
            let creditVoyagerApprovalFiles =this.clientinfopageone.CreditVoyagerApprovalFiles;
            for (let k = 0; k < creditVoyagerApprovalFiles.length; k++) {
                let file = creditVoyagerApprovalFiles[k];
                formData.append(`CreditVoyagerApprovalFiles`, file.file);
            }
            formData.append('request', JSON.stringify(data));
            //window.console.log(JSON.stringify(data));

      // if (!this.IsValidFormData(data)) {
      //   store.dispatch(
      //     "progress/setErrorMessage",
      //     `Please select at least one commission details or TimeOnly to submit request`
      //   );
      // } else {
      this.$store
        .dispatch("client/AddClient", formData)
        .then(() => {
          this.$router.push("/ClientProduct");
          store.dispatch(
            "progress/setSuccessMessage",
            "Your new client request has been submitted!"
          );
        })
        .catch((ex) => {
          if (ex.response) {
            var commenmsg="";
            if(ex.response.status==500)
            {
               commenmsg="Please check your inputs or try again later";
               store.dispatch("progress/setErrorMessage", `${commenmsg}`);
            }
            else if(ex.response.status==400)
            {
              for(var i=0; i<ex.response.data.length;i++)
                {
                  if(ex.response.data[i].ErrorMessage != 'undefined')
                    {
                     commenmsg += ex.response.data[i].ErrorMessage;
                    }
                }
                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
            }
             else{
              store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
            }
          } else {
            store.dispatch(
              "progress/setErrorMessage",
              `Error while creating client. ${ex}`
            );
          }
        });
      // }
    },
    Previous() {
      if (this.id) {
        this.$router.push({ name: "Product Info", params: { id: this.id,isDraft:this.isDraft } });
      } else {
        this.$router.push("ProductInfo");
      }
    },

    Cancel() {
      var guid = localStorage.getItem("UploadfileId");
      if (guid != "") {
        service
          .removeDiretory(guid)
          .then(() => {
            this.$router.push("/ClientProduct");
            localStorage.setItem("UploadfileId", "");
          })
          .catch((err) => {
            alert(err);
            this.$router.push("/ClientProduct");
          });
      } else {
            this.$router.push("/ClientProduct");
      }
    },
    SaveForLater() {},
  },
};
</script>

<style scoped>

.modal-body p {
    margin: 14px 40px 14px 55px;
    font-family: 'Open Sans' !important;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
}

.add-product-row {
  font-weight: bold;
  padding-left: 0px;
  height: 9px;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}

.panel-list:not(.media-dropdowns) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.panel-list .panel-body .panel-title {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  width: 100%;
  margin-left: 0px;
  margin-bottom: 25px !important;
}
.panel-list .panel-body .panel-title label {
  color: #77a2bb;
  margin-bottom: 0px !important;
  padding-top: 13px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  padding-left: 0px !important;
  min-height: 35px;
}
.panel-list .panel-body .panel-title .btn {
  padding-left: 5px;
  padding-right: 5px;
}
.panel-list .panel-body .panel-title > div {
  padding-right: 0px !important;
  float: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}
.panel-list .panel-content label {
  text-transform: uppercase;
  color: #2c3865;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding-left: 0px;
}
.panel-list .panel-content p {
  text-transform: uppercase;
  color: #4d5161;
  font-size: 14px;
  font-weight:600;
}
.panel-content.row > div > label:first-child {
  color: #A2A9AD !important;
  font-family: "Open Sans" !important;
  font-size: 10px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}
.panel-content.row > div > :not(:first-child) {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
}
.panel-content.row > div > div {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.panel-list > .panel-body {
  margin-top: 1rem !important;
}
.panel-content.row > div {
  margin-bottom: 10px !important;
}
.edit-icon {
  height: 11px;
  width: 11px;
  color: #00a1d2;
}
</style>