<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                            <span class="text-uppercase lbltxt" style="margin-left: 11px">
                                Vendor Code
                        </span>
                        </slot>
                        <b-form-input @blur="SetDirty('VendorCode')" @focus="ResetDirty('VendorCode')"
                        autocomplete="off"  id="cilentcode" class="omniipclass" :maxlength="26"
                        v-model="MediaVendorAmend.VendorCode"
                        placeholder="Enter Vendor Code" disabled ></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.VendorCode.$dirty && !$v.MediaVendorAmend.VendorCode.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                        <span class="text-uppercase lbltxt" style="margin-left: 11px">
                           Vendor Business Name
                        </span>
                        <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            v-b-tooltip.hover.v-dark.right.html="' This should be the media supplier name that we are placing the order with. Ie: website name, publication or station call letters'
                                ">
                            <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path
                                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </slot>
                    <b-form-input @blur="SetDirty('VendorBusinessName')" @focus="ResetDirty('VendorBusinessName')"
                        autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="26"
                        v-model="MediaVendorAmend.VendorBusinessName"
                        placeholder="Enter Vendor Business Name" disabled ></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.VendorBusinessName.$dirty && !$v.MediaVendorAmend.VendorBusinessName.required">
                        Required field
                    </div>
                </div>
                <div v-if="MediaVendorAmend.IsRemittanceNameAddressChanged">
                    <div class="fieldborder">
                        <slot name="label" class="lbltxt">
                            <span class="lbltxt" style="margin-left: 11px">
                                INVOICE ATTACHMENT
                            </span>
                        </slot>
                        <vue-dropzone v-on:vdropzone-mounted="mountedInvoiceAttachmentDropzone" :duplicateCheck="true"
                            ref="InvoiceAttachmentDropzone" id="InvoiceAttachmentDropzone" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="InvoiceAttachmentUploadedToDropzone"
                            v-on:vdropzone-removed-file="InvoiceAttachmentRemovedFromDropzone">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="
                                    margin-top: -7px !important;
                                    font-family: 'Open Sans';" />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>

                                </p>
                            </div>
                        </vue-dropzone>
                    </div>
                    <div class="fieldborder">
                        <slot name="label" class="lbltxt">
                            <span class="lbltxt" style="margin-left: 11px">
                                VENDOR REMITTANCE NAME
                            </span>
                        </slot>
                        <b-form-input @blur="SetDirty('VendorRemittanceName')" @focus="ResetDirty('VendorRemittanceName')"
                            autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="`${MediaVendorAmend.VendorTypeSystem == 'PRINT' ? 30 : 20}`"
                            v-model="MediaVendorAmend.VendorRemittanceName"
                            placeholder="Enter Vendor Remittance Name"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.MediaVendorAmend.VendorRemittanceName.$dirty && !$v.MediaVendorAmend.VendorRemittanceName.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder">
                            <div class="fieldborder">
                                <label>COUNTRY</label>
                                <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Country"
                                    name="RemittanceCountry" :showLabel="false" v-model="MediaVendorAmend.RemittanceCountry" :options="CountryList">
                                </OmniSelect>
                            </div>
                            <div class="fieldborder">
                                <label>STREET ADDRESS LINE 1 </label>
                                <b-form-input @focus="ResetDirty('StreetAddressLine1')" @blur="SetDirty('StreetAddressLine1')"
                                    autocomplete="off" id="StreetAddressLine1" class="omniipclass"
                                    v-model="MediaVendorAmend.StreetAddressLine1" placeholder="Enter Street Address Line1"
                                    :maxlength="`${MediaVendorAmend.VendorTypeSystem == 'PRINT' ? 26 : 24}`"></b-form-input>
                                <div class="error-message-format"
                                    v-if="$v.MediaVendorAmend.StreetAddressLine1.$dirty && !$v.MediaVendorAmend.StreetAddressLine1.required">
                                    Required field
                                </div>
                            </div>
                            <div class="fieldborder" v-if="IsCountryUSA">
                                <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                                <b-form-input @focus="ResetDirty('RemittanceCity')" @blur="SetDirty('RemittanceCity')" autocomplete="off"
                                    id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="MediaVendorAmend.RemittanceCity"
                                    placeholder="Enter City" :maxlength="19"></b-form-input>
                                <div class="error-message-format"
                                    v-if="$v.MediaVendorAmend.RemittanceCity.$dirty && !$v.MediaVendorAmend.RemittanceCity.required">
                                    Required field
                                </div>
                            </div>
                            <div class="row fieldborderomniipclass" v-if="IsCountryUSA">
                                <div class="col-md-8">
                                    <label v-if="IsCountryUSA" for="RemittanceState" class="lbltxt">State</label>
                                    <OmniSelect  :showLabel="false" class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State" name="RemittanceState" :showSearch="true" v-model="MediaVendorAmend.RemittanceState" :options="StateList">
                                    </OmniSelect>
                                </div>
                                <div class="col-md-4">
                                    <label v-if="IsCountryUSA" for="RemittanceZipCode" class="lbltxt">ZIP CODE</label>
                                    <b-form-input autocomplete="off" id="RemittanceZipCode" class="" :minlength="5" v-if="IsCountryUSA" :maxlength="5" v-bind:class="{
                                            redtextbox: (validationtextbox || !$v.MediaVendorAmend.RemittanceZipCode.numeric) && $v.MediaVendorAmend.RemittanceZipCode.$dirty,
                                            notredtextbox: !validationtextbox && $v.MediaVendorAmend.RemittanceZipCode.numeric,
                                        }" @blur="SetDirty('RemittanceZipCode')" @focus="ResetDirty('RemittanceZipCode')"
                                        @keyup="ValidateZipCode()" v-model="MediaVendorAmend.RemittanceZipCode"
                                        placeholder="Enter Zip Code"></b-form-input>
                                    <div v-show="validationtextbox && $v.MediaVendorAmend.RemittanceZipCode.$dirty" class="error-message-format">
                                        Input must be 5 characters
                                    </div>
                                    <div v-show="!$v.MediaVendorAmend.RemittanceZipCode.numeric && $v.MediaVendorAmend.RemittanceZipCode.$dirty"
                                        style="width:135px;" class="error-message-format">
                                        This field can only contain numerical values
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            ACCOUNTING CONTACT NAME
                        </span>
                    </slot>
                    <b-form-input @blur="SetDirty('AccountingContactName')" @focus="ResetDirty('AccountingContactName')"
                        autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="20"
                        v-model="MediaVendorAmend.AccountingContactName"
                        placeholder="Enter Accounting Contact Name"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.AccountingContactName.$dirty && !$v.MediaVendorAmend.AccountingContactName.required">
                        Required field
                    </div>
                </div>

                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            ACCOUNTING CONTACT TITLE
                        </span>
                    </slot>
                    <b-form-input @blur="SetDirty('AccountingContactTitle')" @focus="ResetDirty('AccountingContactTitle')"
                        autocomplete="off" id="cilentcode" class="omniipclass" v-model="MediaVendorAmend.AccountingContactTitle"
                        placeholder="Enter Accounting Contact Title"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.AccountingContactTitle.$dirty && !$v.MediaVendorAmend.AccountingContactTitle.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            ACCOUNTING PHONE #
                        </span>
                    </slot>
                    <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', FormatAccountPhoneNumber]"
                        style="margin-top:1px;" class="form-control" @blur="SetDirty(MediaVendorAmend.AccountingPhone)"
                        @focus="ResetDirty(MediaVendorAmend.AccountingPhone)" autocomplete="off" id="AccountingPhone"
                        :maxlength="24" v-model="MediaVendorAmend.AccountingPhone"
                        placeholder="Enter Accounting Phone #"></the-mask>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.AccountingPhone.$dirty && !$v.MediaVendorAmend.AccountingPhone.required">
                        Required field
                    </div>
                </div>

                <div class="fieldborder">
                    <b-form-group>
                        <label>
                            Accounting Email Address
                        </label>
                        <b-form-input type="email" @focus="ResetDirty('AccountingEmailAddress')"
                            @blur="SetDirty('AccountingEmailAddress')" autocomplete="off"
                            placeholder="Enter Accounting Email Address" v-model="MediaVendorAmend.AccountingEmailAddress"
                            ref="email"></b-form-input>
                        <div class="error-message-format" v-if="$v.MediaVendorAmend.AccountingEmailAddress.$dirty">
                            <span v-show="!$v.MediaVendorAmend.AccountingEmailAddress.required">
                                Required field
                            </span>
                            <span v-show="!$v.MediaVendorAmend.AccountingEmailAddress.email">
                                Please enter a valid email address (eg. test@finsys.com)
                            </span>

                        </div>
                    </b-form-group>
                </div>

                <div class="fieldborder" style="margin-top:10px">
                    <b-form-group label="How was this information obtained?" class="mb-n3 timeonly-text">
                        <div class="form-group" style="margin-left: 14px">
                            <div class="text-left" style="margin-top: 11px">
                                <b-form-checkbox v-model="MediaVendorAmend.InfoFromSourceIsVerbal" :value="true" label=""
                                    inline>
                                    Verbal
                                </b-form-checkbox>
                            </div>
                        </div>

                        <div class="form-group" style="margin-left: 14px">
                            <div class="text-left" style="margin-top: 11px">
                                <b-form-checkbox v-model="MediaVendorAmend.InfoFromSourceIsWritten" :value="true" inline>
                                    Written
                                </b-form-checkbox>
                            </div>
                        </div>
                    </b-form-group>
                </div>

                <div class="fieldborder">
                    <b-form-group class="mb-1" v-if="InfoFromSourceDisplayVerbal">
                        <div>
                            <label>Verbal </label>
                            <OmniSelect placeholder="Select Verbal" name="Media" :showLabel="false"
                                :options="InfoFromSourceVerbalOptions" label="Select Verbal"
                                v-model="MediaVendorAmend.InfoFromSourceVerbal" />
                        </div>
                    </b-form-group>
                </div>

                <div class="fieldborder" v-if="InfoFromSourceDisplayWritten">
                    <b-form-group>
                        <div>
                            <label>Written</label>
                            <OmniSelect placeholder="Select Written" name="Written" :showLabel="false"
                                :options="InfoFromSourceWrittenOptions" label="Select Written"
                                v-model="MediaVendorAmend.InfoFromSourceWritten" />
                        </div>

                        <label class="mt-3">Attach Written</label>
                        <vue-dropzone v-on:vdropzone-mounted="mountedWrittenAttachmentDropzone" :duplicateCheck="true"
                            ref="myVueDropzoneWrittenSource" id="myVueDropzoneWrittenSource" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="UploadedtoDropzoneSource"
                            v-on:vdropzone-removed-file="RemovedFromDropzoneSource">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="
                                    margin-top: -7px !important;
                                    font-family: 'Open Sans';" />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>

                                </p>
                            </div>
                        </vue-dropzone>
                    </b-form-group>
                </div>

                <div class="fieldborder" style="margin-top:10px">
                    <label>Who at the vendor provided this information? </label>
                    <br />
                    <label>Name </label>
                    <b-form-input @blur="SetDirty('InfoFromName')" @focus="ResetDirty('InfoFromName')" autocomplete="off"
                        id="InfoFromName" class="omniipclass" :maxlength="36" v-model="MediaVendorAmend.InfoFromName"
                        placeholder="Name"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.InfoFromName.$dirty && !$v.MediaVendorAmend.InfoFromName.required">
                        Required field
                    </div>

                    <label>Title </label>
                    <b-form-input @blur="SetDirty('InfoFromTitle')" @focus="ResetDirty('InfoFromTitle')" autocomplete="off"
                        id="InfoFromTitle" class="omniipclass" :maxlength="36" v-model="MediaVendorAmend.InfoFromTitle"
                        placeholder="Title"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.InfoFromTitle.$dirty && !$v.MediaVendorAmend.InfoFromTitle.required">
                        Required field
                    </div>

                    <label>Phone Number </label>
                    <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', FormatPhoneNumber]"
                        style="margin-top:1px;" class="form-control" @blur="SetDirty(MediaVendorAmend.InfoFromPhone)"
                        @focus="ResetDirty(MediaVendorAmend.InfoFromPhone)" autocomplete="off" id="InfoFromPhone" :maxlength="24"
                        v-model="MediaVendorAmend.InfoFromPhone" placeholder="Phone Number"></the-mask>
                    <div class="error-message-format"
                        v-if="$v.MediaVendorAmend.InfoFromPhone.$dirty && !$v.MediaVendorAmend.InfoFromPhone.required">
                        Required field
                    </div>
                </div>

                <div class="fieldborder mt-3">
                    <b-form-group label="" class="mb-2 IdentificationOption-text">
                        <label style="line-height: 16px;">Has there been a remittance name change for this vendor or a change in ownership?</label>
                        <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="IdentificationOption"
                            v-model="MediaVendorAmend.IdentificationOption" name="IdentificationOption">
                            <b-form-radio value="true">Yes</b-form-radio>
                            <b-form-radio value="false">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                    <div class="fieldborder row">
                        <div class="col-md-6">
                            <div>
                                <label>IDENTIFICATION NUMBER</label>
                                <OmniSelect placeholder="Select Identification Code" name="Media" :showLabel="false"
                                    :options="Identificationnumberslist" label="IDENTIFICATION NUMBER" @input="IDNumberchange"
                                    v-model="MediaVendorAmend.IdentificationType" />
                            </div>

                        </div>
                        <div class="col-md-6" v-show="MediaVendorAmend.IdentificationType">
                            <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '##-########']"
                                style="margin-top:1.5rem;" class="form-control" v-if="IsUSTaxIDSelected"
                                @focus="ResetDirty(MediaVendorAmend.IdentificationType)"
                                @blur="SetDirty(MediaVendorAmend.IdentificationType)" autocomplete="off" id="cilentcode"
                                v-model="MediaVendorAmend[MediaVendorAmend.IdentificationType]"
                                :placeholder="'Enter US Tax ID'"></the-mask>
                            <!-- <b-form-input style="margin-top:1.5rem;" v-if="IsUSTaxIDSelected" @focus="ResetDirty(MediaVendorAmend.IdentificationType)" @blur="SetDirty(MediaVendorAmend.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(MediaVendorAmend.IdentificationType)" v-model="MediaVendorAmend[MediaVendorAmend.IdentificationType]" v-numericOnly :placeholder="'Enter '+MediaVendorAmend.IdentificationType"></b-form-input> -->
                            <!-- <b-form-input style="margin-top:1.5rem;" v-else-if="IsSocialSecurityNumberSelected" @focus="ResetDirty(MediaVendorAmend.IdentificationType)" @blur="SetDirty(MediaVendorAmend.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(MediaVendorAmend.IdentificationType)" v-model="MediaVendorAmend[MediaVendorAmend.IdentificationType]" v-numericOnly :placeholder="'Enter '+MediaVendorAmend.IdentificationType"></b-form-input> -->
                            <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['###', '###-##', '###-##-####']"
                                v-else-if="IsSocialSecurityNumberSelected" style="margin-top:1.5rem;" class="form-control"
                                @focus="ResetDirty(MediaVendorAmend.IdentificationType)"
                                @blur="SetDirty(MediaVendorAmend.IdentificationType)" autocomplete="off" id="socialsecurity"
                                :maxlength="11" v-model="MediaVendorAmend[MediaVendorAmend.IdentificationType]"
                                :placeholder="'Enter Social Security Number'"></the-mask>

                            <b-form-input style="margin-top:1.5rem;" v-else
                                @focus="ResetDirty(MediaVendorAmend.IdentificationType)"
                                @blur="SetDirty(MediaVendorAmend.IdentificationType)" autocomplete="off" id="cilentcode"
                                class="omniipclass" v-model="MediaVendorAmend[MediaVendorAmend.IdentificationType]"
                                :placeholder="'Enter ' + MediaVendorAmend.IdentificationType"></b-form-input>

                            <div class="error-message-format"
                                v-if="MediaVendorAmend.IdentificationType && $v.MediaVendorAmend[MediaVendorAmend.IdentificationType].$dirty">
                                <span v-show="!$v.MediaVendorAmend[MediaVendorAmend.IdentificationType].required">
                                    Required field
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="fieldborder" v-show="MediaVendorAmend.IdentificationType">
                        <label for="axlocatoin" class="lbltxt">{{ AttachmentLabel }}
                            <span>
                                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    v-b-tooltip.hover.v-dark.right.html="' Attachment of a completed W-9 form or Foreign W-8 form signed and dated within the last year, required for any change in ownership'
                                        ">
                                    <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path
                                            d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                            id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                                    </g>
                                </svg>
                            </span>
                        </label>
                        <vue-dropzone v-on:vdropzone-mounted="mountedIdentitificationDropzone" :duplicateCheck="true"
                            ref="identificationDropzone" id="identificationDropzone" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="IdentificationUploadedToDropzone"
                            v-on:vdropzone-removed-file="IdentificationRemovedFromDropzone">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="
                                    margin-top: -7px !important;
                                    font-family: 'Open Sans';" />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>

                                </p>
                            </div>
                        </vue-dropzone>
                    </div>
                <div class="fieldborder mt-2">
                    <b-form-group>
                        <label>
                            Dun & Bradstreet Number
                        </label>
                        <b-form-input type="text" @focus="ResetDirty('DunAndBradstreetNumber')"
                            @blur="SetDirty('DunAndBradstreetNumber')" autocomplete="off"
                            placeholder="Enter Dun & Bradstreet Number" v-model="MediaVendorAmend.DunAndBradstreetNumber"
                            ref="email"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                PREVIOUS
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()"
                :disabled="this.$v.$invalid || !IsVerbalOrWrittenChecked">
                NEXT
            </button>
            <!-- <button  @click="PrintValidation()" class="btn custom-button btn-primary">
            Validation
        </button> -->
        </div>

    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
import { TheMask } from 'vue-the-mask'
import { required, requiredIf, numeric, minLength, helpers } from "vuelidate/lib/validators";
import service from "../../../services/Dropdowns";

const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)

export default {
    components: {
        vueDropzone: vue2Dropzone,
        TheMask
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        },
        address: {
            type: Boolean,
            required: false
        },
        checkData: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            VendorCodeOptions: [{
                label: "label",
                value: "value"
            }],
            InvoiceIdentificationFiles: 0,

            InvoiceAttachmentFile: 0,
            WrittenAttachmentFile: 0,
            IsIdentificationFilesLoadedInEdit: false,
            IsInvoiceAttachmentLoadedInEdit: false,
            IsWrittenAttachmentLoadedInEdit: false,
            Identificationnumberslist: [{
                label: "US TAX ID",
                value: "USTaxId"
            },
            {
                label: "Foreign",
                value: "Foreign"
            },
            {
                label: "Social Security",
                value: "SocialSecurityNumber"
            },
            ],
            InfoFromSourceVerbalOptions: [
                { label: "In Person", value: "In Person" },
                { label: "Phone", value: "Phone" }
            ],
            InfoFromSourceWrittenOptions: [
                { label: "Teams Chat", value: "Teams Chat" },
                { label: "Email", value: "Email" }
            ],
            InvoicesImmediatePaymentOptions: [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" }
            ],
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            CountryList: [],
            StateList: [],
            CountryUnitedStatesValue: "USA - United States",
        }
    },
    validations: {
        MediaVendorAmend: {
            VendorCode: {
                required: required,
            },
            VendorBusinessName: {
                required: required,
            },
            VendorRemittanceName: {
                required: requiredIf(function (model) {
                    if (this.MediaVendorAmend.IsRemittanceNameAddressChanged) {
                        return model;
                    }
                }),
            },
            AccountingContactName: {
                required: required,
            },
            AccountingContactTitle: {
                required: required,
            },
            AccountingPhone: {
                required: required,
                minLength: minLength(10)
            },
            AccountingEmailAddress: {
                required: required,
                email: EmailWithoutCase
            },
            IdentificationType: {
                required: requiredIf(function (model) {
                    return model.IdentificationOption == 'true';
                }),
            },
            USTaxId: {
                required: requiredIf(function (model) {
                    return model.IdentificationType == "USTaxId" && model.IdentificationOption == 'true';
                }),
                numeric,
                minLength: minLength(9)
            },
            SocialSecurityNumber: {
                required: requiredIf(function (model) {
                    return model.IdentificationType == "SocialSecurityNumber" && model.IdentificationOption == 'true';
                }),
                numeric,
                minLength: minLength(9),
            },
            Foreign: {
                required: requiredIf(function (model) {
                    return model.IdentificationType == "Foreign" && model.IdentificationOption == 'true';
                })
            },
            IdentificationFiles: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return (model.IdentificationType == "USTaxId" || model.IdentificationType == "SocialSecurityNumber" || model.IdentificationType == "Foreign") && model.IdentificationOption == 'true';
                })
            },
            InfoFromSourceIsVerbal: {
                required: requiredIf(function () {
                    return !this.MediaVendorAmend.InfoFromSourceIsWritten;
                }),
            },
            InfoFromSourceIsWritten: {
                required: requiredIf(function () {
                    return !this.MediaVendorAmend.InfoFromSourceIsVerbal;
                }),
            },
            InfoFromSourceVerbal: {
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsVerbal == true || model.InfoFromSourceIsVerbal == "true";
                }),
            },
            InfoFromSourceWritten: {
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsWritten == true || model.InfoFromSourceIsWritten == "true";
                }),
            },
            SetupInfoSource: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsWritten == "true" || model.InfoFromSourceIsWritten == true;
                })
            },
            InfoFromName: {
                required: required,
            },
            InfoFromTitle: {
                required: required,
            },
            InfoFromPhone: {
                required,
                minLength: minLength(10)
            },
            IdentificationOption: {
                required: required,
            },
            DunAndBradstreetNumber: {
                required:false
            },
            InvoiceAttachment: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    if (this.MediaVendorAmend.IsRemittanceNameAddressChanged) {
                        return model;
                    }
                }),
            },
            RemittanceCountry: {
                required: requiredIf(function (model) {
                    if (this.MediaVendorAmend.IsRemittanceNameAddressChanged) {
                        return model;
                    }
                }),
            },
            StreetAddressLine1: {
                required: requiredIf(function (model) {
                    if (this.MediaVendorAmend.IsRemittanceNameAddressChanged) {
                        return model;
                    }
                }),
            },
            RemittanceCity: {
                required: requiredIf(function () {
                    return this.MediaVendorAmend.IsRemittanceNameAddressChanged && this.MediaVendorAmend.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
            },
            RemittanceState: {
                required: requiredIf(function () {
                    return this.MediaVendorAmend.IsRemittanceNameAddressChanged && this.MediaVendorAmend.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
            },
            RemittanceZipCode: {
                required: requiredIf(function () {
                    return this.MediaVendorAmend.IsRemittanceNameAddressChanged && this.MediaVendorAmend.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
                numeric,
            },

        }
    },
    computed: {
        FormatPhoneNumber() {
            const infoFromPhone = this.MediaVendorAmend.InfoFromPhone || '';
            return infoFromPhone.length > 10 ? '####################' : '1-###-###-#####';
        },
        FormatAccountPhoneNumber() {
            const accountingPhone = this.MediaVendorAmend.AccountingPhone || '';
            return accountingPhone.length > 10 ? '####################' : '1-###-###-#####';
        },
        IsVerbalOrWrittenChecked() {
            return this.InfoFromSourceDisplayWritten || this.InfoFromSourceDisplayVerbal;
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('vendormediaamend', {
            MediaVendorAmend: 'MediaVendorAmend',
        }),
        IsUSTaxIDSelected() {
            return this.MediaVendorAmend.IdentificationType == 'USTaxId';
        },
        IsSocialSecurityNumberSelected() {
            return this.MediaVendorAmend.IdentificationType == 'SocialSecurityNumber';
        },
        AttachmentLabel() {
            return this.MediaVendorAmend.IdentificationType == 'SocialSecurityNumber' || this.MediaVendorAmend.IdentificationType == 'USTaxId' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        InfoFromSourceDisplayVerbal() {
            return this.MediaVendorAmend.InfoFromSourceIsVerbal;
        },
        InfoFromSourceDisplayWritten() {
            return this.MediaVendorAmend.InfoFromSourceIsWritten;
        },
        IsCountryUSA() {
            return this.MediaVendorAmend.RemittanceCountry != null ? this.MediaVendorAmend.RemittanceCountry == this.CountryUnitedStatesValue : null;
        },
    },
    watch: {
        "MediaVendorAmend.AccountingPhone": function() {
            if (this.MediaVendorAmend.AccountingPhone.length > 10) {
                this.FormatAccountPhoneNumber = true;
            }
            else {
                this.FormatAccountPhoneNumber = false;
            }
        },
        "MediaVendorAmend.InfoFromPhone": function() {
            if (this.MediaVendorAmend.InfoFromPhone.length > 10) {
                this.FormatPhoneNumber = true;
            }
            else {
                this.FormatPhoneNumber = false;
            }
        },
        "MediaVendorAmend.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {
                if (this.IsInvoiceAttachmentLoadedInEdit == false) {
                    this.ManuallyUploadFilesInvoiceAttachment(this.MediaVendorAmend.InvoiceAttachment);
                    this.$set(this.MediaVendorAmend, "InvoiceAttachment", this.GetFilesObjectSource());
                }
                if (this.IsIdentificationFilesLoadedInEdit == false) {
                    this.ManuallyUploadFilesIdentification(this.MediaVendorAmend.IdentificationFiles);
                    this.$set(this.MediaVendorAmend, "IdentificationFiles", this.GetIDFilesObject());
                }
                if (this.IsWrittenAttachmentLoadedInEdit == false) {
                    this.ManuallyUploadFilesWrittenAttachment(this.MediaVendorAmend.SetupInfoSource);
                    this.$set(this.MediaVendorAmend, "SetupInfoSource", this.GetFilesObjectWritten());
                }
            }
        },
        "MediaVendorAmend.InfoFromSourceIsVerbal": function (val) {
            if(val == false || val == 'false') {
                this.MediaVendorAmend.InfoFromSourceVerbal=null;
            }
        },
        "MediaVendorAmend.InfoFromSourceIsWritten": function (val) {
            if(val == false ||  val == 'false') {
                this.MediaVendorAmend.InfoFromSourceWritten=null;
            }
        },
        "MediaVendorAmend.RemittanceCountry": function (val) {
            if (val) {
                this.MediaVendorAmend.RemittanceCity = '';
                this.MediaVendorAmend.RemittanceState = '';
                this.MediaVendorAmend.RemittanceZipCode = '';
            }
        },
        "MediaVendorAmend.VendorTypeSystem": function (val) {
            if(val) {
                this.MediaVendorAmend.VendorRemittanceName = '';
                this.MediaVendorAmend.StreetAddressLine1 = '';
            }
        },
    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    mounted() {
        this.LoadDropdownValues();
        this.GetVendorCodeData();
    },
    methods: {
        IDNumberchange() {
            this.MediaVendorAmend.USTaxId = "";
            this.MediaVendorAmend.SocialSecurityNumber = "";
            this.MediaVendorAmend.Foreign = "";
            this.$refs.identificationDropzone.removeAllFiles()
        },
        PrintValidation() {
            const params = Object.keys(this.$v.MediaVendorAmend.$params);
            var invalidFields = [];
            for (var x = 0; x < params.length; x++) {
                let name = params[x];
                if (this.$v.MediaVendorAmend[name].$invalid) {
                    invalidFields.push(name);
                }
            }
        },
        mountedIdentitificationDropzone() {
            if (this.MediaVendorAmend.IsEditDataLoaded == true &&  this.IsEdit && this.IsIdentificationFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesIdentification(this.MediaVendorAmend.IdentificationFiles);
                this.$set(this.MediaVendorAmend, "IdentificationFiles", this.GetIDFilesObject());
            }
        },
        mountedInvoiceAttachmentDropzone() {
            if (this.MediaVendorAmend.IsEditDataLoaded == true && this.IsEdit && this.IsInvoiceAttachmentLoadedInEdit == false) {
                this.ManuallyUploadFilesInvoiceAttachment(this.MediaVendorAmend.InvoiceAttachment);
                this.$set(this.MediaVendorAmend, "InvoiceAttachment", this.GetFilesObjectSource());
            }
        },
        mountedWrittenAttachmentDropzone() {
            if (this.MediaVendorAmend.IsEditDataLoaded == true && this.IsEdit && this.IsWrittenAttachmentLoadedInEdit == false) {
                this.ManuallyUploadFilesWrittenAttachment(this.MediaVendorAmend.SetupInfoSource);
                this.$set(this.MediaVendorAmend, "SetupInfoSource", this.GetFilesObjectWritten());
            }
        },
        ManuallyUploadFilesIdentification(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded1) => {
                    let file = {
                        size: fileUploaded1.Length,
                        name: fileUploaded1.Name,
                        type: fileUploaded1.Extension,
                    };
                    var url = fileUploaded1.FullName;
                    this.$refs.identificationDropzone.manuallyAddFile(file, url);
                });

            }
            this.IsIdentificationFilesLoadedInEdit = true;
        },
        ManuallyUploadFilesInvoiceAttachment(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded2) => {
                    let file = {
                        size: fileUploaded2.Length,
                        name: fileUploaded2.Name,
                        type: fileUploaded2.Extension,
                    };
                    var url = fileUploaded2.FullName;
                    this.$refs.InvoiceAttachmentDropzone.manuallyAddFile(file, url);
                });

            }
            this.IsInvoiceAttachmentLoadedInEdit = true;
        },
        ManuallyUploadFilesWrittenAttachment(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded3) => {
                    let file = {
                        size: fileUploaded3.Length,
                        name: fileUploaded3.Name,
                        type: fileUploaded3.Extension,
                    };
                    var url = fileUploaded3.FullName;
                    this.$refs.myVueDropzoneWrittenSource.manuallyAddFile(file, url);
                });

            }
            this.IsWrittenAttachmentLoadedInEdit = true;
        },
        IsNumeric(prop) {
            let props = [{
                name: 'USTaxId',
                type: 'numeric'
            }, {
                name: 'SocialSecurityNumber',
                type: 'numeric'
            }, {
                name: 'Foreign',
                type: 'text'
            }];
            let index = props.findIndex(val => val.name == prop);
            if (index > -1) {
                return props[index].type == 'numeric';
            } else {
                return false;
            }
        },
        SetDirty(propertyName) {
            this.$v.MediaVendorAmend[propertyName].$touch();
            this.$v.addressState[propertyName].$touch();
        },
        ResetDirty(propertyName) {
            this.$v.MediaVendorAmend[propertyName].$reset();
        },
        IdentificationUploadedToDropzone() {
            this.InvoiceIdentificationFiles = this.$refs.identificationDropzone.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "IdentificationFiles", this.GetIDFilesObject());
        },
        IdentificationRemovedFromDropzone() {
            this.InvoiceIdentificationFiles = this.$refs.identificationDropzone.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "IdentificationFiles", this.GetIDFilesObject());
        },
        InvoiceAttachmentUploadedToDropzone() {
            this.InvoiceAttachmentFile = this.$refs.InvoiceAttachmentDropzone.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "InvoiceAttachment", this.GetFilesObjectSource());
        },
        InvoiceAttachmentRemovedFromDropzone() {
            this.InvoiceAttachmentFile = this.$refs.InvoiceAttachmentDropzone.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "InvoiceAttachment", this.GetFilesObjectSource());
        },
        UploadedtoDropzoneSource() {
            this.WrittenAttachmentFile = this.$refs.myVueDropzoneWrittenSource.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "SetupInfoSource", this.GetFilesObjectWritten());
        },
        RemovedFromDropzoneSource() {
            this.WrittenAttachmentFile = this.$refs.myVueDropzoneWrittenSource.dropzone.files.length;
            this.$set(this.MediaVendorAmend, "SetupInfoSource", this.GetFilesObjectWritten());
        },
        GetIDFilesObject() {
            let files = [];
            if (typeof this.$refs.identificationDropzone != "undefined" &&
                typeof this.$refs.identificationDropzone.dropzone != "undefined" &&
                this.$refs.identificationDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.identificationDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        GetFilesObjectSource() {
            let files = [];
            if (typeof this.$refs.InvoiceAttachmentDropzone != "undefined" &&
                typeof this.$refs.InvoiceAttachmentDropzone.dropzone != "undefined" &&
                this.$refs.InvoiceAttachmentDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.InvoiceAttachmentDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        GetFilesObjectWritten() {
            let files = [];
            if (typeof this.$refs.myVueDropzoneWrittenSource != "undefined" &&
                typeof this.$refs.myVueDropzoneWrittenSource.dropzone != "undefined" &&
                this.$refs.myVueDropzoneWrittenSource.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzoneWrittenSource.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Amend Credit Card Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Amend Credit Card Info"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Amend Employee Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Amend Employee Info"
                });
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        LoadDropdownValues() {
            this.LoadStates();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.CountryList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.CountryList.findIndex(
                            (i) => i.label == this.CountryUnitedStatesValue
                        );
                        let indexCanada = this.CountryList.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.CountryList.unshift(
                                this.CountryList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                        this.CountryList.forEach(element => { element.value = element.label;});
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.StateList = res.data;
                    this.StateList.forEach(element => { element.value = element.label;});
                })
                .catch((err) => {
                    alert(err);
                });
        },
    }
}
</script>

<style>
fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}

/*file upload starts*/
.customdropzone {
    width: 411px !important;
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    /* margin-left: 97px !important; */
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.draganddropspan {
    color: #00a1d2;
}

/*file upload ends*/
</style>
