<template>
    <div class="panel-list-new row review-component">
        <b-card class="col-lg-10 mx-auto">
            <div class="panel-list">

                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Amend Employee Info')">
                                <b-img class="edit-icon" :src="editIcon" /> EMPLOYEE/REQUEST TYPE INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>EMPLOYEE PHONE NUMBER</label>
                            <p v-if="!ProdEXPAmend.EmployeePhoneNumber">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.EmployeePhoneNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ENTITY</label>
                            <p>{{ ProdEXPAmend.EntityName }}</p>
                            <p v-if="!ProdEXPAmend.EntityName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>AGENCY</label>
                            <p v-if="!ProdEXPAmend.AgencyName">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.AgencyName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>REQUEST TYPE</label>
                            <p v-if="!ProdEXPAmend.RequestType">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.RequestType }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Select type of change requested</label>
                            <p v-if="ProdEXPAmend.ChangeType == 'Both'">
                                Address & Banking
                            </p>
                            <p v-else>
                                {{ ProdEXPAmend.ChangeType }}
                            </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Amend Setup Info')">
                                <b-img class="edit-icon" :src="editIcon" /> SETUP INFO
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>VENDOR CODE</label>
                            <p v-if="!ProdEXPAmend.VendorCodeOptions">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.VendorCodeOptions }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>VENDOR BUSINESS NAME</label>

                            <p>
                                {{ ProdEXPAmend.VendorBusinessName }}
                            </p>
                            <p v-if="!ProdEXPAmend.VendorBusinessName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Invoice Attachment(s)</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdEXPAmend.InvoiceAttachment" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdEXPAmend.InvoiceAttachment, ProdEXPAmend.Files, 'InvoiceAttachment')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.ProdEXPAmend.ChangeType == 'Both' ||  this.ProdEXPAmend.ChangeType == 'Address'">
                            <label>COUNTRY</label>

                            <p>
                                {{ ProdEXPAmend.RemittanceCountry }}
                            </p>
                            <p v-if="!ProdEXPAmend.RemittanceCountry">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.ProdEXPAmend.ChangeType == 'Both' ||  this.ProdEXPAmend.ChangeType == 'Address'">
                            <label>STREET ADDRESS LINE 1</label>

                            <p>
                                {{ ProdEXPAmend.StreetAddressLine1 }}
                            </p>
                            <p v-if="!ProdEXPAmend.StreetAddressLine1">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="(this.ProdEXPAmend.ChangeType == 'Both' ||  this.ProdEXPAmend.ChangeType == 'Address') && this.ProdEXPAmend.RemittanceCountry == CountryUSA">
                            <label>CITY</label>

                            <p>
                                {{ ProdEXPAmend.RemittanceCity }}
                            </p>
                            <p v-if="!ProdEXPAmend.RemittanceCity">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="(this.ProdEXPAmend.ChangeType == 'Both' ||  this.ProdEXPAmend.ChangeType == 'Address') && this.ProdEXPAmend.RemittanceCountry == CountryUSA">
                            <label>STATE</label>

                            <p>
                                {{ ProdEXPAmend.RemittanceState }}
                            </p>
                            <p v-if="!ProdEXPAmend.RemittanceState">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="(this.ProdEXPAmend.ChangeType == 'Both' ||  this.ProdEXPAmend.ChangeType == 'Address') && this.ProdEXPAmend.RemittanceCountry == CountryUSA">
                            <label>ZIP CODE</label>

                            <p>
                                {{ ProdEXPAmend.RemittanceZipCode }}
                            </p>
                            <p v-if="!ProdEXPAmend.RemittanceZipCode">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING CONTACT NAME</label>
                            <p v-if="!ProdEXPAmend.AccountingContactName">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.AccountingContactName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING TITLE</label>
                            <p v-if="!ProdEXPAmend.AccountingTitle">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.AccountingTitle }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING PHONE #</label>

                            <p>
                                {{ ProdEXPAmend.AccountingPhone }}
                            </p>
                            <p v-if="!ProdEXPAmend.AccountingPhone">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>ACCOUNTING EMAIL ADDRESS</label>
                            <p v-if="!ProdEXPAmend.AccountingEmailAddress">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.AccountingEmailAddress }}
                            </p>
                        </b-col>

                        <b-col cols="lg-12">
                            <b-row class="panel-content">
                                <b-col cols="lg-12">
                                    <label>Who at the vendor provided this information?</label>
                                </b-col>
                                <b-col cols="lg-4">
                                    <label>Name</label>
                                    <p>
                                        {{ ProdEXPAmend.InfoFromName }}
                                    </p>
                                </b-col>
                                <b-col cols="lg-4">
                                    <label>Title</label>
                                    <p>
                                        {{ ProdEXPAmend.InfoFromTitle }}
                                    </p>
                                </b-col>
                                <b-col cols="lg-4">
                                    <label>Telephone Number</label>
                                    <p>
                                        {{ ProdEXPAmend.InfoFromPhone }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>How was this information obtained?</label>
                            <p v-if="!ProdEXPAmend.InfoFromSourceIsVerbal && !ProdEXPAmend.InfoFromSourceIsWritten">
                                N/A
                            </p>
                            <p>
                                <span v-if="ProdEXPAmend.InfoFromSourceIsVerbal">Verbal</span>
                                <span v-if="ProdEXPAmend.InfoFromSourceIsVerbal && ProdEXPAmend.InfoFromSourceIsWritten">
                                    &
                                </span>
                                <span v-if="ProdEXPAmend.InfoFromSourceIsWritten">Written</span>
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.InfoFromSourceVerbal">
                            <label>Verbal</label>
                            <p>
                                {{ ProdEXPAmend.InfoFromSourceVerbal }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.InfoFromSourceWritten">
                            <label>Written</label>
                            <p>
                                {{ ProdEXPAmend.InfoFromSourceWritten }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.InfoFromSourceWritten">

                            <label>Written Communication Attachment(s)</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdEXPAmend.SetupInfoSource" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdEXPAmend.SetupInfoSource, ProdEXPAmend.Files, 'SetupInfoSource')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content" v-if="ProdEXPAmend.CurrentW9Attachment == 'No'">
                        <b-col cols="lg-4">
                            <label>DID THE VENDOR PROVIDE A CURRENT W9?</label>
                            <p>
                                NO
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content" v-if="ProdEXPAmend.CurrentW9Attachment == 'Yes'">
                        <b-col cols="lg-4">
                            <label>DID THE VENDOR PROVIDE A CURRENT W9?</label>
                            <p>
                                YES
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>IDENTIFICATION TYPE</label>

                            <p v-if="ProdEXPAmend.IdentificationType === 'SocialSecurityNumber'">SOCIAL SECURITY NUMBER</p>
                            <p v-if="ProdEXPAmend.IdentificationType === 'USTaxID'">US Tax ID</p>
                            <p v-if="ProdEXPAmend.IdentificationType === 'Foreign'">Foreign</p>
                            <p v-if="!ProdEXPAmend.IdentificationType">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>IDENTIFICATION NUMBER</label>

                            <p>
                                <span v-if="ProdEXPAmend.USTaxID">{{ ProdEXPAmend.USTaxID }}</span>
                                <span v-if="ProdEXPAmend.SocialSecurityNumber">{{
                                    formatIdentityType(ProdEXPAmend[ProdEXPAmend.IdentificationType]) }}</span>
                                <span v-if="ProdEXPAmend.Foreign"> {{ ProdEXPAmend.Foreign }}</span>
                            </p>
                            <p v-if="!ProdEXPAmend.IdentificationType">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>IDENTIFICATION Attachment(s)</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdEXPAmend.IdentificationFiles" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdEXPAmend.IdentificationFiles, ProdEXPAmend.Files, 'IdentificationFiles')">Download</span>
                            </div>
                            <p v-if="!ProdEXPAmend.IdentificationFiles">
                                N/A
                            </p>
                        </b-col>
                    </b-row>
                </div>

                <div class="panel-body" v-if="this.ProdEXPAmend.ChangeType == 'Both' || this.ProdEXPAmend.ChangeType == 'Banking'">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Amend Banking Info')">
                                <b-img class="edit-icon" :src="editIcon" /> Banking Info
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>BANK NAME</label>
                            <p v-if="!ProdEXPAmend.BankName">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>BANK ADDRESS LINE 1</label>
                            <p v-if="!ProdEXPAmend.BankAddressLine1">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>BANK ADDRESS LINE 2</label>
                            <p v-if="!ProdEXPAmend.BankAddressLine2">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankAddressLine2 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>COUNTRY</label>
                            <p v-if="!ProdEXPAmend.BankCountry">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.BankCountry == CountryUnitedStatesValue">
                            <label>STATE</label>
                            <p v-if="!ProdEXPAmend.BankState">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.BankCountry == CountryUnitedStatesValue">
                            <label>CITY</label>
                            <p v-if="!ProdEXPAmend.BankCity">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.BankCountry == CountryUnitedStatesValue">
                            <label>ZIP CODE</label>
                            <p v-if="!ProdEXPAmend.BankZipCode">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>BANK ACCOUNT NAME</label>
                            <p v-if="!ProdEXPAmend.AccountName">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.AccountName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>BANK ACCOUNT NUMBER</label>
                            <p v-if="!ProdEXPAmend.AccountNumber">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.AccountNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.BankCountry == CountryUnitedStatesValue">
                            <label>ROUTING NUMBER (US ONLY)</label>
                            <p v-if="!ProdEXPAmend.RoutingNumber">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.RoutingNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.BankCountry != CountryUnitedStatesValue">
                            <label class="text-uppercase">Sort/Swift (Non US)</label>
                            <p v-if="!ProdEXPAmend.BankBIC">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankBIC }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.BankCountry != CountryUnitedStatesValue">
                            <label class="text-uppercase">IBAN (Non US)</label>
                            <p v-if="!ProdEXPAmend.BankIBAN">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.BankIBAN }}
                            </p>
                        </b-col>

                        <b-col cols="lg-4">
                            <label>REMITTANCE ADVICE EMAIL</label>
                            <p v-if="!ProdEXPAmend.RemittanceAdviceEmail">
                                N/A
                            </p>
                            <p>
                                {{ ProdEXPAmend.RemittanceAdviceEmail }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Bank Letter/Voided Check or Invoice Copy with ACH Info</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in ProdEXPAmend.BankLetter" :key="file">{{
                                    file.name
                                    ||
                                    file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(ProdEXPAmend.BankLetter, ProdEXPAmend.Files, 'BankLetter')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>


                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Production Expense Amend Employee Relationship')">
                                <b-img class="edit-icon" :src="editIcon" /> Employee Relationship
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content" v-if="ProdEXPAmend.EmployeeVendorRelationship.length > 0">

                        <template v-if="!IsNoneSelected">
                            <b-col cols="lg-4" v-for="item in ProdEXPAmend.EmployeeVendorRelationship" :key="item">
                                <label>{{ GetRelationshipLabel(item) }}</label>
                                <div>
                                    <p>{{ ProdEXPAmend.EmployeeVendorRelationshipDetails[item] }}</p>
                                </div>
                            </b-col>
                        </template>
                        <b-col cols="lg-4" v-if="IsNoneSelected">
                            <label>Employee Relationship With Vendor</label>
                            <p>None</p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Has any form of compensation or gifts of any type been offered to you or any person
                                related to you in exchange for placing an order with this vendor?</label>
                            <p v-if="ProdEXPAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder == 'true'">YES</p>
                            <p v-else>NO</p>
                        </b-col>
                        <b-col cols="lg-4" v-if="ProdEXPAmend.CompensationReceived">
                            <label>Compensation Received</label>
                            <p>${{ ProdEXPAmend.CompensationReceived }}</p>
                        </b-col>
                    </b-row>

                </div>
            </div>
            <div class="d-block col-lg-10 agree-checkbox agree-checkbox-new">
                <b-form-checkbox value="true" v-model="ProdEXPAmend.Attestation">By checking this box, the requestor is
                    confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the
                    propriety of the vendor and confirm that the information contained on the form is accuraate to the best
                    of their knowledge</b-form-checkbox>
            </div>
        </b-card>
        <b-form-group class="footer-buttons text-right">

            <button class="btn custom-button btn-link" @click="Cancel()">
                Cancel
            </button>
            <button class="btn custom-button btn-primary" v-if="CanGoToSection" @click="Previous()">
                BACK
            </button>

            <button class="btn custom-button btn-primary" :disabled="!IsFormSigned" v-if="!Edit" @click="Submit()">
                Submit
            </button>
            <button class="btn custom-button btn-primary" :disabled="!IsFormSigned" v-if="CanResubmit && !isDraft" @click="Resubmit()">
                Resubmit
            </button>
        </b-form-group>
    </div>
</template>

<script>
import {
    mapGetters
} from "vuex";
import store from "@/store";
import downloadFile from '../../../mixins/downloadFile';
import * as status from "../../../utilities/VendorWorkflowStates";
export default {
    mixins: [downloadFile],
    data() {
        return {
            SpecialRequirement: null,
            AddSpecialbtn: true,
            openspecialreqbox: false,
            EnableSpecialReqEdit: false,
            editIcon: require("@/assets/Icons/edit.svg"),
            CountryUnitedStatesValue: "USA - United States",
            CountryUSA: "USA - United States",
            addIcon: require("@/assets/Icons/add.svg"),
            RelationshipVendors: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee (name) ",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member(name)",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend (name)",
                value: "FriendName"
            },
            {
                "label": "Vendor has a relationship w/ Agency Client",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Vendor of Former employee",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Vendor was former employee of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Vendor has a relationship w/ another Agency employee (list employee name)",
                value: "RelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ],
            RelationshipVendorsLabel: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend's Name",
                value: "FriendName"
            },
            {
                "label": "Explain the nature of the relationship",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former Employee’s Name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        };
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        IsFormSigned() {
            return this.ProdEXPAmend.Attestation == true || this.ProdEXPAmend.Attestation == 'true';
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        AttachmentLabel() {
            return this.ProdEXPAmend.IdentificationType == 'SocialSecurityNumber' || this.ProdEXPAmend.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        IsNoneSelected() {
            return this.ProdEXPAmend.EmployeeVendorRelationship.length == 1 && this.ProdEXPAmend.EmployeeVendorRelationship.findIndex(val => val == "NONE") > -1;
        },
        ...mapGetters('prodexpamend', {
            ProdEXPAmend: 'ProdEXPAmend',
        }),
        CanResubmit() {
            return (
                this.id &&
                parseInt(this.id) > 0 &&
                this.ProdEXPAmend.Status == status.Canceled
            );
        },
        Edit() {
            return this.id && parseInt(this.id) > 0 && !this.isDraft;
        },
        CanGoToSection() {
            return !this.IsEdit || this.CanResubmit;
        },
        GetInfoSource() {
            let text = "";
            if (this.ProdEXPAmend.infofromsourceisverbal) {
                text += "Verbal"
            }
            if (this.ProdEXPAmend.infofromsourceiswritten) {
                text += text == ""
                    ? "Written"
                    : "& Written"
            }
            return text;
        }
    },
    activated() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    created() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    methods: {
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.ProdEXPAmend.IdentificationType == "USTaxID") {
                    return "US Tax ID";
                } else if (this.ProdEXPAmend.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        formatIdentityType(val) {
            if (val) {
                if (this.ProdEXPAmend.IdentificationType == "USTaxID" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.ProdEXPAmend.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        PrepareEmployeeVendorRelationship(prodExpAmendInfo) {
            if (this.IsNoneSelected) {
                let employeeRelationship = {
                    ID: 0,
                    RelationshipName: 'NONE'
                };
                prodExpAmendInfo.EmployeeVendorRelationship = [];
                prodExpAmendInfo.EmployeeVendorRelationship.push(employeeRelationship);
            } else {
                let employeeRelationships = [];
                prodExpAmendInfo.EmployeeVendorRelationship.forEach(employeeRelations => {
                    employeeRelationships.push({
                        ID: 0,
                        RelationshipName: employeeRelations
                    });
                })
                prodExpAmendInfo.EmployeeVendorRelationship = [];
                prodExpAmendInfo.EmployeeVendorRelationship = employeeRelationships;
            }
        },

        PrepareData(isResubmit) {
            let prodExpAmendInfoData = Object.assign({}, this.ProdEXPAmend);
            let ProdEXPAmendData = {};
            ProdEXPAmendData.ID = 0;
            ProdEXPAmendData.EmployeePhoneNumber = this.ProdEXPAmend.EmployeePhoneNumber;
            ProdEXPAmendData.Entity = this.ProdEXPAmend.Entity;
            ProdEXPAmendData.EmployeeAgency = this.ProdEXPAmend.EmployeeAgency;
            ProdEXPAmendData.AgencyName = this.ProdEXPAmend.AgencyName;
            ProdEXPAmendData.RequestType = this.ProdEXPAmend.RequestType;
            ProdEXPAmendData.ChangeType = this.ProdEXPAmend.ChangeType;
            ProdEXPAmendData.VendorCodeOptions = this.ProdEXPAmend.VendorCodeOptions;
            ProdEXPAmendData.VendorBusinessName = this.ProdEXPAmend.VendorBusinessName;
            ProdEXPAmendData.InvoiceAttachment = this.ProdEXPAmend.InvoiceAttachment;
            ProdEXPAmendData.RemittanceCountry = this.ProdEXPAmend.RemittanceCountry;
            ProdEXPAmendData.StreetAddressLine1 = this.ProdEXPAmend.StreetAddressLine1;
            ProdEXPAmendData.RemittanceCity = this.ProdEXPAmend.RemittanceCity;
            ProdEXPAmendData.RemittanceState = this.ProdEXPAmend.RemittanceState;
            ProdEXPAmendData.RemittanceZipCode = this.ProdEXPAmend.RemittanceZipCode;
            ProdEXPAmendData.AccountingContactName = this.ProdEXPAmend.AccountingContactName;
            ProdEXPAmendData.AccountingTitle = this.ProdEXPAmend.AccountingTitle;
            ProdEXPAmendData.AccountingPhone = this.ProdEXPAmend.AccountingPhone;
            ProdEXPAmendData.AccountingEmailAddress = this.ProdEXPAmend.AccountingEmailAddress;
            ProdEXPAmendData.InfoFromName = this.ProdEXPAmend.InfoFromName;
            ProdEXPAmendData.InfoFromTitle = this.ProdEXPAmend.InfoFromTitle;
            ProdEXPAmendData.InfoFromPhone = this.ProdEXPAmend.InfoFromPhone;
            ProdEXPAmendData.InfoFromSourceIsVerbal = this.ProdEXPAmend.InfoFromSourceIsVerbal;
            ProdEXPAmendData.InfoFromSourceIsWritten = this.ProdEXPAmend.InfoFromSourceIsWritten;
            ProdEXPAmendData.InfoFromSourceVerbal = this.ProdEXPAmend.InfoFromSourceVerbal;
            ProdEXPAmendData.InfoFromSourceWritten = this.ProdEXPAmend.InfoFromSourceWritten;
            ProdEXPAmendData.SetupInfoSource = this.ProdEXPAmend.SetupInfoSource;
            ProdEXPAmendData.IdentificationType = this.ProdEXPAmend.IdentificationType;
            ProdEXPAmendData.CurrentW9Attachment = this.ProdEXPAmend.CurrentW9Attachment == "Yes" ? true : false;
            ProdEXPAmendData.USTaxID = this.ProdEXPAmend.USTaxID;
            ProdEXPAmendData.Foreign = this.ProdEXPAmend.Foreign;
            ProdEXPAmendData.SocialSecurityNumber = this.ProdEXPAmend.SocialSecurityNumber;
            ProdEXPAmendData.IdentificationFiles = this.ProdEXPAmend.IdentificationFiles;
            ProdEXPAmendData.BankLetter = this.ProdEXPAmend.BankLetter;
            ProdEXPAmendData.BankName = this.ProdEXPAmend.BankName;
            ProdEXPAmendData.BankAddressLine1 = this.ProdEXPAmend.BankAddressLine1;
            ProdEXPAmendData.BankAddressLine2 = this.ProdEXPAmend.BankAddressLine2;
            ProdEXPAmendData.BankCountry = this.ProdEXPAmend.BankCountry;
            ProdEXPAmendData.SelectedStateName = this.ProdEXPAmend.BankState;
            ProdEXPAmendData.BankCity = this.ProdEXPAmend.BankCity;
            ProdEXPAmendData.BankZipCode = this.ProdEXPAmend.BankZipCode;
            ProdEXPAmendData.AccountName = this.ProdEXPAmend.AccountName;
            ProdEXPAmendData.AccountNumber = this.ProdEXPAmend.AccountNumber;
            ProdEXPAmendData.RoutingNumber = this.ProdEXPAmend.RoutingNumber;
            ProdEXPAmendData.BankBIC = this.ProdEXPAmend.BankBIC;
            ProdEXPAmendData.BankIBAN = this.ProdEXPAmend.BankIBAN;
            ProdEXPAmendData.RemittanceAdviceEmail = this.ProdEXPAmend.RemittanceAdviceEmail;
            ProdEXPAmendData.AnyGiftOrCompensationOfferedToYouToPlaceOrder = this.ProdEXPAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder;
            ProdEXPAmendData.CompensationReceived = this.ProdEXPAmend.CompensationReceived;
            this.PrepareEmployeeVendorRelationship(prodExpAmendInfoData);
            ProdEXPAmendData.EmployeeVendorRelationship = prodExpAmendInfoData.EmployeeVendorRelationship;
            ProdEXPAmendData.EmployeeVendorRelationshipDetails = this.ProdEXPAmend.EmployeeVendorRelationshipDetails;

            ProdEXPAmendData.OmniflowProjectID = 0;
            ProdEXPAmendData.OmniflowProcessID = 0;
            ProdEXPAmendData.Status = new Date();
            ProdEXPAmendData.CompletionDate = new Date();
            ProdEXPAmendData.InActive = false;
             if (isResubmit == true) {
                ProdEXPAmendData.Files = [];
                this.GetFilesCategory().forEach(name => {
                    this.AttachUpdatedFiles(ProdEXPAmendData.Files, name);
                });
            }
            return ProdEXPAmendData;
        },
        AttachFiles(formData, fileCategory) {
            let files = this.ProdEXPAmend[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append(`${fileCategory}[${i}]`, file.file || file);
            }
        },
        AttachUpdatedFiles(filesArray, fileCategory) {
            let files = this.ProdEXPAmend[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                filesArray.push({ Category: fileCategory, Name: file.Name || file.name })
            }
        },
        GetFilesCategory() {
            return ["IdentificationFiles", "SetupInfoSource", "BankLetter", "InvoiceAttachment"];
        },
        Submit() {

            let formData = new FormData();
            let data = this.PrepareData();
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("prodexpamend/AddRequestWithFiles", formData)
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your Amend Production Expense request has been submitted!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while creating Amend Production Expense request. ${ex}`
                        );
                    }
                });
        },
        Resubmit() {
            let formData = new FormData();
            let data = this.PrepareData(true);
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("prodexpamend/UpdateRequestWithFiles", {
                    id: parseInt(this.id),
                    data: formData
                })
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your Amend Production Expense request has been updated!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while Re-submitting Amend Production Expense request. ${ex}`
                        );
                    }
                });
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Amend Employee Relationship",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Amend Employee Relationship"
                });
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendorsLabel.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendorsLabel[relationsshipIndex].label;
            } else {
                return "";
            }
        },

        GotoSection(name) {
            if (this.id) {
                this.$router.push({
                    name: name,
                    params: {
                        id: parseInt(this.id)
                    }
                });
            } else {
                this.$router.push({
                    name: name
                });
            }
            // this.$router.push(`${section}`);
        },

    },
};
</script>

<style scoped>
span.btn.btn-primary.btn-view {
    height: 0;
    width: 94px;
    min-width: 94px;
    padding: 0;
    margin: 0;
    min-height: 22px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.add-product-row {
    font-weight: bold;
    padding-left: 0px;
    height: 9px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #A2A9AD !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
}
</style>
