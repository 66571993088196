import mediaVendorService from "../../services/vendormediaamend";
import lookerService from "../../services/looker";
const getDefaultState = () => {
    return {
        EditFormData: null,
        MediaVendorAmend: {
            BankName: null,
            BankBIC: null,
            BankIBAN: null,
            SelectedCountryName: "",
            BankCountry: null,
            BankState: null,
            SelectedStateName: null,
            BankCity: null,
            BankZipCode: null,
            AccountName: null,
            AccountNumber: null,
            RoutingNumber: null,
            DunAndBradstreetNumber: null,
            AccountingEmailAddress: null,
            AccountingPhone: null,
            AccountingContactName: null,
            AccountingContactTitle: null,
            InfoFromTitle: null,
            InfoFromName: null,
            InfoFromPhone: null,
            VendorRemittanceName: null,
            VendorCode: null,
            VendorTypeCode: null,
            VendorTypeSystem: null,
            VendorTypeMedia: null,
            IsRemittanceNameAddressChanged: null,
            IsBankingInfoChanged: null,
            BankAddressLine1: null,
            BankAddressLine2: null,
            VendorType: null,
            ID: 0,
            IsCreditCardAccepted: null,
            AreThereConditionsOrLimitations: null,
            VendorBusinessName: null,
            IdentificationOption: null,
            VendorInvoiceCopyFiles: [],
            IdentificationType: null,
            USTaxId: null,
            Foreign: null,
            SocialSecurityNumber: null,
            IdentificationFiles: [],
            RemittanceAdviceEmail: null,
            SampleMediaKitFiles: [],
            EmployeePhoneNumber: null,
            EmployeeAgency: null,
            AgencyName: null,
            VendorName: "",
            Attestation: null,
            AnyGiftOrCompensationOfferedToYouToPlaceOrder: null,
            CompensationReceived: null,
            EmployeeVendorRelationshipDetails: {
                FormerEmployeeName: null,
                FamilyMemberName: null,
                FriendName: null,
                VendorRelationshipWithAgencyClient: null,
                VendorOfFormerEmployee: null,
                VendorWasFormerEmployeeOfAgency: null,
                VendorHasRelationshipWithAnotherAgencyEmployee: null,
                Other: null,
                VendorMediaAmendRequestId: 0,
                ID: 0,
            },
            EmployeeVendorRelationship: [],
            // MediaTypes: [],
            MediaType: null, //for normal select ion
            EmployeeRelationships: [],
            Requestor: null,
            IsEditDataLoaded: false,
            CompletedForms: [],
            Status: null,
            InfoFromSourceIsVerbal: false,
            InfoFromSourceIsWritten: false,
            InfoFromSourceVerbal: null,
            InfoFromSourceWritten: null,
            SetupInfoSource: [],
            InvoiceAttachment: [],
            BankLetter: [],
            IsMediaRepRequest: null,
            CreditLimitation: null,
            ChangeType: null,
            RemittanceCountry: null,
            StreetAddressLine1: null,
            RemittanceCity: null,
            RemittanceState: null,
            RemittanceZipCode: null,
            IsACHPayment: null,
            IsBankingInfoCollected: null,
            CreditCardFiles: [],
            HasBankLetterInvoice: null,
            HasACHPaymentSetup: null,
            Entity: null,
            EntityName: null,
        },
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    MediaVendorAmend: (state) => {
        return state.MediaVendorAmend;
    },
    SubmittedFormData: (state) => {
        return state.EditFormData;
    },
};

// actions
const actions = {
    async GetFormData(st, id) {
        await mediaVendorService
            .GetFormData(id, st.rootState.auth.idToken)
            .then((res) => {
                st.commit("loadVendorData", res.data);
                st.commit("loadVendorFiles", res.data);
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    GetDraftFormData(st, id) {
        return mediaVendorService.GetDraftFormData(id, st.rootState.auth.idToken);
    },
    GetRequestWithTaskDetails(st, id) {
        return mediaVendorService.GetRequestWithTaskDetails(
            id,
            st.rootState.auth.idToken
        );
    },
    AddRequest(st, data) {
        return mediaVendorService.AddRequest(data, st.rootState.auth.idToken);
    },
    AddRequestWithFiles(st, data) {
        return mediaVendorService.AddRequestWithFiles(
            data,
            st.rootState.auth.idToken
        );
    },
    ApproveRequestLevel1(st, data) {
        return mediaVendorService.ApproveRequestLevel1(
            data,
            st.rootState.auth.idToken
        );
    },
    ApproveRequestLevel2(st, data) {
        return mediaVendorService.ApproveRequestLevel2(
            data,
            st.rootState.auth.idToken
        );
    },
    AccountingApprove(st, data) {
        return mediaVendorService.AccountingApprove(
            data,
            st.rootState.auth.idToken
        );
    },
    CFOApprove(st, data) {
        return mediaVendorService.CFOApprove(
            data,
            st.rootState.auth.idToken
        );
    },
    CompleteVendorRequest(st, data) {
        return mediaVendorService.CompleteVendorRequest(
            data,
            st.rootState.auth.idToken
        );
    },
    RejectRequestLevel1(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel1"
        );
    },
    RejectRequestLevel2(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel2"
        );
    },
    RejectVendorValidation(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectVendorValidation"
        );
    },
    AccountingReject(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "AccountingReject"
        );
    },
    CFOReject(st, data) {
        return mediaVendorService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "CFOReject"
        );
    },
    GetRequests(st, options) {
        return mediaVendorService.GetRequests(st.rootState.auth.idToken, options);
    },
    RenderUploadedFiles(st, { id, category }) {
        return mediaVendorService.RenderUploadedFiles(
            st.rootState.auth.idToken,
            id,
            category
        );
    },
    UpdateRequestWithFiles(st, { id, data }) {
        return mediaVendorService.UpdateRequestWithFiles(
            id,
            data,
            st.rootState.auth.idToken
        );
    },
    GetUsers(st, { agencyName, name }) {
        return mediaVendorService.GetUsers(
            st.rootState.auth.idToken,
            agencyName,
            name
        );
    },
    SaveAsDraft(st, data) {
        return mediaVendorService.SaveAsDraft(data, st.rootState.auth.idToken);
    },
    GetAuditFormData(st, id) {
        return mediaVendorService.GetAuditFormData(id, st.rootState.auth.idToken);
    },
    GetSystemDropdown(st, data) {
        return lookerService.GetSystemDropdown(st.rootState.auth.idToken, data);
    },
    GetMediaDropdown(st, data) {
        return lookerService.GetMediaDropdown(st.rootState.auth.idToken, data);
    },
    GetCodeDropdown(st, data) {
        return lookerService.GetCodeDropdown(st.rootState.auth.idToken, data);
    },
    GetWorkflowStates(st, id) {
        return mediaVendorService.GetWorkflowStates(id, st.rootState.auth.idToken);
    },
    GetWorkflowHistoryStates(st, id) {
        return mediaVendorService.GetWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },
    DownloadUploadTemplate(st, data) {
        return mediaVendorService.DownloadUploadTemplate(
            st.rootState.auth.idToken,
            data
        );
    },
    PendingVendorApproveorComplete(st, data) {
        return mediaVendorService.PendingVendorApproveorComplete(
            data,
            st.rootState.auth.idToken
        );
    },
};

function GetFilesCategory() {
    return ["IdentificationFiles", "SetupInfoSource", "BankLetter", "InvoiceAttachment", "CreditCardFiles", ];
}
// mutations
const mutations = {
    reset(st) {
        const s = getDefaultState();
        Object.keys(st).forEach((d) => {
            st[d] = s[d];
        });
    },
    loadVendorData(state, data) {
        // state.MediaVendorAmend = data;
        Object.assign(state.MediaVendorAmend, data);
        if (state.MediaVendorAmend.EmployeeVendorRelationship != null) {
            state.MediaVendorAmend.EmployeeVendorRelationship = state.MediaVendorAmend.EmployeeVendorRelationship.map(
                (name) => name.RelationshipName
            );
        }
        state.MediaVendorAmend.IsCreditCardAccepted = data.IsCreditCardAccepted == true ? "true" : "false";
        state.MediaVendorAmend.AreThereConditionsOrLimitations = data.AreThereConditionsOrLimitations == true ? "true" : "false";
        state.MediaVendorAmend.IdentificationOption = data.IdentificationOption == true ? "true" : "false";
        state.MediaVendorAmend.IsMediaRepRequest = data.IsMediaRepRequest == true ? "true" : "false";
        state.MediaVendorAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder = data.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true ? "true" : "false";
        state.MediaVendorAmend.UrlSpecifiedInForm = data.UrlSpecifiedInForm == true ? "true" : "false";
        state.MediaVendorAmend.IsEditDataLoaded = false;
        state.MediaVendorAmend.InfoFromName = data.InfoFromName;
        state.MediaVendorAmend.InfoFromTitle = data.InfoFromTitle;
        state.MediaVendorAmend.InfoFromPhone = data.InfoFromPhone;
        state.MediaVendorAmend.Entity = data.Entity;
        state.MediaVendorAmend.AgencyName = "";
        state.MediaVendorAmend.SelectedCountryName = "";
        state.MediaVendorAmend.InfoFromSourceVerbal = data.InfoFromSourceVerbal;
        state.MediaVendorAmend.InfoFromSourceWritten = data.InfoFromSourceWritten;
        state.MediaVendorAmend.SetupInfoSource = [];
        state.MediaVendorAmend.IdentificationFiles = [];
        state.MediaVendorAmend.BankLetter = [];
        state.MediaVendorAmend.CreditCardFiles = [];
        state.MediaVendorAmend.InvoiceAttachment = [];
        state.MediaVendorAmend.IsBankingInfoCollected = data.IsBankingInfoCollected == true ? "true" : "false";
        state.MediaVendorAmend.IsACHPayment = data.IsACHPayment == true ? "true" : "false";
        state.MediaVendorAmend.HasBankLetterInvoice = data.HasBankLetterInvoice == true ? "true" : "false";
        state.MediaVendorAmend.HasACHPaymentSetup = data.HasACHPaymentSetup == true ? "true" : "false";
    },
    loadVendorFiles(state, data) {
        if (data.Files.length > 0) {
            GetFilesCategory().forEach((file) => {
                if (typeof file == "undefined" || file == null) {
                    return;
                } else {
                    let files = data.Files.filter((catFile) => {
                        return catFile.Category == file;
                    });
                    if (files.length > 0) {
                        files.forEach((originalFile) => {
                            state.MediaVendorAmend[file].push(originalFile);
                        });
                    }
                }
            });
        }
        state.MediaVendorAmend.IsEditDataLoaded = true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};