<template>
    <div style="padding-top: 35px">
        <div class="d-block row">
            <div class="col-lg-12">
                <div class="row" style="margin-top: -48px !important; margin-bottom: 10px !important">
                    <!-- <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="InsideIndex" @input="tabChange()">
                        <b-tab title="All"> </b-tab>
                        <b-tab title="Pending"> </b-tab>
                        <b-tab title="Completed"> </b-tab>
                    </b-tabs> -->
                    <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="OutsideIndex"
                        @input="tabChange()">
                        <b-tab title="Requests"> </b-tab>
                        <!-- <b-tab title="Drafts"> </b-tab> -->
                    </b-tabs>
                </div>
                <div class="row" :style="{
                    'margin-bottom': index != 0 ? '10px' : 'auto',
                }">
                    <button style="margin-left: 9px !important" class="btn btn-link text-uppercase Open-Filter"
                        @click="openfilter()" v-show="!ShowFilter">
                        <b-img width="11" height="9" :src="openFilterIcon" /> Open Filter
                    </button>
                    <div class="offset-lg-3" v-show="ShowFilter">
                        <button style="margin-left: -12px !important" class="btn btn-link text-uppercase Close-Filter"
                            @click="closefilter()">
                            <b-img width="11" height="9" :src="closeFilterIcon" /> Close
                            Filter
                        </button>
                    </div>
                </div>

                <div class="row mb-3">
                    <!-- <div v-bind:class="[
                  {
                    'col-md-6 ': !ShowFilter,
                    'col-md-5 offset-md-3': ShowFilter,
                  },
                ]">
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="text" class="form-control" v-model="filter" placeholder="Search" />
                            </div>
                        </div>
                    </div> -->
                    <div v-bind:class="[
                        {
                            'col-md-4 ': !ShowFilter,
                            'col-md-4 offset-md-3': ShowFilter,
                        },
                    ]">
                        <div class="d-flex">
                                <OmniSelect v-model="InsideIndex" :options="FilterTabOptions" name="" :showLabel="false"
                                    placeholder="" :showSearch="false" />
                                <input type="text" class="ml-2 form-control" v-model="filter" v-on:keyup.enter="onFilterChange()"
                                    placeholder="Search" />
                        </div>

                    </div>
                    <div v-bind:class="[
                        {
                            'col-md-8 d-flex justify-content-end': !ShowFilter,
                            'col-md-9 offset-md-3 d-flex justify-content-start flex-wrap mt-2': ShowFilter,
                        },
                    ]">
                        <div class="download-dropdown mr-4 position-relative">
                            <b-button ref="VendorACHDownload" @click="DownloadVendorACHForm()" id="VendorACHDownload" text="Media ACH Download" class="btn custom-button-new">
                                Media ACH Download
                            </b-button>
                                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                v-b-tooltip.hover.v-dark.right.html="'This form is ONLY to be sent and filled out by the vendor, if they specified on the External Vendor form, that they either do not accept Credit cards, or that there are limitations or fees associated with credit card payments.  If they answered YES to accepting CC’s, do not send them this form.'
                                    ">
                                <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path
                                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                                </g>
                                </svg>
                        </div>
                        <div class="download-dropdown mr-3 position-relative">
                            <b-button ref="VendorFormsDownload" id="VendorFormsDownload" text="External Vendor Forms Download" class="btn custom-button dropdown-toggle">
                                External Forms Download
                            </b-button>
                            <b-popover placement="bottom" display="block" :target="$refs.VendorFormsDownload" variant="primary">
                                <div class="text-left download-dropdown-options">
                                    <p class="m-0 pt-4 pb-2 text-uppercase popoverhover" @click="DownloadVendorForm('ProdExpForm')">External Production/Exp Form</p>
                                    <p class="m-0 pb-4 text-uppercase pt-4 popoverhover" @click="DownloadVendorForm('MediaForm')">External Media Vendor Form</p>
                                </div>
                            </b-popover>
                                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                v-b-tooltip.hover.v-dark.right.html="'As a prerequisite to the New Vendor Submission, you must have a completed form from the vendor before submitting the request. Download forms here.'
                                    ">
                                <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path
                                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                                </g>
                                </svg>
                        </div>
                        <b-button class="btn custom-button ml-2 mr-2" text="Download As Excel" @click="downloadAsExcel()"
                            variant="outline-primary">
                            Download As Excel
                        </b-button>
                        <button class="btn custom-button btn-primary m-0" v-show="(IsReachUser || CanCreateAmendMedia||CanCreateVendorRequest)" ref="popoverButton" id="create-new1"
                            text="Create New">
                            CREATE VENDOR REQUESTS
                        </button>
                        <b-popover placement="top" display="block" :target="$refs.popoverButton" variant="primary">
                            <div class="text-left">
                                <div class="container2" v-if="IsReachUser||CanCreateVendorRequest">
                                    <div class="left">
                                        <!-- <p>
                                            <span class="icon-add-client icon-custom"></span>
                                        </p> -->
                                    </div>
                                    <div class="right">
                                        <p class="popoverhover" @click="CreateProdExpNewMedia('/Request/Vendor/ProdExp/EmployeeInfo')">
                                            NEW PRODUCTION/EXPENSE
                                        </p>
                                    </div>
                                </div>

                                <div class="container2" v-if="IsReachUser||CanCreateVendorRequest">
                                    <div class="left">
                                        <!-- <p>
                                            <span class="icon-add-product icon-custom"></span>
                                        </p> -->
                                    </div>
                                    <div class="right">
                                        <p class="popoverhover" @click="CreateProdExpNewMedia('/Request/MediaVendor/LedgerMedia')">
                                            NEW MEDIA VENDOR
                                        </p>
                                    </div>
                                </div>
                                <div class="container2" v-if="IsReachUser||CanCreateVendorRequest">
                                    <div class="left">
                                        <!-- <p>
                                            <span class="icon-add-product icon-custom"></span>
                                        </p> -->
                                    </div>
                                    <div class="right">
                                        <p class="popoverhover"
                                            @click="CreateProdExpNewMedia('/Request/Vendor/ProdEXPAmends/EmployeeInfo')">
                                            AMEND PRODUCTION/EXPENSE
                                        </p>
                                    </div>
                                </div>
                                <div class="container2" v-if="CanCreateAmendMedia">
                                    <div class="left">
                                        <!-- <p>
                                            <span class="icon-add-product icon-custom"></span>
                                        </p> -->
                                    </div>
                                    <div class="right">
                                        <p class="popoverhover"
                                            @click="createNew('/Request/Vendor/Media/Amend/EmployeeInfo')">
                                            AMEND MEDIA VENDOR
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </b-popover>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-3" v-if="ShowFilter">
                        <div>
                            <div class="form-group col-md-12">
                                <OmniSelect class="omniipclass" :options="RequestTypeOptions" v-model="FilterList.VendorRequestType"
                                    name="Vendor Request Type" placeholder="Filter by RequestType" :showSearch="false" :showSelectAll="true"
                                    selectAllText="All" unSelectAllText="None" />
                                <OmniSelect class="omniipclass" :options="StatusOptions" v-model="FilterList.Status"
                                    name="STATUS" placeholder="Filter by Status" :showSearch="false" :showSelectAll="true"
                                    selectAllText="All" unSelectAllText="None" />
                                <OmniSelect class="omniipclass" :options="AgencyList" v-model="FilterList.Agency"
                                    name="Agency" placeholder="Filter by Agency" :showSearch="false" :showSelectAll="true"
                                    selectAllText="All" unSelectAllText="None" />
                            </div>
                            <div class="row text-right">
                                <div class="col-md-11 p-0">
                                    <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="RestValues()">
                                        RESET
                                    </button>
                                    <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="FilterQuery()">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[
                        { 'col-md-12 ': !ShowFilter, 'col-md-9': ShowFilter },
                    ]">
                        <b-table id="all-items" :per-page="perPage" :items="data.rows" :fields="fields"
                            no-local-sorting=true @sort-changed="sortChange" show-empty striped hover responsive
                            class="all-items">

                            <template v-slot:cell(CreatedDate)="data">
                                <div class="text-center">
                                    {{ data.value | formatDate }}
                                </div>
                            </template>
                            <template v-slot:cell(Actions)="data">

                                <div class="action-cell" style="display:inline">
                                    <b-img width="12" height="12" :src="auditIcon"
                                        @click="showAuditTrail(data.item.ID, data.item.RequestType)" class="audit-trail"
                                        title="Audit Trail" :disable="InsideIndex == 3" v-b-tooltip.hover.top />
                                </div>
                                <div class="action-cell" style="display:inline;padding-left:5px">
                                    <b-img width="12" height="12" v-if="ShowDelete(data.item)" :src="deleteIcon"
                                        @click="deleteRequest(data.item.ID, data.item.RequestType, data.item.Agency)"
                                        class="audit-trail" title="Delete" v-b-tooltip.hover.top />
                                </div>
                                 <div class="action-cell" style="display:inline">
                                    <b-img width="20" height="20" v-if="ShowReassign(data.item)" :src="reassignIcon"
                                        @click="ReassignRequest(data.item)" class="audit-trail"
                                        title="Re-assign" :disable="InsideIndex == 3" v-b-tooltip.hover.top />
                                </div>

                            </template>
                            <template v-slot:cell(VendorBusinessName)="data">

                                <a class="edit-link" @click="navigateToEdit(
                                    data.item.ID,
                                    data.item.RequestType,
                                    data.item.Status, data.item
                                )
                                    ">{{ formatName(data.item) }}</a>
                            </template>

                            <template v-slot:table-colgroup="scope" v-if="allRequest">
                                <col v-for="field in scope.fields" :key="field.key" :style="{
                                    width: field.label === 'Request' ? '300px' : field.key === 'audit' ? '50px' : 'auto',
                                }" />
                            </template>

                            <template v-slot:cell(RequestType)="data">
                                <div class="text-caps text-center">
                                    {{ data.value }}
                                </div>
                            </template>
                            <template v-slot:cell(CompletionDate)="data">
                                <div class="text-center">
                                    {{ data.value | formatDate }}
                                </div>
                            </template>

                            <template v-slot:cell(Requestor)="data">
                                <div>
                                    {{ formatRequestor(data.value) }}
                                </div>
                            </template>

                            <template v-slot:cell(Status)="data">
                                <div style="white-space: nowrap" class="text-left">
                                    <span :style="getBackgroundColor(data.value)">
                                        {{ formatStatus(data.value, data.item.IsDirectorApprovalReassigned) }}
                                    </span>
                                </div>
                            </template>
                            <template slot="custom-foot" v-if="IsPaginationRequired">
                                <b-tr>
                                    <b-td class="custom-pagination" colspan="13">
                                        <b-form-select class="records-per-page" v-model="perPage" :options="RecordsPerPageOption"></b-form-select>
                                        <Pagination style="float: right" :per-page="perPage" aria-controls="open-items" v-model="currentPage" :total-rows="TotalRows" first-number last-number />
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <component :is="currentAuditTrail" v-bind="{ id: requestId, closeAuditModal: closeAuditModal }"
            @closeAudit="closeAudit()"></component>
        <b-modal modal-class="cancel-request" dialog-class="cancel-request" v-model="ShowCancel"
            :no-close-on-backdrop="true" size="sm">
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">Cancel wire transfer request</h5>
                <button class="close" @click="Cancel()"></button>
            </template>
            <div>
                <p class="cancel-info-text">
                    Your wire transfer request will be canceled and this action is permanent. Please provide a brief reason
                    for the cancelation.
                </p>
                <div>
                    <b-textarea :placeholder="'Add details... Minimum 50 characters'" :rows="5" v-model="CancelNotes"
                        class="">

                    </b-textarea>
                </div>

            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="text-right footer-buttons" v-if="ShowCancel == true">
                    <button class="btn custom-button btn-secondary" @click="Cancel()">
                        Never Mind
                    </button>
                    <button :disabled="ShowCancel == true && $v.$invalid" class="btn custom-button btn-primary"
                        @click="CancelRequest()">
                        Cancel Request
                    </button>

                </div>
            </template>
        </b-modal>
        <b-modal no-enforce-focus no-close-on-backdrop size="xl" v-model="ShowReassignModal" class="Title-Text" hide-footer
            title="Re-assign vendor request" style="z-index:1;" @close="onClose">
            <p class="mx-3">
                Do you want to Re-assign the Vendor request pending for Director approval?
            </p>
            <br />
            <div class="text-right">
                <button class="btn custom-button btn-secondary" @click="CancelReassign()">
                    No
                </button>
                <button class="btn custom-button btn-primary" @click="ReassignRequestConfirm()">
                    Yes
                </button>
            </div>
        </b-modal>
    </div>
</template>

<style>
.btn.btn.custom-button.dropdown-toggle.btn-secondary ,.btn.custom-button-new {
    border-color: #00a1d2 !important;
}
.download-dropdown-options p {
    letter-spacing:0 !important;
}
.download-dropdown-options p:hover {
    color: #00a1d2 !important;
}
.download-dropdown svg {
    position: absolute;
    left: auto;
    right: -12px;
    top: 0;
    bottom: auto;
}
.dropdown-form {
    border:1px solid #027fa5;
}
.text-caps {
    text-transform: capitalize;
}

.list-tab {
    font-size: 14px;
    color: #00a1d2 !important;
}

.tooltip-inner {
    background-color: #f7f7f9 !important;
    color: #ffffff !important;
}

.tooltip-inner::before {
    border-top-color: #f7f7f9 !important;
    border-bottom-color: #f7f7f9 !important;
    border-left-color: #f7f7f9 !important;
    border-right-color: #f7f7f9 !important;
}

a.edit-link {
    color: #00a1d2 !important;
}

table.b-table tbody tr td {
    line-height: 1.2 !important;
}

.popover-body>div>div:first-child>div {
    margin-top: 15px !important;
}

.popover-body>div>div:not(:first-child):not(:last-child)>div {
    margin-top: 20px !important;
}

.popover-body>div>div:last-child>div {
    margin-top: 20px !important;
    margin-bottom: 15px !important;
}

.popover-body>div div.left {
    height: 12px !important;
}

.b-table tbody>tr {
    height: 40px !important;
}

.table td {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

table tfoot tr td {
    padding-right: 0px !important;
}

.form-control[placeholder="Search"] {
    width:auto;
    background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
}

/* .form-control[placeholder="Search"]:placeholder-shown {
        position:relative;
        right:0rem;
        background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
    } */
/* .form-control[placeholder="Search"]::-webkit-search-cancel-button{
      -webkit-appearance: none;
        position:relative;
        right:-1.8rem;
    } */
e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #00a1d2 !important;
    border-color: transparent !important;
    color: #fff !important;
}

.e-multi-select-list-wrapper .e-selectall-parent {
    margin-left: 40px !important;
}

.back-arrow:after {
    content: " \00AB";
    width: 4px;
    height: 7px;
}

.btn-link {
    color: #00a1d2 !important;
}

.All-Requests {
    width: 98px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"],
.table.b-table>thead>tr>[aria-sort="ascending"],
.table.b-table>tfoot>tr>[aria-sort="ascending"],
.table.b-table>thead>tr>[aria-sort="descending"],
.table.b-table>tfoot>tr>[aria-sort="descending"] {
    background-position-y: 25px !important;
    background-position-x: 95% !important;
}

.container2:hover p span {
    filter: invert(45%) sepia(81%) saturate(3821%) hue-rotate(167deg) brightness(102%) contrast(101%);
}

.container2:hover p {
    fill: #00a1d2 !important;
    color: #00a1d2 !important;
}

.iconDetails {
    margin-left: -28%;
    float: left;
    height: 14px;
    width: 40px;
}

.container2 {
    width: 270px;
    min-height: 25px;
    float: left;
}

.left {
    float: left;
    width: 45px;
}

.right {
    margin-left: -23px;
    float: left;
    width: 149px;
}

.Apply {
    margin-left: 17px;
    height: 19px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #88d5ec;
    border-radius: 25px !important;
}

.Apply:hover {
    text-decoration: none !important;
    color: white !important;
    background-color: #018db8 !important;
    border-radius: 25px !important;
}

.Filters {
    width: 49px;
    height: 24px;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.Open-Filter {
    /* width: 74px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.All-Requests {
    width: 123px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.Open-Filter {
    padding-top: 16px;
    /* width: 123px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Close-Filter {
    margin-left: 15px;
    padding-top: 20px;
    width: 150px;
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Container {
    width: 1268px;
    height: 353px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffffff;
}

.b-popover p {
    cursor: pointer !important;
}

.tooltip-inner {
    background-color: #2c3865 !important;
}

#body-container>.container {
    padding-top: 30px !important;
}

.btn-info:hover {
    color: #fff;
    background-color: #1388b7 !important;
}

table>tr>td {
    height: 40px !important;
}

.icon-add-client {
    background-image: url("../assets/Icons/add-client.svg");
}

.icon-add-product {
    background-image: url("../assets/Icons/add-product.svg");
}

.icon-add-intercompany {
    background-image: url("../assets/Icons/add-intercompany.svg");
}

.icon-add-vendor {
    background-image: url("../assets/Icons/add-vendor.svg");
}

.icon-custom {
    height: 19px;
    width: 19px;
    background-repeat: no-repeat;
    display: inline-block;
}

.card {
    border: 0px !important;
}

.filter-icon {
    background-image: url("../assets/Icons/f.png");
    background-repeat: no-repeat;
    vertical-align: super;
}

.display-hover {
    height: 20px;
    width: 20px;
    text-align: center;
}

tbody tr:not(:hover) .display-hover>img.action-icons-hover,
tbody tr:not(:hover) .display-hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:not(:hover)>img.action-icons-hover,
tbody tr:hover .display-hover:hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:hover>img.action-icons-hover,
tbody tr:hover .display-hover:not(:hover)>img.action-icons {
    display: inline-block;
}

tbody tr>td {
    height: 40px !important;
    cursor: default !important;
}

tbody tr>td .audit-trail {
    cursor: pointer !important;
}

td.NameHoverclass:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

td.NameHoverclass {
    overflow-wrap: anywhere !important;
    /* text-decoration: underline !important; */
}

#cardheader .card-header {
    background-color: rgba(127, 137, 152, 1) !important;
}

tbody {
    background-color: white !important;
}

.dataTables_length {
    display: none !important;
}

thead {
    background-color: rgba(123, 129, 136, 1) !important;
}

/* .nav-tabs {
      border-bottom: 1px solid white !important;
    } */

.tabs {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.tab-content {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
    padding: 20px !important;
}

/* .input-group {
        display: none !important;
    } */

.vbt-per-page-dropdown {
    display: none !important;
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: calc(100% - 31%) !important;
    }
}

.close {
    outline: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group:not(.e-float-icon-left) {
    border: 1px solid #ced4da !important;
    width: 290px !important;
}

.popoverhover {
    font-family: "Open Sans";
    font-size: 12px;
}

.popoverhoverproduct {
    font-family: "Open Sans";
    font-size: 12px;
}

.clientnamehover :hover {
    text-decoration: underline !important;
}

.omniipclass {
    font-family: "Open Sans" !important;
}

.Open-Filter img,
.Close-Filter img {
    vertical-align: initial !important;
}
</style>

<script>
const moment = require("moment");
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import * as VendorStates from "../utilities/VendorWorkflowStates";
import {ADCountries} from "../utilities/CommonConst";
import {formatRequestor} from "../utilities/Helper";
import {
    mapGetters
} from "vuex";
import {
    requiredIf,
    minLength
} from "vuelidate/lib/validators";
import {UpdateRecordsPerPageOption} from "./../utilities/paginationUtils";
import service from "../services/Dropdowns";
export default {
    components: {
        DateRangePicker,
    },

    data() {
        return {
            AgencyList: [],
            AgencyName: null,
            RecordsPerPageOption: [],
            userEmail: null,
            noData: [],
            reachJobTitle: [],
            reachUserReportees: [],
            IsReachUser: false,
            CanCreateAmendMedia: false,
            CancelNotes: null,
            ShowCancel: false,
            ShowReassignModal: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            collapseIcon: require("@/assets/Icons/arrow-down.svg"),
            expandIcon: require("@/assets/Icons/rightarrow.svg"),
            editIcon: require("@/assets/Icons/edit.svg"),
            reassignIcon: require("@/assets/Icons/Reassign.svg"),
            FilterTabOptions: [{
                label: 'All Requests',
                value: "0"
            },
            {
                label: 'Pending',
                value: "1"
            },
            {
                label: 'Completed',
                value: "2"
            }
            ],
            RequestTypeOptions: [
            {
                label: 'Production',
                value: "production"
            },
            {
                label: 'Expense',
                value: "expense"
            },
            {
                label: 'Media Vendor',
                value: "Media Vendor"
            },
            {
                label: 'Amend Production',
                value: "Amend Production"
            },
            {
                label: 'Amend Media',
                value: "Amend Media"
            }
            ],
            StatusOptions: [],
            MasterClientCodes: [],
            locale: {
                applyLabel: "Set",
                cancelLabel: "Cancel",
            },
            button: "button title",
            InsideIndex: "0",
            OutsideIndex: 0,
            TotalRows: 0,
            NewRows: 0,
            requestType: null,
            requestId: 0,
            omniflowProcessID: 0,
            requestor: null,
            closeAuditModal: true,
            addClient: require("@/assets/Icons/add-client.svg"),
            addProduct: require("@/assets/Icons/add-product.svg"),
            addIntercompany: require("@/assets/Icons/add-intercompany.svg"),
            addVendor: require("@/assets/Icons/add-vendor.svg"),
            closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
            openFilterIcon: require("@/assets/Icons/open-filter-icon.svg"),
            auditIcon: require("@/assets/Icons/auditTrail_icon.svg"),

            filter: null,
            sortBy: "CreatedDate",
            sortDesc: true,
            theme: "row",
            ShowFilter: false,
            OmniflowStatus: {
                ApprovLevel1: "MR Approval",
                ApprovLevel2: "MDA Approval",
                ApprovLevel3: "Financial Systems completion",
                ApprovLevel4: "Open codes in AX and DDS",
                ApprovLevel5: "Update the request with System Code",
                VendorValidation: "Vendor Validation",
                AccountingApproval: "Accounting Approval",
                Rejected: "Reject",
                Completed: "Completed",
                ProductionVendorApprovLevel1: 'Director Approval',
                ProductionVendorApprovLevel2: 'Finance Director Approval',
                ProductionVendorApprovLevel3: 'Vendor Validation Approval',
                ProductionVendorApprovLevel4: 'Pending Vendor Response',
                ProductionVendorApprovLevel5: 'MDA Manager Approval',
                ProductionVendorApprovLevel6: 'MDAAccountingApproval',
                ProductionVendorApprovLevel7: 'Financial Systems Completes'
            },
            FilterList: {
                PaymentType: null,
                CompanyName: null,
                Beneficiary: null,
                Status: [],
                Agency: [],
                VendorRequestType: [],
            },
            RequestTypes: [{
                value: "1",
                label: "Client"
            },
            {
                value: "2",
                label: "Product"
            },
            {
                value: "3",
                label: "Inter Company"
            },
            {
                value: "4",
                label: "Vendor"
            },
            ],
            RequestTypeFields: {
                text: "RequestType",
                value: "Id"
            },

            Status: [],
            Agency: [],
            RequestStatusFields: {
                text: "RequestSts",
                value: "Id"
            },
            MediaTypeField: {
                text: "MediaTypeVal",
                value: "Id"
            },
            showSelectAll: true,
            getFixed: true,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            showfilter: false,
            showfilterOpenItems: false,
            perPage: 10,
            currentPage: 1,
            DateFormat: "MM/DD/YYYY",
            elegant: "black",
            fields: this.buildColumns(),
            data: {
                config: {
                    card_mode: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    pagination_info: true,
                    global_search: {
                        visibility: false,
                    },
                },
                loading: false,
                classes: {
                    tableWrapper: "outer-table-div-class wrapper-class-two grid-table",
                    table: {
                        "table-sm": true,
                    },
                },
                rows: [],
            },
            reachUserData: null,
            timer: null
        };
    },
    validations: {
        CancelNotes: {
            minLength: minLength(50),
            required: requiredIf(function () {
                return this.ShowCancel == true;
            })
        }

    },
    props: {
        allRequest: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        requestid: {
            type: Number,
            required: false,
        },
    },
    async created() {
        await this.getEmailFromStore();
        await this.reachJobLevelValidation();
        await this.getReachUserReportees();
        await this.setStatusOptions();
        this.$store.commit("client/reset");
        this.$on("closeAudit", function () {
            this.closeAudit();
        });
    },
    async mounted() {
        this.LoadAgency();
        this.GetRequests();
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);
            window.console.log(this.$refs.picker);
        });
    },
    watch: {
        InsideIndex: function () {
            this.currentPage = 1;
            this.fields = this.buildColumns();
            this.GetRequests();
        },
        filter(e) {
            if ((e != null && e.length > 2) || e == null || e.length == 0) {
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.currentPage = 1;
                    this.GetRequests();
                }, 500);
            }
        },
        currentPage: function (value) {
            if (value != null && parseInt(value) > 0) {
                this.GetRequests();
            }
        },
        perPage: function () {
            this.currentPage = 1;
            this.GetRequests();
        }
    },
    computed: {
        IsFormFilterValid() {
            return this.FilterList.Status.length > 0 || this.FilterList.Agency.length > 0 || this.FilterList.VendorRequestType.length > 0 ;
        },
        ...mapGetters({
            loggedInUser: "emailAddress"
        }),
        canDeleteProfileRequest() {
            return this.$store.getters.canDeleteProfileRequest;
        },
        IsPaginationRequired() {
            return this.TotalRows != null && parseInt(this.TotalRows) > parseInt(this.perPage);
        },
        CanCreateVendorRequest() {
            return this.$store.getters.canCreateVendorRequest;
        },

        canApprovePendingMrManagerVendorRequest() {
            return this.$store.getters.canApprovePendingMrManagerVendorRequest;
        },

        canApproveRequestLevel2() {
            return this.$store.getters.canApproveLevel2VendorRequest;
        },
        canApprovePendingFinanceDirector() {
            return this.$store.getters.canApprovePendingFinanceDirectorVendorRequest;
        },
        canApprovePendingVendorValidation() {
            return this.$store.getters.canApprovePendingVendorValidation;
        },
        canApprovePendingMdaManager() {
            return this.$store.getters.canApprovePendingMdaManagerVendorRequest;
        },
        canApprovePendingAccountingApproval() {
            return this.$store.getters.canApprovePendingAccountingApprovalVendorRequest;
        },
        canApprovePendingCFOApproval() {
            return this.$store.getters.canApprovePendingCFOApprovalVendorRequest;
        },
        canCompleteVendorRequest() {
            return this.$store.getters.canCompleteVendorRequest;
        },
        canCompleteRequest() {
            return this.$store.getters.canApproveLevel2VendorRequest; //change once permission added
        },
        canReassignDirectorApprover() {
            return this.$store.getters.canReassignDirectorApprover;
        },
        canApproveReassignedDirectorApproval() {
            return this.$store.getters.canApproveReassignedDirectorApproval;
        },
        canApprovePendingDirectorVendorRequest() {
            return this.$store.getters.canApprovePendingDirectorVendorRequest;
        },
        currentAuditTrail() {
            let auditTrailComponent = "";
            if(this.closeAuditModal != true){
                if(this.requestType === "production" || this.requestType === "expense"){
                    auditTrailComponent =  "prodexp-vendor-audit";
                }
                else if(this.requestType === "Media Vendor"){
                    auditTrailComponent =  "media-vendor-audit";
                }
                else if(this.requestType === "Amend Production"){
                    auditTrailComponent =  "prodexp-amend-audit";
                }
                else if(this.requestType === "Amend Media"){
                    auditTrailComponent =  "media-amend-audit";
                }
                else {
                    auditTrailComponent = "";
                }
            }
            return auditTrailComponent;
        },

        CanAccessList() {
            return this.$store.getters.canAccessList;
        },
        userDataMapping() {
            return this.$store.getters.dataMappings;
        },
        AllAgencyAccess() {
            return this.userDataMapping != null && this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e == "*";
        },
        IsAgencyArray() {
            return this.userDataMapping != null && !this.AllAgencyAccess && Array.isArray(this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e);
        },
        CanCreatProductionExpenseNewMediaRequest() {
            return this.reachUserData && this.reachUserData.MatrixEmailID && this.reachUserData.MatrixEmailID.trim() != "";
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
        },
        formatDateAndTime(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY h:mma") : "-";
        },

    },
    methods: {
        DownloadVendorACHForm() {
            const vendorACHForm = 'mediavendor/DownloadMediaVendorACH';
            let selectedFileName = {
                Name: "Media Vendor ACH Form.pdf"
            };
            this.$store
                .dispatch(vendorACHForm, selectedFileName)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: selectedFileName.Extension
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = selectedFileName.Name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex}`
                        );
                    }
                });
        },
        DownloadVendorForm(val) {
            const mediaTemplateForm = 'mediavendor/DownloadUploadTemplate';
            const prodExpTemplateForm = 'prodexpvendor/DownloadUploadTemplate';
            let mediaFileName = {
                Name: "External Form - Media Vendor.pdf"
            };
            let prodExpFileName = {
                Name: "External Form - Production-Expense Vendor.pdf"
            };
            let selectedTemplateForm = '';
            let selectedFileName = '';
            if(val === 'MediaForm') {
                selectedTemplateForm = mediaTemplateForm;
                selectedFileName = mediaFileName;
            }
            if(val === 'ProdExpForm') {
                selectedTemplateForm = prodExpTemplateForm;
                selectedFileName = prodExpFileName;
            }
            this.$store
                .dispatch(selectedTemplateForm, selectedFileName)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: selectedFileName.Extension
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = selectedFileName.Name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex}`
                        );
                    }
                });
        },
        async getEmailFromStore() {
            try {
                const resEmail = await this.$store.dispatch("getEmail");
                this.userEmail = resEmail.toLowerCase();
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        async reachJobLevelValidation() {
            try {
                const resCountry = await this.$store.dispatch("client/GetADEmpDetails", "");
                if (resCountry.data.Country != null && ADCountries.some(country => country.toLowerCase() == resCountry.data.Country.toLowerCase())) {
                    this.CanCreateAmendMedia = true;
                } else {
                    this.CanCreateAmendMedia = false;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
            try {
                const resReach = await this.$store.dispatch("product/GetReachUser", this.userEmail);
                this.reachUserData = resReach.data;
                const authorizedJobTitles = [
                    'OMGUS4-Manager/Supervisor',
                    'OMGUS5-Associate Director',
                    'OMGUS6-Group Director',
                    'OMGUS7-Director',
                    'OMGUS8-Chief/Officer'
                ];

                const reachData = resReach.data && resReach.data.MailID.toLowerCase() === this.userEmail && authorizedJobTitles.includes(resReach.data.JobLevel);

                if (reachData) {
                    this.IsReachUser = true;
                }

            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        async getReachUserReportees() {
            try {
                const reachReportees = await this.$store.dispatch("product/GetReachUserReportees", this.userEmail);
                this.reachUserReportees = reachReportees.data;
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        UserAgency(agency) {
            return this.AllAgencyAccess || (this.IsAgencyArray ? this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e.findIndex(d => d == agency) > -1 : false);
        },
        CreateRequest() {
            this.$router.push('/Request/ProfileUnlock');
        },
        DeleteRequest() {
            this.ShowCancel = true;
        },
        CancelRequest() {
            this.ShowCancel = false;

        },
        Cancel() {
            this.ShowCancel = false;
        },
        CancelReassign() {
            this.ShowReassignModal = false;
        },
        ReassignRequest(item) {
            this.requestId = item.ID;
            this.requestType = item.RequestType;
            this.omniflowProcessID = item.OmniflowProcessID;
            this.requestor = item.Requestor;
            this.ShowReassignModal = true;
        },
        ReassignRequestConfirm(){
            this.ShowReassignModal = false;
            var vendorRequestType = "";
            var data = {
                ID: parseInt(this.requestId),
                ProcessID: this.omniflowProcessID,
                Requestor: this.requestor
            };
            if(this.requestType == "production" || this.requestType == "expense"){
                vendorRequestType = "prodexpvendor";
            }
            else if(this.requestType == "Amend Production"){
                vendorRequestType = "prodexpamend";
            }
            this.$store
                .dispatch(vendorRequestType + "/ReassignDirectorApproval", data)
                .then(() => {
                    this.$store.dispatch(
                        "progress/setSuccessMessage",
                        "Request has been reassigned successfully!"
                    );
                    this.GetRequests();
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while reassigned the request.${ex}`
                        );
                    }
                });
        },
        async setStatusOptions(){
            VendorStates.AllStates.forEach(status => {
                this.StatusOptions.push({
                    label : status,
                    value : status
                })
            });
        },
        deleteRequest(id, requestType, name) {
            var deleteConfirm = confirm(`Are you sure you want to delete the ${requestType} request with name ${name}`);
            if (deleteConfirm == true) {
                this.$store
                    .dispatch("mediavendor/delete", parseInt(id))
                    .then(() => {
                        this.$store.dispatch(
                            "progress/setSuccessMessage",
                            "Request has been deleted successfully!"
                        );
                        this.GetRequests();
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `${ex.response.data}`
                            );
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Error while deleting request.${ex}`
                            );
                        }
                    });
            }
        },
        formatCurrency(row) {
            let value = row.TotalInvoiceAmount;
            if (typeof value !== "number" && isNaN(value)) {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US');
            return formatter.format(value);
            //return value;
        },

        IsRequestor(row) {
            return (row.Requestor != null && row.Requestor.toLowerCase() == this.loggedInUser.toString().toLowerCase());
        },

        isRequestorbyEmail(email) {

            return email.toLowerCase() == this.loggedInUser.toString().toLowerCase();
        },
        ShowDelete(row) {
            return row.Status == "Cancel" && (this.IsRequestor(row) || this.canDeleteProfileRequest);
        },
        ShowReassign(row) {
            return row.Status == VendorStates.DirectorApproval && row.IsDirectorApprovalReassigned != true
            && this.canReassignDirectorApprover && row.IsProtegoUser!=true;
        },
        tabChange() {
            this.fields = this.buildColumns();
            this.applyFilter();
        },
        applyFilter() {
            //this.currentPage = 1;
            if (this.InsideIndex == 1) {
                this.FilterList.Status = [];
                this.FilterList.Status.push(VendorStates.DirectorApproval);
                this.FilterList.Status.push(VendorStates.AccountingApproval);
                this.FilterList.Status.push(VendorStates.MrApproval);
            } else if (this.InsideIndex == 2) {
                this.FilterList.Status = [];
                this.FilterList.Status.push(VendorStates.Final);
            } else {
                this.FilterList.Status = [];
            }
            this.currentPage = 1;
            this.GetRequests();
        },
        formatRequestor(email) {
          return formatRequestor(email)
        },
        formatHFMCode(HFMCode) {
            let masterClientCode = "";
            if (HFMCode) {
                let masterClientCodeExists = (HFMCode.match(/-/g) || []).length;
                if (masterClientCodeExists > 1) {
                    masterClientCode = HFMCode.substring(0, HFMCode.indexOf("-"));
                } else {
                    let customEntity = HFMCode.split('–');
                    if (customEntity.length > 1) {
                        masterClientCode = customEntity[1];
                    }
                }
            }
            return masterClientCode;
        },
        IsValidDate(completionDate) {
            return (
                completionDate != null &&
                moment(completionDate.startDate).isValid() &&
                moment(completionDate.endDate).isValid()
            );
        },
        formatRequested(row) {
            if (row.RequestType == "Profile Unlock") {
                if (row.ClientCode) {
                    return `Client Code: ${row.ClientCode} | Media  : ${row.UnlockMediaType}`;
                } else {
                    return "";
                }
            }
        },
        formatName(row) {
            if (row.RequestType == "Media Vendor") {
                return `${row.VendorBusinessName}`;
            } else if (row.RequestType == "production" || row.RequestType == "expense") {
                return `${row.VendorBusinessName}`;
            }
            else if (row.RequestType == "Amend Production") {
                return `${row.VendorBusinessName}`;
            }
            else if (row.RequestType == "Amend Media") {
                return `${row.VendorBusinessName}`;
            }
            else {
                return "";
            }
        },

        formatBillingParty(row) {
            if (row.BillingParty) {
                return row.BillingParty;
            } else {
                return '-';
            }
        },
        formatStatus(status,isDirectorApprovalReassigned) {
            if (this.InsideIndex == 3) {
                return "Draft";
            } else if (status != null && status != '') {
                if (status == "Final" || status == "Done" || status == "Complete" || status == "Completed") {
                    return "Completed";
                } else if (status == "Cancel" || status == "Rejected" || status == "Reject") {
                    return "Rejected";
                } else if (status == VendorStates.VendorValidationRejected) {
                    return status;
                }
                else {
                    if(status == VendorStates.DirectorApproval && isDirectorApprovalReassigned) {
                        return `Pending ${status} - Reassigned`;
                    }
                    else {
                        return `Pending ${status}`;
                    }
                }
            } else {
                return '-';
            }
        },
        onFiltered(e) {
            if ((e != null && e.length > 2) || e == null || e.length == 0) {
                this.currentPage = 1;
                this.GetRequests();
            }
        },
        sortChange(e) {
            this.sortBy = e.sortBy;
            this.sortDesc = e.sortDesc;
            this.GetRequests();
        },
        GetGridOptions() {

            let extrafilters = [];
            if (
                this.FilterList.VendorRequestType != null && this.FilterList.VendorRequestType != ""
            ) {
                extrafilters.push({
                    Field: "RequestType",
                    Operator: "=",
                    Value: this.FilterList.VendorRequestType.join(","),
                });
            }
            if (
                this.FilterList.Status != null && this.FilterList.Status != ""
            ) {
                extrafilters.push({
                    Field: "Status",
                    Operator: "=",
                    Value: this.FilterList.Status.join(","),
                });
            }

            if (this.FilterList.Agency != null && this.FilterList.Agency.length > 0) {
                extrafilters.push({
                    Field: "Agency",
                    Operator: "=",
                    Value: this.FilterList.Agency.join(","),
                });
            }
            return {
                PageSize: parseInt(this.perPage),
                CurrentPageNumber: parseInt(this.currentPage),
                FilterValue: this.filter,
                SortBy: this.sortBy,
                SortDesc: this.sortDesc,
                Filters: extrafilters,
                Status: parseInt(this.InsideIndex),
                IsDraftType: this.OutsideIndex == 1
            };
        },
        buildColumns() {
            let columns = [];
            if (this.InsideIndex != 3) {
                columns.push({
                    key: "Actions",
                    label: "Actions",
                });
            }
            columns.push({
                key: "AgencyName",
                label: "Agency",
                sortable: false,
            })
            columns.push({
                key: "VendorCode",
                label: "Vendor Code",
            })
            columns.push({
                key: "VendorBusinessName",
                label: "Vendor Business Name",
            })
            columns.push({
                key: "Requestor",
                label: "Requestor",
                sortable: true,
            })
            columns.push({
                key: "RequestType",
                label: "Request Type",
                sortable: false,
            })
            columns.push({
                key: "Status",
                label: "Status",
                sortable: true,
            })
            columns.push({
                key: "CreatedDate",
                label: "Requested Date",
                sortable: true,
            })
            columns.push({
                key: "CompletionDate",
                label: "Completed Date",
                sortable: true,
            });

            if (this.allRequest && this.InsideIndex != 3) {
                columns.push({
                    key: "CompletionDate",
                    label: "Date Completed",
                    sortable: true,
                    align: 'center'
                });
            }
            return columns;
        },
        downloadAsExcel() {
            this.$store
                .dispatch("mediavendor/DownloadList", this.GetGridOptions())
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Requests_${moment().format("MMDDYYYYmmhhss")}.xlsx`;
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error downloading requests.`
                    );
                });
        },
        closeAudit() {
            this.closeAuditModal = true;
        },
        showAuditTrail(id, requestType) {
            this.requestId = id;
            this.requestType = requestType;
            this.closeAuditModal = false;
        },
        workflowAccessIssueAlert() {
            this.$store.dispatch(
                "progress/setErrorMessage",
                `Don't have permission to approve the request`
            );
        },
        redirectToReview(id) {
            this.$router.push({
                name: `WireTransferReview`,
                params: {
                    id: parseInt(id),
                },
            });
        },
        navigateToEdit(id, requestType, status, row) {
            if (this.OutsideIndex == 1) {
                if (requestType == "production" || requestType == "expense") {
                    this.$router.push({
                        name: `production/expense`,
                        params: {
                            id: parseInt(id),
                            isDraft: true
                        },
                    });
                } else if (requestType == "Media Vendor") {
                    this.$router.push({
                        name: `MediaVendor`,
                        params: {
                            id: parseInt(id),
                            isDraft: true
                        },
                    });
                }else if (requestType == "Amend Media") {
                    this.$router.push({
                        name: `MediaVendorAmend`,
                        params: {
                            id: parseInt(id),
                            isDraft: true
                        },
                    });
                }
            }
            else {
                if (requestType == "Media Vendor") {
                    if (status == VendorStates.MrApproval) {
                        if (this.canApprovePendingMrManagerVendorRequest) {
                            this.$router.push({
                                path: `/MediaVendorApproveLevel1/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    }
                    else if (status == VendorStates.VendorValidation ||status == VendorStates.VendorValidationRejected) {
                        if (this.canApprovePendingVendorValidation) {
                            this.$router.push({
                                path: `/MediaVendorValidation/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    }
                    else if (status == VendorStates.MdaManagerApproval) {
                        if (this.canApprovePendingMdaManager) {
                            this.$router.push({
                                path: `/MediaVendorApproveLevel2/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.AccountingApproval) {
                        if (this.canApprovePendingAccountingApproval) {
                            this.$router.push({
                                path: `/MediaVendorAccountingApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.CfoApproval) {
                        if (this.canApprovePendingCFOApproval) {
                            this.$router.push({
                                path: `/MediaVendorCFOApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.FinancialSystemApproval) {
                        if (this.canCompleteVendorRequest) {
                            this.$router.push({
                                path: `/MediaVendorComplete/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.Canceled) {
                        if (this.IsRequestor(row) && (this.CanCreatProductionExpenseNewMediaRequest || this.CanCreateVendorRequest)) {
                            this.$router.push({
                                name: `Media Vendor Ledger Media`,
                                params: {
                                    id: parseInt(id),
                                },
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to edit the request`
                            );
                        }
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Request is completed or not started`
                        );
                    }
                }
                else if (requestType == "production" || requestType == "expense") {
                    if (status == VendorStates.DirectorApproval) {
                        if (this.CanApproveRejectAsDirector(row.Requestor)
                            || (row.IsDirectorApprovalReassigned == true && this.canApproveReassignedDirectorApproval)
                            || (row.IsProtegoUser == true && this.canApprovePendingDirectorVendorRequest)) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/ApprovalLevel1/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.FinanceDirectorApproval) {
                        if (this.canApprovePendingFinanceDirector) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/ApprovalLevel2/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.VendorValidation || status == VendorStates.VendorValidationRejected) {
                        if (this.canApprovePendingVendorValidation) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/VendorValidation/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.MdaManagerApproval) {
                        if (this.canApprovePendingMdaManager) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/ApprovalLevel3/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.AccountingApproval) {
                        if (this.canApprovePendingAccountingApproval) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/AccountingApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.CfoApproval) {
                        if (this.canApprovePendingCFOApproval) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/CFOApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.FinancialSystemApproval) {
                        if (this.canCompleteVendorRequest) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExp/Complete/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.Canceled) {
                        if (this.IsRequestor(row) && (this.CanCreatProductionExpenseNewMediaRequest ||this.CanCreateVendorRequest)) {
                            this.$router.push({
                                name: 'Production Expense Vendor Employee Info',
                                params: {
                                    id: parseInt(id),
                                },
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to edit the request`
                            );
                        }
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Request is completed or not started`
                        );
                    }
                }
                else if (requestType == "Amend Media") {
                    if (status == VendorStates.MrApproval) {
                        if (this.canApprovePendingMrManagerVendorRequest) {
                            this.$router.push({
                                path: `/AmendMediaVendorApproveLevel1/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    }
                    else if (status == VendorStates.VendorValidation || status == VendorStates.VendorValidationRejected) {
                        if (this.canApprovePendingVendorValidation) {
                            this.$router.push({
                                path: `/AmendMediaVendorValidation/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    }
                    else if (status == VendorStates.MdaManagerApproval) {
                        if (this.canApprovePendingMdaManager) {
                            this.$router.push({
                                path: `/AmendMediaVendorApproveLevel2/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.AccountingApproval) {
                        if (this.canApprovePendingAccountingApproval) {
                            this.$router.push({
                                path: `/AmendMediaVendorAccountingApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.CfoApproval) {
                        if (this.canApprovePendingCFOApproval) {
                            this.$router.push({
                                path: `/AmendMediaVendorCFOApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.FinancialSystemApproval) {
                        if (this.canCompleteVendorRequest) {
                            this.$router.push({
                                path: `/AmendMediaVendorComplete/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.Canceled) {
                        if (this.IsRequestor(row) && this.CanCreateAmendMedia ) {
                            this.$router.push({
                                name: `Media Vendor Amend Employee Info`,
                                params: {
                                    id: parseInt(id),
                                },
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to edit the request`
                            );
                        }
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Request is completed or not started`
                        );
                    }
                }
                else if (requestType == "Amend Production") {
                    if (status == VendorStates.DirectorApproval) {
                        if(this.CanApproveRejectAsDirector(row.Requestor)
                            || (row.IsDirectorApprovalReassigned == true && this.canApproveReassignedDirectorApproval)
                            || (row.IsProtegoUser == true && this.canApprovePendingDirectorVendorRequest)) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/ApprovalLevel1/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.FinanceDirectorApproval) {
                        if (this.canApprovePendingFinanceDirector) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/ApprovalLevel2/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.VendorValidation || status == VendorStates.VendorValidationRejected) {
                        if (this.canApprovePendingVendorValidation) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/VendorValidation/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.MdaManagerApproval) {
                        if (this.canApprovePendingMdaManager) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/ApprovalLevel3/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.AccountingApproval) {
                        if (this.canApprovePendingAccountingApproval) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/AccountingApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.CfoApproval) {
                        if (this.canApprovePendingCFOApproval) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/CFOApproval/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.FinancialSystemApproval) {
                        if (this.canCompleteVendorRequest) {
                            this.$router.push({
                                path: `/Request/Vendor/ProdExpAmend/Complete/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == VendorStates.Canceled) {
                        if (this.IsRequestor(row) && (this.CanCreatProductionExpenseNewMediaRequest||this.CanCreateVendorRequest)) {
                            this.$router.push({
                                name: 'Production Expense Amend Employee Info',
                                params: {
                                    id: parseInt(id),
                                },
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to edit the request`
                            );
                        }
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Request is completed or not started`
                        );
                    }
                }
            }
        },
        CanApproveRejectAsDirector(requestor){
            let canApprove = false;
            if(this.reachUserReportees) {
                let reportees = this.reachUserReportees.map(reportee => reportee.toLowerCase());
                if(reportees.includes(requestor.toLowerCase())) {
                    canApprove = true;
                }
            }
            return canApprove;
        },
        GetRequests() {
            this.TotalRows = 0;
            this.data.rows = [];
            this.$store
                .dispatch("mediavendor/GetRequests", this.GetGridOptions())
                .then((res) => {
                    if (res.data) {
                        this.data.rows = res.data.Result;
                        this.TotalRows = res.data.Total;
                        this.RecordsPerPageOption = UpdateRecordsPerPageOption(this.TotalRows);
                    }
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error fetching requests.${ex}`
                        );
                    }
                });
        },
        FilterQuery: function () {
            this.currentPage = 1;
            this.GetRequests();
        },

        OnChange: function (val) {
            alert(val);
        },

        select: function (val) {
            alert(val);
        },
        RestValues: function () {
            this.FilterList.VendorRequestType = [];
            this.FilterList.Agency = [];
            this.FilterList.Status = [];
            this.currentPage = 1;
            this.GetRequests();
        },
        closefilter() {
            this.theme = "row";
            this.ShowFilter = false;
        },
        openfilter() {
            (this.theme = "col-md-9"), (this.ShowFilter = true);
        },
        createNew(name) {
            this.$router.push(name);
        },
        CreateProdExpNewMedia(name) {
            if(this.CanCreatProductionExpenseNewMediaRequest|| this.CanCreateVendorRequest) {
                this.$router.push(name);
            }
            else{
                this.$router.push({
                    name: "unauthorizedProdExpCreate",
                    params: {
                        message: "UnauthorizedProdExpCreate"
                    },
                });
            }
        },
        getWindowWidth: function () {
            this.getFixed = document.documentElement.clientWidth > 992 ? true : null;
        },
        getBackgroundColor: function (status) {
            let color = "#8c8c8c";
            let emptyStatus = '';
            let emptyStatusNull = null;
            switch (status) {
                case VendorStates.DirectorApproval:
                case VendorStates.FinanceDirectorApproval:
                case VendorStates.MrApproval:
                case VendorStates.VendorValidation:
                case VendorStates.MdaManagerApproval:
                case VendorStates.AccountingApproval:
                case VendorStates.CfoApproval:
                case VendorStates.FinancialSystemApproval:
                    color = "#efa56a";
                    break;
                case VendorStates.Final:
                    color = "#2cc4ad";
                    break;
                case VendorStates.Canceled:
                case VendorStates.VendorValidationRejected:
                    color = "#df5678";
                    break;
                case emptyStatus:
                    color = "";
                    break;
                case emptyStatusNull:
                    color = "";
                    break;
                default:
                    color = "#ccc";
                    break;
            }

            return {
                color: color,
                "font-weight": 600
            };
        },
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },
    },
};
</script>