<template>
    <div class="panel-list panel-list-new">
        <div class="row review-component">
            <b-card class="col-lg-12 main-content" v-cloak>
                <div class="panel-list">
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label>EMPLOYEE/REQUEST TYPE INFO</label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col :cols="ColumnsClass">
                                <label>EMPLOYEE PHONE NUMBER</label>
                                <p v-if="!Request.EmployeePhoneNumber">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.EmployeePhoneNumber }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Entity</label>
                                <p>{{ Request.Entity }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>AGENCY</label>
                                <p>
                                    {{ Request.EmployeeAgency }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>System Type</label>
                                <p>
                                    {{ Request.VendorTypeSystem }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Vendor Media Type</label>
                                <p>
                                    {{ Request.VendorTypeMedia }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Code/Name Type</label>
                                <p>
                                    {{ Request.VendorTypeCode }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>What is changing</label>
                                <b-col cols="lg-12 px-0">
                                    <p v-if = "Request.IsRemittanceNameAddressChanged == false && Request.IsBankingInfoChanged == false">N/A</p>
                                    <p>
                                        <span v-if="Request.IsRemittanceNameAddressChanged == true">Remittance Name/Address</span>
                                        <span v-if="Request.IsRemittanceNameAddressChanged == true && Request.IsBankingInfoChanged == true">
                                            &
                                        </span>
                                        <span v-if="Request.IsBankingInfoChanged == true">Banking Info</span>
                                    </p>
                                </b-col>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Is this Request for a Media Rep?</label>
                                <p v-if="Request.IsMediaRepRequest == true">
                                    YES
                                </p>
                                <p v-if="Request.IsMediaRepRequest == false">
                                    NO
                                </p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label>SETUP INFO</label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col :cols="ColumnsClass">
                                <label>VENDOR CODE</label>
                                <p>
                                    {{ Request.VendorCode }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>VENDOR Business Name</label>
                                <p>
                                    {{ Request.VendorBusinessName }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.InvoiceAttachment.length > 0">
                                <label>INVOICE ATTACHMENT</label>
                                <upload-file-holder :Files="Request.InvoiceAttachment"></upload-file-holder>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.VendorRemittanceName">
                                <label>VENDOR REMITTANCE NAME</label>
                                <p>
                                    {{ Request.VendorRemittanceName }}
                                </p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceCountry">
                                <label>COUNTRY</label>
                                <p>{{ Request.RemittanceCountry }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceCountry">
                                <label>STREET ADDRESS LINE 1</label>
                                <p>{{ Request.StreetAddressLine1 }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceCity">
                                <label>CITY</label>
                                <p>{{ Request.RemittanceCity }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceState">
                                <label>STATE</label>
                                <p>{{ Request.RemittanceState }}</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="this.Request.RemittanceZipCode">
                                <label>ZIP CODE</label>
                                <p>{{ Request.RemittanceZipCode }}</p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>ACCOUNTING CONTACT NAME</label>
                                <p>
                                    {{ Request.AccountingContactName }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>ACCOUNTING CONTACT TITLE</label>
                            <p>
                                {{ Request.AccountingContactTitle }}
                            </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>ACCOUNTING PHONE #</label>
                                <p>
                                    {{ Request.AccountingPhone }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>ACCOUNTING EMAIL ADDRESS</label>
                                <p>
                                    {{ Request.AccountingEmailAddress }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>How was this information obtained?</label>
                                <p>
                                    <span v-if="Request.InfoFromSourceIsVerbal">Verbal</span>
                                    <span
                                        v-if="Request.InfoFromSourceIsVerbal && Request.InfoFromSourceIsWritten">
                                        &
                                    </span>
                                    <span v-if="Request.InfoFromSourceIsWritten">Written</span>
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.InfoFromSourceVerbal">
                                <label>Verbal</label>
                                <p>
                                    {{ Request.InfoFromSourceVerbal }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.InfoFromSourceWritten">
                                <label>Written</label>
                                <p>
                                    {{ Request.InfoFromSourceWritten }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.InfoFromSourceWritten">
                                <label>Written Communication File</label>
                                <upload-file-holder :Files="Request.SetupInfoSource"></upload-file-holder>
                            </b-col>
                        </b-row>
                            <b-row class="panel-content">
                                <b-col cols="lg-12">
                                    <label>Who at the vendor provided this information?</label>
                                </b-col>
                                <b-col :cols="ColumnsClass">
                                    <label>Name</label>
                                    <p>
                                        {{ Request.InfoFromName }}
                                    </p>
                                </b-col>
                                <b-col :cols="ColumnsClass">
                                    <label>Title</label>
                                    <p>
                                        {{ Request.InfoFromTitle }}
                                    </p>
                                </b-col>
                                <b-col :cols="ColumnsClass">
                                    <label>Telephone Number</label>
                                    <p>
                                        {{ Request.InfoFromPhone }}
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row class="panel-content">
                            <b-col :cols="ColumnsClass">
                                <label>Has there been a remittance name change for this vendor or a change in ownership?</label>
                                <p v-if="Request.IdentificationOption == true">
                                Yes
                                </p>
                                <p v-if="Request.IdentificationOption == false">
                                No
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>Identification Type</label>
                                <p v-if="!Request.IdentificationType">
                                   N/A
                                </p>
                                <p v-else>
                                    {{ formatIdentityTypeLabel(Request.IdentificationType) }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IdentificationType">
                                <label>Identification Number</label>
                                <p>
                                    {{ formatIdentityType(Request[Request.IdentificationType])
                                    }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IdentificationType">
                                <label>{{ AttachmentLabel }}</label>
                                <p v-if="(!Request.IdentificationFiles || Request.IdentificationFiles.length == 0 )">
                                    N/A
                                </p>
                                <div v-else>
                                    <upload-file-holder :Files="Request.IdentificationFiles"></upload-file-holder>
                                </div>
                            </b-col>
                            <b-col :cols="ColumnsClass">
                                <label>DUN & BRADSTREET NUMBER</label>
                                <p v-if="!Request.DunAndBradstreetNumber">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.DunAndBradstreetNumber }}
                                </p>
                            </b-col>
                            </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label>Credit Card/Banking Info</label>
                        </b-row>
                        <b-row class="panel-content">
                            <b-col :cols="ColumnsClass">
                                <label>Did the vendor specify that they do, in fact accept credit cards on the form the vendor completed?</label>
                                <p v-if="Request.IsCreditCardAccepted == true">
                                    YES
                                </p>
                                <p v-if="Request.IsCreditCardAccepted == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IsCreditCardAccepted == false">
                                <label>Would the vendor like to be set up by ACH Payment?</label>
                                <p v-if="Request.IsACHPayment == true">
                                    YES
                                </p>
                                <p v-if="Request.IsACHPayment == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IsACHPayment == true">
                                <label>Is Banking Info Collected</label>
                                <p v-if="Request.IsBankingInfoCollected == true">YES</p>
                                <p v-if="Request.IsBankingInfoCollected == false">NO</p>
                                <p v-if="!Request.IsBankingInfoCollected">N/A</p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.CreditCardFiles.length > 0">
                                <label>Attachment(s)</label>
                                <upload-file-holder :Files="Request.CreditCardFiles"></upload-file-holder>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.IsCreditCardAccepted == true">
                                <label>Are there fees or conditions/limitations in accepting payment via credit card for this organization? An example would be that the vendor charges a 3% fee on all transactions, or that the companies credit card information must be held on file with the vendor for immediate payment processing.</label>
                                <p v-if="Request.AreThereConditionsOrLimitations == true">
                                    YES
                                </p>
                                <p v-if="Request.AreThereConditionsOrLimitations == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AreThereConditionsOrLimitations == true">
                                <label>What the Limitations Are</label>
                                <p v-if="!Request.CreditLimitation">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.CreditLimitation }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AreThereConditionsOrLimitations == true">
                                <label>Did the vendor indicate that they would like to be setup for ACH payments?</label>
                                <p v-if="Request.HasACHPaymentSetup == true">
                                    YES
                                </p>
                                <p v-if="Request.HasACHPaymentSetup == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.HasACHPaymentSetup == true">
                                <label>Did the vendor provide a bank letter, invoice or voided check confirming their account information?</label>
                                <p v-if="Request.HasBankLetterInvoice == true">
                                    YES
                                </p>
                                <p v-if="Request.HasBankLetterInvoice == false">
                                    NO
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankName">
                                <label>BANK NAME</label>
                                <p v-if="!Request.BankName">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.BankName }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankName">
                                <label>BANK ADDRESS LINE 1</label>
                                <p v-if="!Request.BankAddressLine1">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.BankAddressLine1 }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankName">
                                <label>BANK ADDRESS LINE 2</label>
                                <p v-if="!Request.BankAddressLine2">
                                    N/A
                                </p>
                                <p>
                                    {{ Request.BankAddressLine2 }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankCountry">
                                <label>COUNTRY</label>
                                <p>
                                    {{ Request.BankCountry }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankState">
                                <label>STATE</label>
                                <p>
                                    {{ Request.BankState }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankCity">
                                <label>CITY</label>
                                <p>
                                    {{ Request.BankCity }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankZipCode">
                                <label>ZIP CODE</label>
                                <p>
                                    {{ Request.BankZipCode }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AccountName">
                                <label>ACCOUNT NAME</label>
                                <p>
                                    {{ Request.AccountName }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.AccountNumber">
                                <label>ACCOUNT NUMBER</label>
                                <p>
                                    {{ Request.AccountNumber }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankBIC">
                                <label>Sort/Swift (Non US)</label>

                                <p>
                                    {{ Request.BankBIC }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankIBAN">
                                <label>IBAN (Non US)</label>

                                <p>
                                    {{ Request.BankIBAN }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.RoutingNumber">
                                <label>ROUTING NUMBER (US ONLY)</label>
                                <p>
                                    {{ Request.RoutingNumber }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.RemittanceAdviceEmail">
                                <label>REMITTANCE ADVICE EMAIL</label>
                                <p>
                                    {{ Request.RemittanceAdviceEmail }}
                                </p>
                            </b-col>
                            <b-col :cols="ColumnsClass" v-if="Request.BankLetter.length > 0">
                                <label>BANK LETTER OR INVOICE</label>
                                <upload-file-holder :Files="Request.BankLetter"></upload-file-holder>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body">
                        <b-row class="panel-title">
                            <label>EMPLOYEE RELATIONSHIP INFO </label>
                        </b-row>
                        <b-row class="panel-content">
                            <template v-if="!IsNoneSelected">
                                <b-col :cols="ColumnsClass" v-for="item in Request.EmployeeVendorRelationship"
                                    :key="item.RelationshipName">
                                    <label>{{ GetRelationshipLabel(item.RelationshipName) }}</label>
                                    <div>
                                        <p>{{ Request.EmployeeVendorRelationshipDetails[item.RelationshipName] }}</p>
                                    </div>
                                </b-col>
                            </template>
                            <b-col cols="lg-4" v-if="IsNoneSelected">
                                <label>Employee Relationship With Vendor</label>
                                <p>None</p>
                            </b-col>
                            <b-col cols="lg-4">
                                <label>Has any form of compensation or gifts of any type been offered to you or any person
                                    related to you in exchange for placing an order with this vendor?</label>
                                <p v-if="Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true">
                                    YES</p>
                                <p v-if="Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == false">
                                    NO</p>
                            </b-col>
                            <b-col cols="lg-4" v-if="Request.CompensationReceived > 0">
                                <label>Compensation Received</label>
                                <p>${{ Request.CompensationReceived }}</p>
                            </b-col>
                        </b-row>
                        <b-row class="panel-content" v-if="Request.CompletedCode">
                            <b-col cols="lg-4">
                                <label>Type of Amendment / Comment</label>
                                <p>{{ Request.CompletedCode }}</p>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="panel-body" v-if="VendorValidationFiles.length > 0">
                        <b-row class="panel-title">
                            <label class="col-6">Vendor Validation Attachment</label>
                        </b-row>
                        <b-row class="panel-content row">
                            <b-col :cols="ColumnsClass" v-if="JpmAttachmentFiles.length > 0">
                                <label>JPM Validation Documents</label>
                                <upload-file-holder :Files="JpmAttachmentFiles"></upload-file-holder>
                             </b-col>
                             <b-col :cols="ColumnsClass" v-if="VendorValidationAttachmentFiles.length > 0">
                                <label>Vendor Validation Documents</label>
                                <upload-file-holder :Files="VendorValidationAttachmentFiles"></upload-file-holder>
                             </b-col>
                        </b-row>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<style scope >
@media screen and (min-width: 1200px) {
    .icreview-component {
        padding: 1rem 3rem 0px !important;
        height: 600px !important;
    }
}

.file-ref {
    color: #00a1d2 !important;
}

.Agency-Name-HFM-Co {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.73px;
    color: #4d5161;
}

.approval-side-section {
    background-color: rgba(162, 169, 173, 0.05);
    margin-bottom: 2px;
    padding-right: 35px !important;
    -webkit-box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
    border: solid 1px rgba(239, 242, 244, 0.2);
    border-left: none;
    border-radius: 0px 8px 8px 0px;
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.file-ref {
    color: #00a1d2 !important;
}
.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #a2a9ad !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div>*:not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.request-status {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    height: 135px;
    border-radius: 5px;
}

.status-bar {
    line-height: 2;
    color: #d5d7e0;
}

.request-status .status {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .date {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.request-status .updatedby {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .comments {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #00a1d2;
}
</style>

<script>
import UploadFileHolder from '../../Common/UploadFileHolder';
export default {
    components: {
        'UploadFileHolder': UploadFileHolder
    },
    data() {
        return {
            Error: false,
            Comments: "",
            id: null,

            RelationshipVendorsLabel: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend's Name",
                value: "FriendName"
            },
            {
                "label": "Explain the nature of the relationship",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former Employee’s Name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        };
    },
    name: "Overview",
    props: {
        Request: {
            type: Object,
            required: false,
            default: () => ({})
        },
        VendorValidationFiles: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        DownloadableFiles: {
            type: Boolean,
            required: false,
            default: true,
        },
        ID: {
            type: Number,
            required: false,
            default: 0,
        },
        Columns: {
            type: Number,
            default: 4,
            required: false,
        }
    },
    computed: {
        IsNoneSelected() {
            return this.Request.EmployeeVendorRelationshipDetails == null;
        },
        AttachmentLabel() {
            return this.Request.IdentificationType == 'SocialSecurityNumber' || this.Request.IdentificationType == 'USTaxId' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        ColumnsClass() {
            return `lg-${this.Columns}`;
        },
        IdentificationFiles() {
            let files = [];
            if (this.Request.Files && this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "IdentificationFiles");
            }
            return files;
        },
        SetupInfoSource() {
            let files = [];
            if (this.Request.Files && this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "SetupInfoSource");
            }
            return files;
        },
        BankLetter() {
            let files = [];
            if (this.Request.Files && this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "BankLetter");
            }
            return files;
        },
        InvoiceAttachment() {
            let files = [];
            if (this.Request.Files && this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "InvoiceAttachment");
            }
            return files;
        },
        CreditCardFiles() {
            let files = [];
            if (this.Request.Files && this.Request.Files.length > 0) {
                return this.Request.Files.filter(file => file.Category == "CreditCardFiles");
            }
            return files;
        },
        JpmAttachmentFiles() {
            let files = [];
            if (this.VendorValidationFiles.length > 0) {
                return this.VendorValidationFiles.filter(file => file.Category == "JpmAttachmentFiles");
            }
            return files;
        },
        VendorValidationAttachmentFiles() {
            let files = [];
            if (this.VendorValidationFiles.length > 0) {
                return this.VendorValidationFiles.filter(file => file.Category == "AttachmentFiles");
            }
            return files;
        }
    },
    methods: {
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.Request.IdentificationType == "USTaxId") {
                    return "US Tax ID";
                } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        formatIdentityType(val) {
            if (val) {
                if (this.Request.IdentificationType == "USTaxId" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return "N/A";
            }
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendorsLabel.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendorsLabel[relationsshipIndex].label;
            } else {
                return "";
            }
        },
    },
};
</script>