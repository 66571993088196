<template>
    <div class="row child-component">
        <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">

            <div class="d-block row col-lg-8 mx-auto">
                <div class="fieldborder">
                    <OmniSelect v-model="ProdEXPAmend.VendorCodeOptions" :options="VendorCodeOptions" name="Vendor Code"
                        :placeholder="'Select Vendor Code'" @blur="SetDirty('VendorCodeOptions')"
                        @focus="ResetDirty('VendorCodeOptions')" />
                    <div class="error-message-format"
                        v-if="$v.ProdEXPAmend.VendorCodeOptions.$dirty && !$v.ProdEXPAmend.VendorCodeOptions.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            Vendor Business Name
                        </span>
                        <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            v-b-tooltip.hover.v-dark.right.html="' This should be the media supplier name that we are placing the order with. Ie: website name, publication or station call letters'
                                ">
                            <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path
                                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </slot>
                    <b-form-input @blur="SetDirty('VendorBusinessName')" @focus="ResetDirty('VendorBusinessName')"
                        autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="26"
                        v-model="ProdEXPAmend.VendorBusinessName" placeholder="Enter Vendor Business Name" disabled></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.ProdEXPAmend.VendorBusinessName.$dirty && !$v.ProdEXPAmend.VendorBusinessName.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder mb-n4">
                    <b-form-group>
                        <label>Invoice Attachment</label>
                        <vue-dropzone v-on:vdropzone-mounted="mountedInvoiceAttachmentDropzone" :duplicateCheck="true"
                            ref="myVueDropzoneInvoiceAttachment" id="myVueDropzoneInvoiceAttachment" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="UploadedtoInvoiceAttachment"
                            v-on:vdropzone-removed-file="RemovedFromInvoiceAttachment">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="margin-top: -7px !important;font-family: 'Open Sans';"/>
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>
                                </p>
                            </div>
                        </vue-dropzone>
                    </b-form-group>
                </div>
                <div v-if="ProdEXPAmend.ChangeType == 'Address' || ProdEXPAmend.ChangeType == 'Both'">
                    <div class="fieldborder pt-2">
                        <label>COUNTRY</label>
                        <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Country"
                            name="RemittanceCountry" :showLabel="false" v-model="ProdEXPAmend.RemittanceCountry" :options="CountryList">
                        </OmniSelect>
                    </div>
                    <div class="fieldborder pt-2">
                        <label>STREET ADDRESS LINE 1 </label>
                        <b-form-input @focus="ResetDirty('StreetAddressLine1')" @blur="SetDirty('StreetAddressLine1')"
                            autocomplete="off" id="StreetAddressLine1" class="omniipclass"
                            v-model="ProdEXPAmend.StreetAddressLine1" placeholder="Enter Street Address Line1"
                            :maxlength="26"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.StreetAddressLine1.$dirty && !$v.ProdEXPAmend.StreetAddressLine1.required">
                            Required field
                        </div>
                    </div>
                    <div class="fieldborder pt-2" v-if="IsCountryUSA">
                        <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                        <b-form-input @focus="ResetDirty('RemittanceCity')" @blur="SetDirty('RemittanceCity')" autocomplete="off"
                            id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="ProdEXPAmend.RemittanceCity"
                            placeholder="Enter City" :maxlength="19"></b-form-input>
                        <div class="error-message-format"
                            v-if="$v.ProdEXPAmend.RemittanceCity.$dirty && !$v.ProdEXPAmend.RemittanceCity.required">
                            Required field
                        </div>
                    </div>
                    <div class="row fieldborderomniipclass pt-2" v-if="IsCountryUSA">
                        <div class="col-md-8">
                            <label v-if="IsCountryUSA" for="RemittanceState" class="lbltxt">State</label>
                            <OmniSelect  :showLabel="false" class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State" name="RemittanceState" :showSearch="true" v-model="ProdEXPAmend.RemittanceState" :options="StateList">
                            </OmniSelect>
                        </div>
                        <div class="col-md-4">
                            <label v-if="IsCountryUSA" for="RemittanceZipCode" class="lbltxt">ZIP CODE</label>
                            <b-form-input autocomplete="off" id="RemittanceZipCode" class="" :minlength="5" v-if="IsCountryUSA" :maxlength="5" v-bind:class="{
                                    redtextbox: (validationtextbox || !$v.ProdEXPAmend.RemittanceZipCode.numeric) && $v.ProdEXPAmend.RemittanceZipCode.$dirty,
                                    notredtextbox: !validationtextbox && $v.ProdEXPAmend.RemittanceZipCode.numeric,
                                }" @blur="SetDirty('RemittanceZipCode')" @focus="ResetDirty('RemittanceZipCode')"
                                @keyup="ValidateZipCode()" v-model="ProdEXPAmend.RemittanceZipCode"
                                placeholder="Enter Zip Code"></b-form-input>
                            <div v-show="validationtextbox && $v.ProdEXPAmend.RemittanceZipCode.$dirty" class="error-message-format">
                                Input must be 5 characters
                            </div>
                            <div v-show="!$v.ProdEXPAmend.RemittanceZipCode.numeric && $v.ProdEXPAmend.RemittanceZipCode.$dirty"
                                style="width:135px;" class="error-message-format">
                                This field can only contain numerical values
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            ACCOUNTING CONTACT NAME
                        </span>
                    </slot>
                    <b-form-input @blur="SetDirty('AccountingContactName')" @focus="ResetDirty('AccountingContactName')"
                        autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="20"
                        v-model="ProdEXPAmend.AccountingContactName"
                        placeholder="Enter Accounting Contact Name"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.ProdEXPAmend.AccountingContactName.$dirty && !$v.ProdEXPAmend.AccountingContactName.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            ACCOUNTING TITLE
                        </span>
                    </slot>
                    <b-form-input @blur="SetDirty('AccountingTitle')" @focus="ResetDirty('AccountingTitle')"
                        autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="20"
                        v-model="ProdEXPAmend.AccountingTitle" placeholder="Enter Accounting Title"></b-form-input>
                    <div class="error-message-format"
                        v-if="$v.ProdEXPAmend.AccountingTitle.$dirty && !$v.ProdEXPAmend.AccountingTitle.required">
                        Required field
                    </div>
                </div>
                <div class="fieldborder pt-2">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: 11px">
                            ACCOUNTING PHONE #
                        </span>
                    </slot>
                    <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', FormatAccountPhoneNumber]"
                        style="margin-top:1px;" class="form-control" @blur="SetDirty(ProdEXPAmend.AccountingPhone)"
                        @focus="ResetDirty(ProdEXPAmend.AccountingPhone)" autocomplete="off" id="AccountingPhone"
                        :minLength="10" :maxlength="24" v-model="ProdEXPAmend.AccountingPhone"
                        placeholder="Enter Accounting Phone #"></the-mask>
                </div>
                <div class="fieldborder pt-2">
                    <b-form-group>
                        <label>
                            Accounting Email Address
                        </label>
                        <b-form-input type="email" @focus="ResetDirty('AccountingEmailAddress')"
                            @blur="SetDirty('AccountingEmailAddress')" autocomplete="off"
                            placeholder="Enter Accounting Email Address" v-model="ProdEXPAmend.AccountingEmailAddress"
                            ref="email"></b-form-input>
                        <div class="error-message-format" v-if="$v.ProdEXPAmend.AccountingEmailAddress.$dirty">
                            <span v-show="!$v.ProdEXPAmend.AccountingEmailAddress.required">
                                Required field
                            </span>
                            <span v-show="!$v.ProdEXPAmend.AccountingEmailAddress.email">
                                Please enter a valid email address (eg. test@finsys.com)
                            </span>

                        </div>
                    </b-form-group>
                </div>

                <div class="fieldborder" style="margin-top:10px">
                    <label>Who at the vendor provided this information? </label>
                    <br />
                    <label>Name </label>
                    <b-form-input autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="36"
                        v-model="ProdEXPAmend.InfoFromName" placeholder="Name"></b-form-input>
                    <label>Title </label>
                    <b-form-input autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="36"
                        v-model="ProdEXPAmend.InfoFromTitle" placeholder="Title"></b-form-input>
                    <label>Phone Number </label>

                    <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', FormatPhoneNumber]"
                        style="margin-top:1px;" class="form-control" @blur="SetDirty(ProdEXPAmend.InfoFromPhone)"
                        @focus="ResetDirty(ProdEXPAmend.InfoFromPhone)" autocomplete="off" id="InfoFromPhone"
                        :minLength="10" :maxlength="24" v-model="ProdEXPAmend.InfoFromPhone"
                        placeholder="Phone Number"></the-mask>

                </div>

                <b-form-group style="margin-top:15px" label="How was this information obtained?" class="mb-n3 timeonly-text">
                    <div class="form-group" style="margin-left: 14px">
                        <div class="text-left" style="margin-top: 11px">
                            <b-form-checkbox v-model="ProdEXPAmend.InfoFromSourceIsVerbal" value="true" label="dsad" inline>
                                Verbal
                            </b-form-checkbox>
                        </div>
                    </div>

                    <div class="form-group" style="margin-left: 14px">
                        <div class="text-left" style="margin-top: 11px">
                            <b-form-checkbox v-model="ProdEXPAmend.InfoFromSourceIsWritten" value="true" inline>
                                Written
                            </b-form-checkbox>
                        </div>
                    </div>
                </b-form-group>

                <div class="fieldborder mt-2">
                    <b-form-group class="mb-1" v-if="InfoFromSourceDisplayVerbal">
                        <div>
                            <label>Verbal </label>
                            <OmniSelect placeholder="Select Verbal" name="Media" :showLabel="false"
                                :options="InfoFromSourceVerbalOptions" label="Select Verbal"
                                v-model="ProdEXPAmend.InfoFromSourceVerbal" />
                        </div>
                    </b-form-group>
                </div>

                <div class="fieldborder" v-if="InfoFromSourceDisplayWritten">
                    <b-form-group>
                        <div>
                            <label>Written</label>
                            <OmniSelect placeholder="Select Written" name="Written" :showLabel="false"
                                :options="InfoFromSourceWrittenOptions" label="Select Written"
                                v-model="ProdEXPAmend.InfoFromSourceWritten" />
                        </div>

                        <label class="mt-3">Attach Written</label>
                        <vue-dropzone v-on:vdropzone-mounted="mountedWrittenDropzone" :duplicateCheck="true"
                            ref="DropzoneWrittenSource" id="DropzoneWrittenSource" :useCustomSlot="true"
                            class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                            v-on:vdropzone-file-added="UploadedtoDropzoneSource"
                            v-on:vdropzone-removed-file="RemovedFromDropzoneSource">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="
                                        margin-top: -7px !important;
                                        font-family: 'Open Sans';
                                        " />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>
                                </p>
                            </div>
                        </vue-dropzone>
                    </b-form-group>
                </div>

                <div class="fieldborder">
                    <b-form-group label="" class="currentW9Attachment-text">
                        <label>Did the vendor provide a current W9?</label>
                        <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="CurrentW9Attachment"
                            v-model="ProdEXPAmend.CurrentW9Attachment" name="CurrentW9Attachment">
                            <b-form-radio value="Yes">Yes</b-form-radio>
                            <b-form-radio value="No">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <span v-if="ProdEXPAmend.CurrentW9Attachment == 'Yes'">
                <div class="fieldborder row">
                    <div class="col-md-6">
                        <div>
                            <label>IDENTIFICATION NUMBER </label>
                            <OmniSelect placeholder="Select Identification Code" name="Media" :showLabel="false"
                                :options="Identificationnumberslist" label="IDENTIFICATION NUMBER"
                                v-model="ProdEXPAmend.IdentificationType" @input="IDNumberchange" />
                        </div>

                    </div>
                    <div class="col-md-6" v-show="ProdEXPAmend.IdentificationType">
                        <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '##-########']"
                            style="margin-top:1.5rem;" class="form-control" v-if="IsUSTaxIDSelected"
                            @focus="ResetDirty(ProdEXPAmend.IdentificationType)"
                            @blur="SetDirty(ProdEXPAmend.IdentificationType)" autocomplete="off" id="USTaxID"
                            v-model="ProdEXPAmend[ProdEXPAmend.IdentificationType]"
                            :placeholder="'Enter US Tax ID'"></the-mask>
                        <!-- <b-form-input style="margin-top:1.5rem;" v-if="IsUSTaxIDSelected" @focus="ResetDirty(ProdEXPAmend.IdentificationType)" @blur="SetDirty(ProdEXPAmend.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(ProdEXPAmend.IdentificationType)" v-model="ProdEXPAmend[ProdEXPAmend.IdentificationType]" v-numericOnly :placeholder="'Enter '+ProdEXPAmend.IdentificationType"></b-form-input> -->
                        <!-- <b-form-input style="margin-top:1.5rem;" v-else-if="IsSocialSecurityNumberSelected" @focus="ResetDirty(ProdEXPAmend.IdentificationType)" @blur="SetDirty(ProdEXPAmend.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(ProdEXPAmend.IdentificationType)" v-model="ProdEXPAmend[ProdEXPAmend.IdentificationType]" v-numericOnly :placeholder="'Enter '+ProdEXPAmend.IdentificationType"></b-form-input> -->
                        <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['###', '###-##', '###-##-####']"
                            v-else-if="IsSocialSecurityNumberSelected" style="margin-top:1.5rem;" class="form-control"
                            @focus="ResetDirty(ProdEXPAmend.IdentificationType)"
                            @blur="SetDirty(ProdEXPAmend.IdentificationType)" autocomplete="off" id="socialsecurity"
                            :maxlength="11" v-model="ProdEXPAmend[ProdEXPAmend.IdentificationType]"
                            :placeholder="'Enter Social Security Number'"></the-mask>

                        <b-form-input style="margin-top:1.5rem;" v-else @focus="ResetDirty(ProdEXPAmend.IdentificationType)"
                            @blur="SetDirty(ProdEXPAmend.IdentificationType)" autocomplete="off" id="Foreign"
                            class="omniipclass" v-model="ProdEXPAmend[ProdEXPAmend.IdentificationType]"
                            :placeholder="'Enter ' + ProdEXPAmend.IdentificationType"></b-form-input>


                        <div class="error-message-format"
                            v-if="ProdEXPAmend.IdentificationType && $v.ProdEXPAmend[ProdEXPAmend.IdentificationType].$dirty">
                            <span v-show="!$v.ProdEXPAmend[ProdEXPAmend.IdentificationType].required">
                                Required field
                            </span>
                        </div>
                    </div>
                </div>

                <div class="fieldborder" v-show="ProdEXPAmend.IdentificationType">
                    <label for="axlocatoin" class="lbltxt">{{ AttachmentLabel }}
                        <span>
                            <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9"
                                version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                v-b-tooltip.hover.v-dark.right.html="' Attachment of a completed W-9 form or Foreign W-8 form signed and dated within the last year, required for any change in ownership'
                                    ">
                                <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                                        id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                                </g>
                            </svg>
                        </span></label>
                    <vue-dropzone v-on:vdropzone-mounted="mountedIdentitificationDropzone" :duplicateCheck="true"
                        ref="identificationDropzone" id="identificationDropzone" :useCustomSlot="true"
                        class="customdropzone" :include-styling="false" :options="dropzoneOptions"
                        v-on:vdropzone-file-added="UploadtoIdentification"
                        v-on:vdropzone-removed-file="RemovedFromIdentification">
                        <div class="dropzone-custom-content">
                            <p class="Drag-and-drop-file-h">
                                <b-img style="
                                margin-top: -7px !important;
                                font-family: 'Open Sans';" />
                                Drag and drop file here or
                                <span class="draganddropspan"> browse </span>

                            </p>
                        </div>
                    </vue-dropzone>
                </div>

                </span>
            </div>
        </div>
        <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
            <button class="btn btn-link text-uppercase" @click="Cancel()">
                CANCEL
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
                PREVIOUS
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="Next()"
                :disabled="this.$v.$invalid || !IsVerbalOrWrittenChecked">
                NEXT
            </button>
            <!-- <button  @click="PrintValidation()" class="btn custom-button btn-primary">
            Validation
        </button> -->
        </div>

    </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapGetters } from "vuex";
import { TheMask } from 'vue-the-mask'
import { required, requiredIf, numeric, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import service from "../../../services/Dropdowns";

const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)

export default {
    components: {
        vueDropzone: vue2Dropzone,
        TheMask
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            VendorCodeOptions:[],
            WrittenAttachmentFile: 0,
            IsIdentificationFilesLoadedInEdit: false,
            IsWrittenFilesLoadedInEdit: false,
            IsInvoiceAttachmentLoadedInEdit: false,
            Identificationnumberslist: [{
                label: "US TAX ID",
                value: "USTaxID"
            },
            {
                label: "Foreign",
                value: "Foreign"
            },
            {
                label: "Social Security",
                value: "SocialSecurityNumber"
            },
            ],
            InfoFromSourceVerbalOptions: [
                { label: "In Person", value: "In Person" },
                { label: "Phone", value: "Phone" }
            ],
            InfoFromSourceWrittenOptions: [
                { label: "Teams Chat", value: "Teams Chat" },
                { label: "Email", value: "Email" }
            ],
            InvoicesImmediatePaymentOptions: [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" }
            ],
            dropzoneOptions: {
                paramName: "file",
                //params: {'param_1':'xyz','para_2': localid },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/mdm/Upload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 5!");
                        }
                    });

                    this.on("removedfile", function () { });
                },
            },
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../../assets/Icons/file.png"),
            CountryList: [],
            StateList: [],
            CountryUnitedStatesValue: "USA - United States",
        }
    },
    validations: {
        ProdEXPAmend: {
            VendorCodeOptions: {
                required: required,
            },
            VendorBusinessName: {
                required: required,
            },
            AccountingContactName: {
                required: required,
            },
            AccountingTitle: {
                required: required,
            },
            AccountingPhone: {
                required: required,
                minLength: minLength(10)
            },
            AccountingEmailAddress: {
                required: required,
                email: EmailWithoutCase
            },
            InfoFromName: {
                required: required,
            },
            InfoFromTitle: {
                required: required,
            },
            InfoFromPhone: {
                required: required,
                minLength: minLength(10)
            },
            CurrentW9Attachment: {
                required: required,
            },
            IdentificationType: {
                required: requiredIf(function () {
                    return this.ProdEXPAmend.CurrentW9Attachment == 'Yes';
                }),
            },
            USTaxID: {
                required: requiredIf(function (model) {
                    return this.ProdEXPAmend.CurrentW9Attachment == 'Yes' && model.IdentificationType == "USTaxID";
                }),
                numeric,
                minLength: minLength(9)
            },
            SocialSecurityNumber: {
                required: requiredIf(function (model) {
                    return this.ProdEXPAmend.CurrentW9Attachment == 'Yes' && model.IdentificationType == "SocialSecurityNumber" ;
                }),
                numeric,
                minLength: minLength(9),
                maxLength: maxLength(9)
            },
            Foreign: {
                required: false,
            },
            IdentificationFiles: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return this.ProdEXPAmend.CurrentW9Attachment == 'Yes' && (model.IdentificationType == "USTaxID" || model.IdentificationType == "SocialSecurityNumber" || model.IdentificationType == "Foreign");
                })
            },
            InfoFromSourceIsVerbal: {
                required: requiredIf(function () {
                    return !this.ProdEXPAmend.InfoFromSourceIsWritten;
                }),
            },
            InfoFromSourceIsWritten: {
                required: requiredIf(function () {
                    return !this.ProdEXPAmend.InfoFromSourceIsVerbal;
                }),
            },
            InfoFromSourceVerbal: {
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsVerbal == true || model.InfoFromSourceIsVerbal == "true";
                }),
            },
            InfoFromSourceWritten: {
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsWritten == true || model.InfoFromSourceIsWritten == "true";
                }),
            },
            SetupInfoSource: {
                minLength: minLength(1),
                required: requiredIf(function (model) {
                    return model.InfoFromSourceIsWritten == "true" || model.InfoFromSourceIsWritten == true;
                })
            },
            RemittanceCountry: {
                required: requiredIf(function () {
                    return this.ProdEXPAmend.ChangeType == 'Address' || this.ProdEXPAmend.ChangeType == 'Both';
                }),
            },
            StreetAddressLine1: {
                required: requiredIf(function () {
                    return this.ProdEXPAmend.ChangeType == 'Address' || this.ProdEXPAmend.ChangeType == 'Both';
                }),
            },
            RemittanceCity: {
                required: requiredIf(function (model) {
                    return (this.ProdEXPAmend.ChangeType == 'Address' || this.ProdEXPAmend.ChangeType == 'Both') && model.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
            },
            RemittanceState: {
                required: requiredIf(function (model) {
                    return (this.ProdEXPAmend.ChangeType == 'Address' || this.ProdEXPAmend.ChangeType == 'Both') && model.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
            },
            RemittanceZipCode: {
                required: requiredIf(function (model) {
                    return (this.ProdEXPAmend.ChangeType == 'Address' || this.ProdEXPAmend.ChangeType == 'Both') && model.RemittanceCountry == this.CountryUnitedStatesValue;
                }),
                numeric,
            },
            InvoiceAttachment: {
                minLength: minLength(1),
                required: requiredIf(function () {
                    return this.ProdEXPAmend.ChangeType != null;
                }),
            },
        }
    },
    computed: {
        FormatPhoneNumber() {
            const infoFromPhone = this.ProdEXPAmend.InfoFromPhone || '';
            return infoFromPhone.length > 10 ? '####################' : '1-###-###-#####';
        },
        FormatAccountPhoneNumber() {
            const accountingPhone = this.ProdEXPAmend.AccountingPhone || '';
            return accountingPhone.length > 10 ? '####################' : '1-###-###-#####';
        },
        IsVerbalOrWrittenChecked() {
            return this.InfoFromSourceDisplayWritten || this.InfoFromSourceDisplayVerbal;
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        ...mapGetters('prodexpamend', {
            ProdEXPAmend: 'ProdEXPAmend',
        }),
        IsUSTaxIDSelected() {
            return this.ProdEXPAmend.IdentificationType == 'USTaxID';
        },
        IsSocialSecurityNumberSelected() {
            return this.ProdEXPAmend.IdentificationType == 'SocialSecurityNumber';
        },
        AttachmentLabel() {
            return this.ProdEXPAmend.IdentificationType == 'SocialSecurityNumber' || this.ProdEXPAmend.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        InfoFromSourceDisplayVerbal() {
            return this.ProdEXPAmend.InfoFromSourceIsVerbal;
        },
        InfoFromSourceDisplayWritten() {
            return this.ProdEXPAmend.InfoFromSourceIsWritten;
        },
        IsCountryUSA() {
            return this.ProdEXPAmend.RemittanceCountry != null ? this.ProdEXPAmend.RemittanceCountry == this.CountryUnitedStatesValue : null;
        },
    },
    mounted() {
        this.LoadDropdownValues();
        this.GetVendorCodeData();
    },
    watch: {
        "ProdEXPAmend.AccountingPhone": function() {
            if (this.ProdEXPAmend.AccountingPhone.length > 10) {
                this.FormatAccountPhoneNumber = true;
            }
            else {
                this.FormatAccountPhoneNumber = false;
            }
        },
        "ProdEXPAmend.InfoFromPhone": function() {
            if (this.ProdEXPAmend.InfoFromPhone.length > 10) {
                this.FormatPhoneNumber = true;
            }
            else {
                this.FormatPhoneNumber = false;
            }
        },
        "ProdEXPAmend.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {

                if (this.IsIdentificationFilesLoadedInEdit == false) {
                    this.ManuallyUploadFilesIdentification(this.ProdEXPAmend.IdentificationFiles);
                    this.$set(this.ProdEXPAmend, "IdentificationFiles", this.GetIDFilesObject());
                }

                if (this.IsWrittenFilesLoadedInEdit == false) {
                    this.ManuallyUploadFilesWritten(this.ProdEXPAmend.SetupInfoSource);
                    this.$set(this.ProdEXPAmend, "SetupInfoSource", this.GetFilesObjectSource());
                }

                if (this.IsInvoiceAttachmentLoadedInEdit == false) {
                    this.ManuallyUploadInvoiceAttachment(this.ProdEXPAmend.InvoiceAttachment);
                    this.$set(this.ProdEXPAmend, "InvoiceAttachment", this.GetFilesObjectInvoiceAttachment());
                }
            }
        },
        "ProdEXPAmend.InfoFromSourceIsVerbal": function (val) {
            if(val == false || val == 'false') {
                this.ProdEXPAmend.InfoFromSourceVerbal=null;
            }
        },
        "ProdEXPAmend.InfoFromSourceIsWritten": function (val) {
            if(val == false ||  val == 'false') {
                this.ProdEXPAmend.InfoFromSourceWritten=null;
            }
        },
        "ProdEXPAmend.CurrentW9Attachment": function (val) {
            if (val == "No") {
                this.ProdEXPAmend.IdentificationType = "";
                this.IDNumberchange();
            }
        },
        "ProdEXPAmend.VendorCodeOptions": function (val)
        {
            if(val && this.VendorCodeOptions) {
                    const businessName = this.VendorCodeOptions.find(el => el.VendorCode == val)
                    if(businessName)
                    {
                        this.ProdEXPAmend.VendorBusinessName= businessName.VendorBusinessName;
                    }
                }
            },
        "ProdEXPAmend.RemittanceCountry": function (val) {
            if (val) {
                    this.ProdEXPAmend.RemittanceCity = '';
                    this.ProdEXPAmend.RemittanceState = '';
                    this.ProdEXPAmend.RemittanceZipCode = '';
                }
            },
        },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    },
    methods: {
        async GetVendorCodeData() {
            try {
                const resSystem = await this.$store.dispatch("prodexpamend/GetD365VendorData");
                if (resSystem.data) {
                    this.VendorCodeOptions = resSystem.data;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        PrintValidation() {
            const params = Object.keys(this.$v.ProdEXPAmend.$params);
            var invalidFields = [];
            for (var x = 0; x < params.length; x++) {
                let name = params[x];
                if (this.$v.ProdEXPAmend[name].$invalid) {
                    invalidFields.push(name);
                }
            }
        },
        mountedWrittenDropzone() {
            if (this.ProdEXPAmend.IsEditDataLoaded == true && this.IsEdit && this.IsWrittenFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesWritten(this.ProdEXPAmend.SetupInfoSource);
                this.$set(this.ProdEXPAmend, "SetupInfoSource", this.GetFilesObjectSource());
                this.WrittenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
            }
        },
        mountedIdentitificationDropzone() {
            if (this.ProdEXPAmend.IsEditDataLoaded == true && this.IsEdit && this.IsIdentificationFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesIdentification(this.ProdEXPAmend.IdentificationFiles);
                this.$set(this.ProdEXPAmend, "IdentificationFiles", this.GetIDFilesObject());
            }
        },
        mountedInvoiceAttachmentDropzone() {
            if (this.ProdEXPAmend.IsEditDataLoaded == true && this.IsEdit && this.IsInvoiceAttachmentLoadedInEdit == false) {
                this.ManuallyUploadInvoiceAttachment(this.ProdEXPAmend.InvoiceAttachment);
                this.$set(this.ProdEXPAmend, "InvoiceAttachment", this.GetFilesObjectInvoiceAttachment());
            }
        },
        ManuallyUploadFilesIdentification(files) {
            if (this.$refs.identificationDropzone != null) {
                if (files != null && files.length > 0) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.identificationDropzone.manuallyAddFile(file, url);
                    });

                }
                this.IsIdentificationFilesLoadedInEdit = true;
            }
        },
        ManuallyUploadFilesWritten(files) {
            if (this.$refs.DropzoneWrittenSource != null) {
                if (files != null && files.length > 0) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.DropzoneWrittenSource.manuallyAddFile(file, url);
                    });
                }
                this.IsWrittenFilesLoadedInEdit = true;
            }
        },
        ManuallyUploadInvoiceAttachment(files) {
            if (this.$refs.myVueDropzoneInvoiceAttachment != null) {
                if (files != null && files.length > 0) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.myVueDropzoneInvoiceAttachment.manuallyAddFile(file, url);
                    });
                }
                this.IsInvoiceAttachmentLoadedInEdit = true;
            }
        },
        IsNumeric(prop) {
            let props = [{
                name: 'USTaxID',
                type: 'numeric'
            }, {
                name: 'SocialSecurityNumber',
                type: 'numeric'
            }, {
                name: 'Foreign',
                type: 'text'
            }];
            let index = props.findIndex(val => val.name == prop);
            if (index > -1) {
                return props[index].type == 'numeric';
            } else {
                return false;
            }
        },
        SetDirty(propertyName) {
            this.$v.ProdEXPAmend[propertyName].$touch();
        },
        ResetDirty(propertyName) {
            this.$v.ProdEXPAmend[propertyName].$reset();
        },
        UploadtoIdentification() {
            this.$set(this.ProdEXPAmend, "IdentificationFiles", this.GetIDFilesObject());
        },
        RemovedFromIdentification() {
            this.$set(this.ProdEXPAmend, "IdentificationFiles", this.GetIDFilesObject());
        },
        UploadedtoDropzoneSource() {
            this.WrittenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
            this.$set(this.ProdEXPAmend, "SetupInfoSource", this.GetFilesObjectSource());
        },
        RemovedFromDropzoneSource() {
            this.WrittenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
            this.$set(this.ProdEXPAmend, "SetupInfoSource", this.GetFilesObjectSource());
        },
        UploadedtoInvoiceAttachment() {
            this.$set(this.ProdEXPAmend, "InvoiceAttachment", this.GetFilesObjectInvoiceAttachment());
        },
        RemovedFromInvoiceAttachment() {
            this.$set(this.ProdEXPAmend, "InvoiceAttachment", this.GetFilesObjectInvoiceAttachment());
        },
        GetIDFilesObject() {
            let files = [];
            if (this.$refs.identificationDropzone.dropzone.files && this.$refs.identificationDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.identificationDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,
                        file: filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        GetFilesObjectSource() {
            let files = [];
            if (typeof this.$refs.DropzoneWrittenSource != "undefined" &&
                typeof this.$refs.DropzoneWrittenSource.dropzone != "undefined" &&
                this.$refs.DropzoneWrittenSource.dropzone.files.length > 0) {
                let filesExists = this.$refs.DropzoneWrittenSource.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        GetFilesObjectInvoiceAttachment() {
            let files = [];
            if (typeof this.$refs.myVueDropzoneInvoiceAttachment != "undefined" &&
                typeof this.$refs.myVueDropzoneInvoiceAttachment.dropzone != "undefined" &&
                this.$refs.myVueDropzoneInvoiceAttachment.dropzone.files.length > 0) {
                let filesExists = this.$refs.myVueDropzoneInvoiceAttachment.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name, file: filesExists[i]
                    });
                }
            }

            return files;
        },
        Next() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            if (this.id) {
                if(this.ProdEXPAmend.ChangeType == 'Both' || this.ProdEXPAmend.ChangeType == 'Banking') {
                    this.$router.push({
                        name: "Production Expense Amend Banking Info",
                        params: {
                            id: this.id,
                            isDraft: this.isDraft
                        }
                    });

                }
                if(this.ProdEXPAmend.ChangeType == 'Address') {
                    this.$router.push({
                        name: "Production Expense Amend Employee Relationship",
                        params: {
                            id: this.id,
                            isDraft: this.isDraft
                        }
                    });
                }
            } else {
                if(this.ProdEXPAmend.ChangeType == 'Both' || this.ProdEXPAmend.ChangeType == 'Banking') {
                    this.$router.push({
                        name: "Production Expense Amend Banking Info"
                    });
                }
                if(this.ProdEXPAmend.ChangeType == 'Address') {
                    this.$router.push({
                        name: "Production Expense Amend Employee Relationship"
                    });
                }
            }
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Production Expense Amend Employee Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Production Expense Amend Employee Info"
                });
            }
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        IDNumberchange() {
            this.ProdEXPAmend.USTaxID = "";
            this.ProdEXPAmend.Foreign = "";
            this.ProdEXPAmend.SocialSecurityNumber = "";
            this.$refs.identificationDropzone.removeAllFiles()
        },
        LoadDropdownValues() {
            this.LoadStates();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.CountryList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.CountryList.findIndex(
                            (i) => i.label == this.CountryUnitedStatesValue
                        );
                        let indexCanada = this.CountryList.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.CountryList.unshift(
                                this.CountryList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                        this.CountryList.forEach(element => { element.value = element.label;});
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.StateList = res.data;
                    this.StateList.forEach(element => { element.value = element.label;});
                })
                .catch((err) => {
                    alert(err);
                });
        },
    }
}
</script>

<style>
fieldset.form-group:not(.custom-radio)>legend {
    padding-left: 0px !important;
}

/*file upload starts*/

.customdropzone {
    width: 411px !important;
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    /* margin-left: 97px !important; */
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.draganddropspan {
    color: #00a1d2;
}

/*file upload ends*/
</style>
