import prodEXPService from "../../services/prodexpamend";
import lookerService from "../../services/looker";
const getDefaultState = () => {
    return {
        ProdEXPAmend: {
            ID: 0,
            VendorCodeOptions: null,
            RequestType: null,
            ChangeType: null,
            EmployeeAgency: null,
            AgencyName: null,
            VendorBusinessName: null,
            AccountingContactName: null,
            AccountingTitle: null,
            AccountingPhone: null,
            AccountingEmailAddress: null,
            InfoFromName: null,
            InfoFromTitle: null,
            InfoFromPhone: null,
            InfoFromSourceIsVerbal: false,
            InfoFromSourceIsWritten: false,
            InfoFromSourceVerbal: null,
            InfoFromSourceWritten: null,
            InfoFromWrittenAttachment: null,
            CurrentW9Attachment: null,
            IdentificationType: null,
            BankName: null,
            BankCountry: null,
            BankAddressLine1: null,
            BankAddressLine2: null,
            BankCity: null,
            BankState: null,
            BankZipCode: null,
            BankLetter: [],
            AccountName: null,
            AccountNumber: null,
            RoutingNumber: null,
            BankBIC: null,
            BankIBAN: null,
            RemittanceAdviceEmail: null,
            IsCreditCardAccepted: null,
            IsBankingInfoCollected: null,
            CreditCardFiles: [],
            IsDigitalMedia: null,
            IsMediaRepRequest: null,
            VendorInvoiceCopyFiles: [],
            USTaxID: null,
            Foreign: null,
            SocialSecurityNumber: null,
            IdentificationFiles: [],
            SampleMediaKitFiles: [],
            EmployeeTitle: null,
            EmployeePhoneNumber: null,
            AnyGiftOrCompensationOfferedToYouToPlaceOrder: null,
            CompensationReceived: null,
            EmployeeVendorRelationship: [],
            EmployeeVendorRelationshipDetails: {
                FormerEmployeeName: null,
                FamilyMemberName: null,
                FriendName: null,
                VendorRelationshipWithAgencyClient: null,
                VendorOfFormerEmployee: null,
                VendorWasFormerEmployeeOfAgency: null,
                VendorHasRelationshipWithAnotherAgencyEmployee: null,
                Other: null,
                ProdEXPAmendRequestID: 0,
                ID: 0,
            },
            Status: null,
            Attestation: false,
            SetupInfoSource: [],
            RemittanceCountry: null,
            StreetAddressLine1: null,
            RemittanceCity: null,
            RemittanceState: null,
            RemittanceZipCode: null,
            InvoiceAttachment: [],
            Entity: null,
            EntityName: null,
        },
    };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
    ProdEXPAmend: (state) => {
        return state.ProdEXPAmend;
    },
};

// actions
const actions = {
    async GetFormData(st, id) {
        await prodEXPService
            .GetFormData(id, st.rootState.auth.idToken)
            .then((res) => {
                st.commit("loadVendorData", res.data);
                st.commit("loadVendorFiles", res.data);
                return res.data;
            })
            .catch((err) => {
                throw err;
            });
    },
    GetDraftFormData(st, id) {
        return prodEXPService.GetDraftFormData(id, st.rootState.auth.idToken);
    },
    GetRequestWithTaskDetails(st, id) {
        return prodEXPService.GetRequestWithTaskDetails(
            id,
            st.rootState.auth.idToken
        );
    },
    AddRequest(st, data) {
        return prodEXPService.AddRequest(data, st.rootState.auth.idToken);
    },
    AddRequestWithFiles(st, data) {
        return prodEXPService.AddRequestWithFiles(data, st.rootState.auth.idToken);
    },
    ApproveRequestLevel1(st, data) {
        return prodEXPService.ApproveRequestLevel1(data, st.rootState.auth.idToken);
    },
    ApproveRequestLevel2(st, data) {
        return prodEXPService.ApproveRequestLevel2(data, st.rootState.auth.idToken);
    },
    ApproveRequestLevel3(st, data) {
        return prodEXPService.ApproveRequestLevel3(data, st.rootState.auth.idToken);
    },
    AccountingApprove(st, data) {
        return prodEXPService.AccountingApprove(data, st.rootState.auth.idToken);
    },
    CFOApprove(st, data) {
        return prodEXPService.CFOApprove(data, st.rootState.auth.idToken);
    },
    CompleteRequest(st, data) {
        return prodEXPService.CompleteRequest(data, st.rootState.auth.idToken);
    },
    RejectRequestLevel1(st, data) {
        return prodEXPService.RejectRequestLevel1(data, st.rootState.auth.idToken);
    },
    RejectRequestLevel2(st, data) {
        return prodEXPService.RejectRequestLevel2(data, st.rootState.auth.idToken);
    },
    RejectRequestLevel3(st, data) {
        return prodEXPService.RejectRequestLevel3(data, st.rootState.auth.idToken);
    },
    RejectVendorValidation(st, data) {
        return prodEXPService.RejectVendorValidation(data, st.rootState.auth.idToken);
    },
    AccountingReject(st, data) {
        return prodEXPService.AccountingReject(data, st.rootState.auth.idToken);
    },
    CFOReject(st, data) {
        return prodEXPService.CFOReject(data, st.rootState.auth.idToken);
    },
    GetRequests(st, options) {
        return prodEXPService.GetRequests(st.rootState.auth.idToken, options);
    },
    RenderUploadedFiles(st, { id, category }) {
        return prodEXPService.RenderUploadedFiles(
            st.rootState.auth.idToken,
            id,
            category
        );
    },
    UpdateRequestWithFiles(st, { id, data }) {
        return prodEXPService.UpdateRequestWithFiles(
            id,
            data,
            st.rootState.auth.idToken
        );
    },
    GetUsers(st, { agencyName, name }) {
        return prodEXPService.GetUsers(st.rootState.auth.idToken, agencyName, name);
    },
    SaveAsDraft(st, data) {
        return prodEXPService.SaveAsDraft(data, st.rootState.auth.idToken);
    },

    GetAuditFormData(st, id) {
        return prodEXPService.GetAuditFormData(id, st.rootState.auth.idToken);
    },
    GetWorkflowStates(st, id) {
        return prodEXPService.GetWorkflowStates(id, st.rootState.auth.idToken);
    },
    ReassignDirectorApproval(st, data) {
        return prodEXPService.ReassignDirectorApproval(data, st.rootState.auth.idToken);
    },
    GetWorkflowHistoryStates(st, id) {
        return prodEXPService.GetWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },
    DownloadUploadTemplate(st, data) {
        return prodEXPService.DownloadUploadTemplate(
            st.rootState.auth.idToken,
            data
        );
    },
    PendingVendorApproveorComplete(st, data) {
        return prodEXPService.PendingVendorApproveorComplete(
            data,
            st.rootState.auth.idToken
        );
    },
    GetD365VendorData(st, data) {
        return lookerService.GetD365VendorData(st.rootState.auth.idToken, data);
    },
    GetRequestorById(st, id) {
        return prodEXPService.GetRequestorById(
            id,
            st.rootState.auth.idToken
        );
    },
};

function GetFilesCategory() {
    return ["IdentificationFiles", "SetupInfoSource", "BankLetter", "InvoiceAttachment"];
}
// mutations
const mutations = {
    reset(st) {
        const s = getDefaultState();
        Object.keys(st).forEach((d) => {
            st[d] = s[d];
        });
    },
    loadVendorData(state, data) {
        Object.assign(state.ProdEXPAmend, data);
        state.ProdEXPAmend.CurrentW9Attachment = data.CurrentW9Attachment == true ? "Yes" : "No";
        state.ProdEXPAmend.BankState = data.SelectedStateName;
        if (state.ProdEXPAmend.EmployeeVendorRelationship != null) {
            state.ProdEXPAmend.EmployeeVendorRelationship = state.ProdEXPAmend.EmployeeVendorRelationship.map(
                (name) => name.RelationshipName
            );
        }

        state.ProdEXPAmend.AnyGiftOrCompensationOfferedToYouToPlaceOrder =
            data.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true ?
            "true" :
            "false";
        state.ProdEXPAmend.IdentificationFiles = [];
        state.ProdEXPAmend.SetupInfoSource = [];
        state.ProdEXPAmend.BankLetter = [];
        state.ProdEXPAmend.InvoiceAttachment = [];
    },
    loadVendorFiles(state, data) {
        if (data.Files.length > 0) {
            GetFilesCategory().forEach((file) => {
                if (typeof file == "undefined" || file == null) {
                    return;
                } else {
                    let files = data.Files.filter((catFile) => {
                        return catFile.Category == file;
                    });
                    if (files.length > 0) {
                        files.forEach((originalFile) => {
                            state.ProdEXPAmend[file].push(originalFile);
                        });
                    }
                }
            });
        }
        state.ProdEXPAmend.IsEditDataLoaded = true;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};