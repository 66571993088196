<template>
    <div class="panel-list-new row review-component">
        <b-card class="col-lg-10 mx-auto">
            <div class="panel-list">
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Ledger Media')">
                                <b-img class="edit-icon" :src="editIcon" /> Ledger and Media info
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Is Media Rep Request</label>
                            <p v-if="!MediaVendor.IsMediaRepRequest">
                                N/A
                            </p>
                            <p v-if="MediaVendor.IsMediaRepRequest == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendor.IsMediaRepRequest == 'false'">
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsMediaRepRequest == 'true'">
                            <label>Please attach an invoice copy</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.VendorInvoiceCopyFiles" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.VendorInvoiceCopyFiles, MediaVendor.Files, 'VendorInvoiceCopyFiles')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsMediaRepRequest == 'false'">
                            <label>Is Digital Request?</label>
                            <p v-if="MediaVendor.IsDigitalMedia == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendor.IsDigitalMedia == 'false'">
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsDigitalMedia == 'true'">
                            <label>Digital Media types</label>
                            <p>
                                Search, Interactive, Social, Video,
                                Bundled, Unbundled, Digital Audio
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsDigitalMedia == 'false'">
                            <label>Media Type</label>
                            <p>
                                {{ MediaVendor.MediaType }}
                            </p>
                            <p v-if="MediaVendor.MediaType === 'TV' || MediaVendor.MediaType === 'Radio'">
                                <span class="d-flex" v-if="MediaVendor.CallLetters">Call Letters : {{
                                    MediaVendor.CallLetters
                                }}</span>
                                <span class="d-flex" v-if="!MediaVendor.CallLetters">Call Letters : N/A</span>
                                <span class="d-flex" v-if="MediaVendor.MarketNumbers">Market Numbers : {{
                                    MediaVendor.MarketNumbers }}</span>
                                <span class="d-flex" v-if="!MediaVendor.MarketNumbers">Market Numbers : N/A</span>
                                <span class="d-flex" v-if="MediaVendor.MarketName">Market Name : {{ MediaVendor.MarketName
                                }}</span>
                                <span class="d-flex" v-if="!MediaVendor.MarketName">Market Name : N/A</span>
                            </p>
                        </b-col>
                        <!-- <b-col cols="lg-3">
                        <label>Is Digital</label>
                        <p>
                            {{MediaVendor.IsDigitalMedia }}
                        </p>
                    </b-col> -->
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor External Attachment')">
                                <b-img class="edit-icon" :src="editIcon" />External Vendor Form
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Completed Forms</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.CompletedForms" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.CompletedForms, MediaVendor.Files, 'CompletedForms')">Download</span>
                            </div>
                            <p v-if="!file && !MediaVendor.CompletedForms">
                                N/A
                            </p>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Credit Info')">
                                <b-img class="edit-icon" :src="editIcon" /> Credit Card Info
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>Did the vendor specify that they do, in fact accept credit cards on the form the vendor completed?</label>
                            <p v-if="!MediaVendor.IsCreditCardAccepted">
                                N/A
                            </p>
                            <p v-if="MediaVendor.IsCreditCardAccepted == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendor.IsCreditCardAccepted == 'false'">
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsCreditCardAccepted == 'true'">
                            <label>Are there fees or conditions/limitations in accepting payment via credit card for this organization? An example would be that the vendor charges a 3% fee on all transactions, or that the companies credit card information must be held on file with the vendor for immediate payment processing.</label>
                            <p v-if="MediaVendor.AreThereConditionsOrLimitations == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendor.AreThereConditionsOrLimitations == 'false'">
                                NO
                            </p>
                            <p v-if="!MediaVendor.AreThereConditionsOrLimitations">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4"  v-if="MediaVendor.AreThereConditionsOrLimitations == 'true'">
                            <label>What are the Limitations?</label>
                            <p v-if="!MediaVendor.CreditLimitation">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendor.CreditLimitation }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"  v-if="MediaVendor.AreThereConditionsOrLimitations == 'true'">
                            <label>Did the vendor indicate that they would like to be setup for ACH payments?</label>
                            <p v-if="!MediaVendor.HasACHPaymentSetup">
                                N/A
                            </p>
                            <p v-if="MediaVendor.HasACHPaymentSetup == 'true'">
                                YES
                            </p>
                            <p v-else>
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4"  v-if="MediaVendor.HasACHPaymentSetup == 'true'">
                            <label>Did the vendor provide a bank letter, invoice or voided check confirming their account information?</label>
                            <p v-if="!MediaVendor.HasBankLetterInvoice">
                                N/A
                            </p>
                            <p v-if="MediaVendor.HasBankLetterInvoice == 'true'">
                                YES
                            </p>
                            <p v-else>
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>BANK NAME</label>
                            <p>
                                {{ MediaVendor.BankName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>BANK ADDRESS LINE 1</label>
                            <p>
                                {{ MediaVendor.BankAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>BANK ADDRESS LINE 2</label>
                            <p v-if="!MediaVendor.BankAddressLine2">
                                N/A
                            </p>
                            <p v-if="MediaVendor.BankAddressLine2">
                                {{ MediaVendor.BankAddressLine2 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.BankCountry && MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>BANK COUNTRY</label>
                            <p>
                                {{ MediaVendor.BankCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.BankCountry == CountryUSA  && MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>BANK STATE</label>
                            <p>
                                {{ MediaVendor.BankState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendor.BankCountry == CountryUSA && MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>BANK CITY</label>
                            <p>
                                {{ MediaVendor.BankCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendor.BankCountry == CountryUSA && MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>ZIP CODE</label>
                            <p>
                                {{ MediaVendor.BankZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>ACCOUNT NAME</label>
                            <p>
                                {{ MediaVendor.BankAccountName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>ACCOUNT NUMBER</label>
                            <p>
                                {{ MediaVendor.BankAccountNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4"
                            v-if="MediaVendor.BankCountry == CountryUSA && MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>ROUTING NUMBER (US ONLY)</label>
                            <p>
                                {{ MediaVendor.BankRoutingNumber }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if=" MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>REMITTANCE ADVICE EMAIL</label>
                            <p>
                                {{ MediaVendor.RemittanceEmailAddress }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if=" MediaVendor.HasBankLetterInvoice == 'true'">
                            <label>Please attach bank letter, invoice, or voided check.</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.BankLetterInvoice" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.BankLetterInvoice, MediaVendor.Files, 'BankLetterInvoice')">Download</span>
                            </div>
                            <p v-if="!file && !MediaVendor.BankLetterInvoice">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsCreditCardAccepted == 'false'">
                            <label>Would the vendor like to be set up by ACH Payment?</label>
                            <p v-if="MediaVendor.ACHPayment == 'true'">
                                YES
                            </p>
                            <p v-if="MediaVendor.ACHPayment == 'false'">
                                NO
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.ACHPayment == 'true'">
                            <label>You must obtain a bank letter from the vendor’s bank, or a voided check to setup ACH payments. Have you collected this information?</label>
                            <p v-if="MediaVendor.IsBankingInfoCollected == 'true'">YES</p>
                            <p v-if="MediaVendor.IsBankingInfoCollected == 'false'">NO</p>
                            <p v-if="!MediaVendor.IsBankingInfoCollected">N/A</p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsBankingInfoCollected == 'true'">
                            <label>Attachment(s)</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.CreditCardFiles" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.CreditCardFiles, MediaVendor.Files, 'CreditCardFiles')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Setup Info')">
                                <b-img class="edit-icon" :src="editIcon" /> Setup Info
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>VENDOR BUSINESS NAME</label>
                            <p v-if="!MediaVendor.VendorBusinessName">
                                N/A
                            </p>
                            <p>
                                {{ MediaVendor.VendorBusinessName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Identification Type</label>
                            <p>
                                {{ formatIdentityTypeLabel(MediaVendor.IdentificationType) }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Identification Number</label>
                            <p v-if="!MediaVendor.IdentificationType">
                                N/A
                            </p>
                            <p>
                                {{ formatIdentityType(MediaVendor[MediaVendor.IdentificationType]) }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>{{ AttachmentLabel }}</label>
                            <p v-if="MediaVendor.IdentificationFiles.length <= 0">
                                N/A
                            </p>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.IdentificationFiles" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.IdentificationFiles, MediaVendor.Files, 'IdentificationFiles')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Did the vendor provide a link to their website?</label>
                            <p v-if="MediaVendor.IsVendorWebsiteProvided == 'true'">
                                Yes
                            </p>
                            <div v-if="MediaVendor.IsVendorWebsiteProvided == 'false'">
                                NO
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsVendorWebsiteProvided == 'true'">
                            <label>VENDOR WEBSITE LINK</label>
                            <p>
                                {{ MediaVendor.VendorWebsiteLink }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsVendorWebsiteProvided == 'false'">
                            <label>Attach Media Kit File</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.MediaKitAttachments" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.MediaKitAttachments, MediaVendor.Files, 'MediaKitAttachments')">Download</span>
                            </div>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>VENDOR REMITTANCE NAME</label>
                            <p>
                                {{ MediaVendor.VendorRemittanceName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>COUNTRY</label>
                            <p>
                                {{ MediaVendor.RemittanceCountry }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>STREET ADDRESS LINE 1</label>
                            <p>
                                {{ MediaVendor.StreetAddressLine1 }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.MediaVendor.RemittanceCountry == CountryUSA">
                            <label>CITY</label>
                            <p>
                                {{ MediaVendor.RemittanceCity }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.MediaVendor.RemittanceCountry == CountryUSA">
                            <label>STATE</label>
                            <p>
                                {{ MediaVendor.RemittanceState }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="this.MediaVendor.RemittanceCountry == CountryUSA">
                            <label>ZIP CODE</label>
                            <p>
                                {{ MediaVendor.RemittanceZipCode }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>VENDOR EMAIL</label>
                            <p v-if="!MediaVendor.RemittanceAdviceEmail">
                                N/A
                            </p>
                            <p v-if="MediaVendor.RemittanceAdviceEmail">
                                {{ MediaVendor.RemittanceAdviceEmail }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>DID THE VENDOR SPECIFY THEIR URL ON THE FORM THE VENDOR COMPLETED?</label>

                            <p v-if="!MediaVendor.UrlSpecifiedInForm">
                                N/A
                            </p>
                            <p v-if="MediaVendor.UrlSpecifiedInForm == 'true'">
                                Yes
                            </p>
                            <div v-if="MediaVendor.UrlSpecifiedInForm == 'false'">
                                NO
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.IsMediaRepRequest == 'true'">
                            <label>VENDOR EMAIL</label>
                            <div class="d-flex">
                                <p>
                                    {{ MediaVendor.RemittanceAdviceEmail }}
                                </p>
                            </div>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.UrlSpecifiedInForm == 'false'">
                            <label>Sample Media Kit</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.SampleMediaKitFiles" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.SampleMediaKitFiles, MediaVendor.Files, 'SampleMediaKitFiles')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-12">
                            <label>Who at the vendor provided this information?</label>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Name</label>
                            <p>
                                {{ MediaVendor.InfoFromName }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Title</label>
                            <p>
                                {{ MediaVendor.InfoFromTitle }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Telephone Number</label>
                            <p>
                                {{ MediaVendor.InfoFromPhone }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="panel-content">
                        <b-col cols="lg-4">
                            <label>How was this information obtained?</label>
                            <p v-if="!MediaVendor.InfoFromSourceIsVerbal && !MediaVendor.InfoFromSourceIsWritten">
                                N/A
                            </p>
                            <p>
                                <span v-if="MediaVendor.InfoFromSourceIsVerbal">Verbal</span>
                                <span v-if="MediaVendor.InfoFromSourceIsVerbal && MediaVendor.InfoFromSourceIsWritten"> &
                                </span>
                                <span v-if="MediaVendor.InfoFromSourceIsWritten">Written</span>
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.InfoFromSourceVerbal">
                            <label>Verbal</label>
                            <p>
                                {{ MediaVendor.InfoFromSourceVerbal }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.InfoFromSourceWritten">
                            <label>Written</label>
                            <p>
                                {{ MediaVendor.InfoFromSourceWritten }}
                            </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.InfoFromSourceWritten">
                            <label>Written Communication File</label>
                            <div class="d-flex align-items-start flex-column">
                                <a class="file-ref" v-for="file in MediaVendor.SetupInfoSource" :key="file">{{
                                    file.name || file.Name }}</a>
                                <span class="btn btn-primary btn-view"
                                    @click.prevent="downloadFiles(MediaVendor.SetupInfoSource, MediaVendor.Files, 'SetupInfoSource')">Download</span>
                            </div>
                        </b-col>
                    </b-row>
                </div>

                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Relationship Info')">
                                <b-img class="edit-icon" :src="editIcon" /> Employee Relationship
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content" v-if="MediaVendor.EmployeeVendorRelationship.length > 0">

                        <p v-if="!MediaVendor.EmployeeVendorRelationship">
                            N/A
                        </p>
                        <template v-if="!IsNoneSelected">
                            <b-col cols="lg-4" v-for="item in MediaVendor.EmployeeVendorRelationship" :key="item">
                                <label>{{ GetRelationshipLabel(item) }}</label>
                                <div>
                                    <p>{{ MediaVendor.EmployeeVendorRelationshipDetails[item] }}</p>
                                </div>
                            </b-col>
                        </template>
                        <b-col cols="lg-4" v-if="IsNoneSelected">
                            <label>Employee Relationship With Vendor</label>
                            <p>None</p>
                        </b-col>
                        <b-col cols="lg-4">
                            <label>Has any form of compensation or gifts of any type been offered to you or any person
                                related to you in exchange for placing an order with this vendor?</label>
                            <p v-if="MediaVendor.AnyGiftOrCompensationOfferedToYouToPlaceOrder == 'true'">YES</p>
                            <p v-else>NO</p>
                        </b-col>
                        <b-col cols="lg-4" v-if="MediaVendor.CompensationReceived">
                            <label>Compensation Received</label>
                            <p>${{ MediaVendor.CompensationReceived }}</p>
                        </b-col>
                    </b-row>

                </div>
                <div class="panel-body">
                    <b-row class="panel-title">
                        <div class="text-right">
                            <button v-if="CanGoToSection" class="btn btn-link"
                                @click="GotoSection('Media Vendor Employee Info')">
                                <b-img class="edit-icon" :src="editIcon" /> Employee Info
                            </button>
                        </div>
                    </b-row>
                    <b-row class="panel-content row">
                        <!-- <b-col cols="lg-3">
                        <label>Employee Title</label>
                        <div>{{MediaVendor.EmployeeTitle}}</div>
                    </b-col> -->
                        <b-col cols="lg-3">
                            <label>Employee Phone Number</label>
                            <p>{{ MediaVendor.EmployeePhoneNumber }}</p>
                            <p v-if="!MediaVendor.EmployeePhoneNumber">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <label>ENTITY</label>
                            <p>{{ MediaVendor.EntityName }}</p>
                            <p v-if="!MediaVendor.EntityName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <label>Employee Agency</label>
                            <p>{{ MediaVendor.AgencyName }}</p>
                            <p v-if="!MediaVendor.AgencyName">
                                N/A
                            </p>
                        </b-col>
                        <b-col cols="lg-3">
                            <label>Client Code</label>
                            <p v-if="!MediaVendor.ClientCode">
                                N/A
                            </p>
                            <p>{{ MediaVendor.ClientCode }}</p>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="d-block col-lg-10 agree-checkbox agree-checkbox-new">
                <b-form-checkbox value="true" v-model="MediaVendor.Attestation">By checking this
                    box, the requestor is
                    confirming that he/she/they has conducted all acceptable measures of due diligence to ensure the
                    propriety of the vendor and confirm that the information contained on the form is accuraate to the best
                    of their knowledge</b-form-checkbox>
            </div>
        </b-card>
        <b-form-group class="footer-buttons text-right">

            <button class="btn custom-button btn-link" @click="Cancel()">
                Cancel
            </button>
            <button class="btn custom-button btn-primary" v-if="CanGoToSection" @click="Previous()">
                BACK
            </button>

            <button class="btn custom-button btn-primary" :disabled="!IsFormSigned" v-if="!Edit" @click="Submit()">
                Submit
            </button>
            <button class="btn custom-button btn-primary"  :disabled="!IsFormSigned" v-if="CanResubmit && !isDraft" @click="Resubmit()">
                Resubmit
            </button>
        </b-form-group>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import downloadFile from '../../../mixins/downloadFile';
import * as status from "../../../utilities/VendorWorkflowStates";
export default {
    mixins: [downloadFile],
    data() {
        return {
            CountryUSA: "USA - United States",
            editIcon: require("@/assets/Icons/edit.svg"),
            RelationshipVendorsLabel: [{
                "label": "NONE",
                value: "NONE"
            },
            {
                "label": "Former Employee's Name",
                value: "FormerEmployeeName"
            },
            {
                "label": "Family Member's Name",
                value: "FamilyMemberName"
            },
            {
                "label": "Friend's Name",
                value: "FriendName"
            },
            {
                "label": "Explain the nature of the relationship",
                value: "VendorRelationshipWithAgencyClient"
            },
            {
                "label": "Former Employee Name",
                value: "VendorOfFormerEmployee"
            },
            {
                "label": "Former Employee’s Name of Agency",
                value: "VendorWasFormerEmployeeOfAgency"
            },
            {
                "label": "Name of employee with another Agency",
                value: "VendorHasRelationshipWithAnotherAgencyEmployee"
            },
            {
                "label": "Other",
                value: "Other"
            }
            ]
        };
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        ...mapGetters('mediavendor', {
            MediaVendor: 'MediaVendor',
        }),
        CreditCardFiles() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "CreditCardFiles");
            }
            return files;
        },
        VendorInvoiceCopyFiles() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "VendorInvoiceCopyFiles");
            }
            return files;
        },
        SampleMediaKitFiles() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "SampleMediaKitFiles");
            }
            return files;
        },
        IdentificationFiles() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "IdentificationFiles");
            }
            return files;
        },
        SetupInfoSource() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "SetupInfoSource");
            }
            return files;
        },
        CompletedForms() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "CompletedForms");
            }
            return files;
        },
        BankLetterInvoice() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "BankLetterInvoice");
            }
            return files;
        },
        MediaKitAttachments() {
            let files = [];
            if (this.MediaVendor.Files.length > 0) {
                return this.MediaVendor.Files.filter(file => file.Category == "MediaKitAttachments");
            }
            return files;
        },
        IsFormSigned() {
            return this.MediaVendor.Attestation == 'true';
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
        AttachmentLabel() {
            return this.MediaVendor.IdentificationType == 'SocialSecurityNumber' || this.MediaVendor.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
        },
        IsNoneSelected() {
            return this.MediaVendor.EmployeeVendorRelationship.length == 1 && this.MediaVendor.EmployeeVendorRelationship.findIndex(val => val == "NONE") > -1;
        },
        CanResubmit() {
            return (
                this.id &&
                parseInt(this.id) > 0 &&
                this.MediaVendor.Status == status.Canceled
            );
        },
        Edit() {
            return this.id && parseInt(this.id) > 0 && !this.isDraft;
        },
        CanGoToSection() {
            return !this.IsEdit || this.CanResubmit;
        },
        GetInfoSource() {
            let text = "";
            if (this.MediaVendor.infofromsourceisverbal) {
                text += "Verbal"
            }
            if (this.MediaVendor.infofromsourceiswritten) {
                text += text == ""
                    ? "Written"
                    : "& Written"
            }
            return text;
        }
    },
    activated() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    mounted() {
            // this.fileData2 = this.MediaVendor.Files.filter(el => el.Category == 'VendorInvoiceCopyFiles')
            // console.log(this.fileData2,"this.fileData2")
            // this.MediaVendor.VendorInvoiceCopyFiles.push(this.fileData2)
    },
    created() {
        if (this.id) {
            // window.console.log("review step changed");
            // window.console.log(`review ${this.$route.meta.tabIndex}`);
            // this.$parent.$emit("stepChanged", this.$route.meta.tabIndex - 1);
        }
        this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
    },
    watch: {
    },
    methods: {
        formatIdentityTypeLabel(val) {
            if (val) {
                if (this.MediaVendor.IdentificationType == "USTaxID") {
                    return "US Tax ID";
                } else if (this.MediaVendor.IdentificationType == "SocialSecurityNumber") {
                    return "Social Security Number";
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        formatIdentityType(val) {
            if (val) {
                if (this.MediaVendor.IdentificationType == "USTaxID" && val.length > 2) {
                    if (val.indexOf("-") != 2) {
                        val = val.insert(2, '-');
                    }
                    return val;
                } else if (this.MediaVendor.IdentificationType == "SocialSecurityNumber") {
                    if (val.indexOf("-") != 3 && val.length > 3) {
                        val = val.insert(3, '-');

                    }
                    if (val.lastIndexOf("-") != 6 && val.length > 6) {
                        val = val.insert(6, '-');

                    }
                    const identificationType = val;
                    const lastFourDigits = identificationType.slice(-4); // Get last four digits
                    const maskedDigits = 'XXX'; // Specify the format for the first three digits
                    return maskedDigits + '-XX-' + lastFourDigits;
                } else {
                    return val;

                }
            } else {
                return val;
            }
        },
        ConvertToBool(mediavendorInfo) {
            mediavendorInfo.AnyGiftOrCompensationOfferedToYouToPlaceOrder = mediavendorInfo.AnyGiftOrCompensationOfferedToYouToPlaceOrder == "true" ? true : false;
            mediavendorInfo.IsCreditCardAccepted = mediavendorInfo.IsCreditCardAccepted == "true" ? true : false;
            mediavendorInfo.IsMediaRepRequest = mediavendorInfo.IsMediaRepRequest == "true" ? true : false;
            mediavendorInfo.UrlSpecifiedInForm = mediavendorInfo.UrlSpecifiedInForm == "true" ? true : false;
            mediavendorInfo.IsBankingInfoCollected = mediavendorInfo.IsBankingInfoCollected == "true" ? true : false;
            mediavendorInfo.IsDigitalMedia = mediavendorInfo.IsDigitalMedia == "true" ? true : false;
            mediavendorInfo.ACHPayment = mediavendorInfo.ACHPayment == "true" ? true : false;
            mediavendorInfo.AreThereConditionsOrLimitations = mediavendorInfo.AreThereConditionsOrLimitations == "true" ? true : false;
            mediavendorInfo.HasACHPaymentSetup = mediavendorInfo.HasACHPaymentSetup == "true" ? true : false;
            mediavendorInfo.HasBankLetterInvoice = mediavendorInfo.HasBankLetterInvoice == "true" ? true : false;
            mediavendorInfo.IsVendorWebsiteProvided = mediavendorInfo.IsVendorWebsiteProvided == "true" ? true : false;
        },
        PrepareEmployeeVendorRelationship(mediavendorInfo) {
            if (this.IsNoneSelected) {
                let employeeRelationship = {
                    ID: 0,
                    RelationshipName: 'NONE'
                };
                mediavendorInfo.EmployeeVendorRelationship = [];
                mediavendorInfo.EmployeeVendorRelationship.push(employeeRelationship);
                mediavendorInfo.EmployeeVendorRelationshipDetails = null;
            } else {
                let employeeRelationships = [];
                mediavendorInfo.EmployeeVendorRelationship.forEach(employeeRelations => {
                    employeeRelationships.push({
                        ID: 0,
                        RelationshipName: employeeRelations
                    });
                })
                mediavendorInfo.EmployeeVendorRelationship = [];
                mediavendorInfo.EmployeeVendorRelationship = employeeRelationships;

            }
        },
        PrepareData(IsResubmit) {
            let mediavendorInfo = Object.assign({}, this.MediaVendor);
            this.ConvertToBool(mediavendorInfo);
            this.PrepareEmployeeVendorRelationship(mediavendorInfo);
            if (IsResubmit == true) {
                mediavendorInfo.Files = [];
                this.GetFilesCategory().forEach(name => {
                    this.AttachUpdatedFiles(mediavendorInfo.Files, name);
                });
            }
            //mediavendorInfo.WireTransferRequestWatchers = this.PrepareWatchersObject();
            mediavendorInfo.CreditCardFiles = [];
            this.MediaVendor.CreditCardFiles.forEach(s => {
                mediavendorInfo.CreditCardFiles.push(s.name);
            });
            return mediavendorInfo;
        },
        SaveAsDraft() {
            let data = this.PrepareData(true);
            let formData = new FormData();
            let files = this.$refs.myVueDropzone.dropzone.files;
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('files[' + i + ']', file);
            }
            formData.append('request', JSON.stringify(data));
            // formData.append('totalFiles', JSON.stringify(data.Files.map(val => {
            //     return val.Name
            // })));
            formData.append('draftRequestId', this.isDraft == true ? parseInt(this.id) : 0);
            this.$store
                .dispatch("wiretransfer/SaveAsDraft", formData)
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your wire transfer request has been saved as draft!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while saving wire transfer request as draft. ${ex}`
                        );
                    }
                });
        },
        AttachFiles(formData, fileCategory) {
            let files = this.MediaVendor[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append(`${fileCategory}[${i}]`, file.file || file);
            }
        },
        AttachUpdatedFiles(filesArray, fileCategory) {
            let files = this.MediaVendor[fileCategory];
            if (typeof files == "undefined" || files == null) {
                return;
            }
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                filesArray.push({ Category: fileCategory, Name: file.Name || file.name })
            }
        },
        GetFilesCategory() {
            return ["SampleMediaKitFiles", "CreditCardFiles", "VendorInvoiceCopyFiles", "IdentificationFiles", "SetupInfoSource", "CompletedForms","BankLetterInvoice","MediaKitAttachments"];
        },
        Submit() {

            let data = this.PrepareData();
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            let formData = new FormData();
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("mediavendor/AddRequestWithFiles", formData)
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your Media Vendor request has been submitted!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while creating media vendor request. ${ex}`
                        );
                    }
                });
        },
        Resubmit() {
            let data = this.PrepareData(true);
            if (this.IsEdit == true && this.isDraft == true) {
                data.DraftRequestID = parseInt(this.id);
            }
            let formData = new FormData();
            this.GetFilesCategory().forEach(name => {
                this.AttachFiles(formData, name);
            });
            formData.append('request', JSON.stringify(data));
            this.$store
                .dispatch("mediavendor/UpdateRequestWithFiles", {
                    id: parseInt(this.id),
                    data: formData
                })
                .then(() => {
                    this.$router.push("/VendorRequests");
                    store.dispatch(
                        "progress/setSuccessMessage",
                        "Your media vendor request has been updated!"
                    );
                })
                .catch((ex) => {
                    window.console.error(JSON.stringify(ex));
                    if (ex.response) {
                        var commenmsg = "";
                        if (ex.response.status == 500) {
                            commenmsg = "Please check your inputs or try again later";
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else if (ex.response.status == 400) {
                            for (var i = 0; i < ex.response.data.length; i++) {
                                if (ex.response.data[i].ErrorMessage != "undefined") {
                                    commenmsg += ex.response.data[i].ErrorMessage;
                                }
                            }
                            store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                        } else {
                            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                        }
                    } else {
                        store.dispatch(
                            "progress/setErrorMessage",
                            `Error while updating wire transfer request. ${ex}`
                        );
                    }
                });
        },
        Previous() {
            if (this.id) {
                this.$router.push({
                    name: "Media Vendor Employee Info",
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: "Media Vendor Employee Info",
                });
            }
        },
        GetRelationshipLabel(value) {
            let relationsshipIndex = this.RelationshipVendorsLabel.findIndex(val => {
                return val.value == value;
            })
            if (relationsshipIndex > -1) {
                return this.RelationshipVendorsLabel[relationsshipIndex].label;
            } else {
                return "";
            }
        },

        GotoSection(name) {
            if (this.id) {
                this.$router.push({
                    name: name,
                    params: {
                        id: parseInt(this.id)
                    }
                });
            } else {
                this.$router.push({
                    name: name
                });
            }
            // this.$router.push(`${section}`);
        },
        Cancel() {
            this.$router.push("/VendorRequests");
        },
    },
};
</script>

<style scoped>
span.btn.btn-primary.btn-view {
    height: 0;
    width: 94px;
    min-width: 94px;
    padding: 0;
    margin: 0;
    min-height: 22px;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.add-product-row {
    font-weight: bold;
    padding-left: 0px;
    height: 9px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list:not(.media-dropdowns) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #A2A9AD !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div> :not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.agree-checkbox .custom-control-label {
    font-weight: 400;
    font-size: 12px;
    text-transform: none !important;
}
</style>
